import { useViewportSize } from '@mantine/hooks'

function useIsMobile() {
  const { width } = useViewportSize()

  // Define a max width for mobile devices
  const mobileWidth = 768

  // If the window width is less than or equal to the mobileWidth, we consider it a mobile device
  const isMobile = width <= mobileWidth

  return isMobile
}

export default useIsMobile
