import { useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  Table as MantineTable, Pagination, ScrollArea,
} from '@mantine/core'
import { TableColumn } from '../../../interfaces'
import { TableDefaultCell } from './table-default-cell'
import EmptyRows from './table-empty-rows'
import SkeletonRows from './table-skeleton-rows'
import { FooterLoading } from './table-footer-loading'
import { ROW_HEIGHT_SIZE } from '../../utils/constants'
import useTableDefaultRowsPerPage from '../../hooks/use-table-default-rows-per-page'
import TablePageConfiguration from '../../../interfaces/table/page-configuration'
import tableUtils from './utils/table-utils'
import { SelectTableHeader } from './select-table-header'
import { SelectTableSummary } from './select-table-summary'
import InvoiceSynchronizeParams from '../../../interfaces/invoice/invoice-synchronize-params'

interface Props<ModelSchema> {
  data: ModelSchema[];
  columns: TableColumn<ModelSchema>[];
  indexKey: keyof ModelSchema;
  isFetching: boolean;
  isLoading: boolean;
  isRowSelectable: (rowData: ModelSchema) => boolean;
  onSynchronize: (keys: (keyof ModelSchema)[], synchronizeParams: InvoiceSynchronizeParams) => void
  pageConfiguration?: TablePageConfiguration;
  rowAction?: (rowData: ModelSchema) => void;
  minWidth?: number;
}

export function SelectTable<ModelSchema>({
  data, columns, indexKey, isFetching, isLoading, isRowSelectable, pageConfiguration, rowAction, onSynchronize, minWidth,
}: Props<ModelSchema>) {
  const defaultRowsPerPage = useTableDefaultRowsPerPage()

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedRows, setSelectedRows] = useState<Set<keyof ModelSchema>>(new Set())
  const selectableRows = data.filter(isRowSelectable)

  const pageSize = useMemo(
    () => tableUtils.getRowsPerPage(defaultRowsPerPage - 1, pageConfiguration),
    [pageConfiguration, defaultRowsPerPage],
  )

  const pagesAmount = useMemo(
    () => Math.ceil(data.length / pageSize),
    [data, pageSize],
  )

  const paginatedData = useMemo(
    () => data.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    [currentPage, data, pageSize],
  )

  const missingRows = pageSize - paginatedData.length

  useEffect(() => {
    if (currentPage > pagesAmount) { setCurrentPage(1) }
  }, [pagesAmount, currentPage, setCurrentPage])

  const handleSelectMultipleRows = (rows: Set<keyof ModelSchema>) => {
    setSelectedRows(rows)
  }

  const handleSelectRow = (rowId: keyof ModelSchema) => {
    const newSelectedRows = new Set(selectedRows)
    if (newSelectedRows.has(rowId)) {
      newSelectedRows.delete(rowId)
    } else {
      newSelectedRows.add(rowId)
    }
    setSelectedRows(newSelectedRows)
  }

  const handleSynchronize = () => {
    onSynchronize(Array.from(selectedRows), {
      onSuccess: () => handleSelectMultipleRows(new Set()),
    })
  }

  const getTableStyle = () => ({
    minWidth: minWidth ? `${minWidth}px` : '100%',
  })

  return (
    <div className="h-full">
      <SelectTableSummary
        selectedCount={selectedRows.size}
        itemName="faturas"
        onSynchronize={handleSynchronize}
      />
      <ScrollArea type="auto" offsetScrollbars style={{ width: '100%' }}>
        <div className="bg-white rounded-xl px-5 pt-2 pb-2 overflow-auto">
          <MantineTable style={getTableStyle()}>
            <colgroup className="w-auto">
              <col style={{ width: '5%' }} />
              {columns.map(({ widthPercentage, columnKey }) => (
                <col key={columnKey} style={{ width: `${widthPercentage}%` }} />
              ))}
            </colgroup>

            <SelectTableHeader<ModelSchema>
              columns={columns}
              selectedRows={selectedRows}
              selectableRows={selectableRows}
              onSelectMultipleRows={handleSelectMultipleRows}
              indexKey={indexKey}
            />

            <MantineTable.Tbody>
              {!isLoading && data.length > 0 && (
                paginatedData && paginatedData.map((row: any) => (
                  <MantineTable.Tr
                    className={`${rowAction ? 'cursor-pointer hover:bg-violet-100' : ''}`}
                    style={{
                      height: `${ROW_HEIGHT_SIZE}px`,
                      maxHeight: `${ROW_HEIGHT_SIZE}px`,
                    }}
                    key={row[indexKey]}
                  >
                    <MantineTable.Td>
                      <Checkbox
                        checked={selectedRows.has(row[indexKey])}
                        disabled={!isRowSelectable(row)}
                        onChange={() => handleSelectRow(row[indexKey])}
                      />
                    </MantineTable.Td>
                    {
                      columns.map(({
                        key, columnKey, Component, action, ignoreRowClick,
                      }) => (
                        <MantineTable.Td
                          key={columnKey}
                          onClick={() => {
                            const shouldIgnoreClick = typeof ignoreRowClick === 'function' ? ignoreRowClick(row) : ignoreRowClick
                            if (!(action || shouldIgnoreClick)) {
                              rowAction?.(row)
                            }
                          }}
                        >
                          {Component ? (
                            <Component value={row[key]} rowData={row} action={action} />
                          ) : (
                            <TableDefaultCell value={row[key]} />
                          )}
                        </MantineTable.Td>
                      ))
                    }
                  </MantineTable.Tr>
                ))
              )}

              {!isLoading && missingRows > 0 && (
                <EmptyRows
                  rowsToFill={missingRows}
                  columnsAmount={columns.length + 1}
                />
              )}

              {isLoading && (
                <SkeletonRows
                  rowsToFill={pageSize}
                  columnsAmount={columns.length + 1}
                />
              )}
            </MantineTable.Tbody>
          </MantineTable>
        </div>
      </ScrollArea>
      <div className="flex w-full align-middle justify-between mt-2 h-12">
        <Pagination
          total={pagesAmount}
          onChange={setCurrentPage}
          value={currentPage}
          color="dark"
          siblings={1}
          withControls={false}
          radius="md"
        />
        {(isLoading || isFetching) && <FooterLoading />}
      </div>
    </div>
  )
}
