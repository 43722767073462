import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { useEventsSumQuery } from '../queries'

export default function EventsSumCard() {
  const { t } = useTranslation('event')

  const {
    data: eventsSum,
    isLoading,
  } = useEventsSumQuery()

  return (
    <div className="text bg-violet-100 px-3 py-1 rounded-lg">
      <Text size="sm" fw={700} className="text-gray-700">
        {t('event:sum-card')}
        {' '}
        {isLoading ? '--' : eventsSum}
      </Text>
    </div>
  )
}
