import { Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { images } from '../../../shared/utils/public'

export default function LoginDesign() {
  const { t } = useTranslation('auth')

  return (
    <div className="flex flex-col">
      <img
        className="mb-4"
        src={images.loginDesign.src}
        alt={images.loginDesign.alt}
      />
      <Title order={2} className="text-white">
        {t('login.aira-slogan')}
        <span className="text-secondary">{t('login.aira-slogan-product')}</span>
      </Title>
    </div>
  )
}
