import { CreateResourceAttributes, UpdateResourceAttributes } from '../../../interfaces'
import { api } from '../../../libs'
import DeleteResourceRequest from '../../../libs/api/requests/resources/delete-resource-request'
import { Resource } from '../../../models'

const fetchResources = async (): Promise<Resource[]> => {
  const getResourcesResponse = await api.resources.getResources()
  return getResourcesResponse.data
}

const createResource = async (createResourceAttributes: CreateResourceAttributes): Promise<Resource> => {
  const createResourceResponse = await api.resources.createResource(createResourceAttributes)
  return createResourceResponse?.data
}

const updateResource = async (updateResourceAttributes: UpdateResourceAttributes): Promise<Resource> => {
  const updateResourceResponse = await api.resources.updateResource(updateResourceAttributes)
  return updateResourceResponse?.data
}

const deleteResource = async (deleteResourceAttributes: DeleteResourceRequest) => {
  await api.resources.deleteResource(deleteResourceAttributes)
}

const resourceService = {
  fetchResources,
  createResource,
  updateResource,
  deleteResource,
}

export default resourceService
