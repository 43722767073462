enum Path {
  Login = '/login',
  Error = '/error',
  Users = '/users',
  Plans = '/plans',
  CreatePlan = '/plans/new',
  DuplicatePlan = '/plans/new/:id',
  UpdatePlan = '/plans/edit/:id',
  Resources = '/resources',
  Customers = '/customers',
  CustomerDetails = '/customers/details/:id',
  Invoices = '/invoices',
  InvoiceDetail = '/invoices/:id',
  Events = '/events',
  InvoiceExternalProcessors = '/invoices/external-processors',
}

export default Path
