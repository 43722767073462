import { Button, Card, Text } from '@mantine/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { TbInfoTriangle } from 'react-icons/tb'

interface Props {
  onContinue: () => void
  onCancel: () => void
}

export default function SynchronizeModalCard({ onContinue, onCancel }: Props) {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Card padding="sm" radius="md">
      <div className="mb-4">
        <Text size="sm" fw={400} className="mb-2">
          {t('invoice:external-processor:sync-modal:description')}
        </Text>

        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="w-3 m-1">
              <AiOutlineInfoCircle size={16} color="#1C7ED6" className="mt-1" />
            </div>
            <Text size="sm" fw={400} className="text-justify">
              {t('invoice:external-processor:sync-modal:payment-customer-connection')}
            </Text>
          </div>
          <div className="flex gap-2">
            <div className="w-3 m-1">
              <AiOutlineInfoCircle size={16} color="#1C7ED6" className="mt-1" />
            </div>
            <Text size="sm" fw={400} className="text-justify">
              {t('invoice:external-processor:sync-modal:contract-connection')}
            </Text>
          </div>
          <div className="flex gap-2">
            <div className="w-3 m-1">
              <AiOutlineInfoCircle size={16} color="#1C7ED6" className="mt-1" />
            </div>
            <Text size="sm" fw={400} className="text-justify">
              {t('invoice:external-processor:sync-modal:update-order-values')}
            </Text>
          </div>
          <div className="flex gap-2">
            <div className="w-3 m-1">
              <TbInfoTriangle size={16} color="#E03131" className="mt-1" />
            </div>
            <Text size="sm" fw={400} className="text-justify">
              {t('invoice:external-processor:sync-modal:not-invoice-service')}
            </Text>
          </div>
        </div>
      </div>

      <Text size="sm" fw={400}>
        {t('invoice:external-processor:sync-modal:async-warning')}
      </Text>

      <hr className="my-4 border-t border-gray-200" />

      <div className="flex gap-2 mt-4">
        <Button
          className="min-w-0"
          color="violet"
          variant="filled"
          onClick={() => {
            setLoading(true)
            onContinue()
          }}
          loading={loading}
        >
          {t('invoice:synchronize')}
        </Button>
        <Button
          variant="transparent"
          className="text-black"
          onClick={onCancel}
        >
          {t('common:cancel')}
        </Button>
      </div>
    </Card>
  )
}
