import { Button, Title } from '@mantine/core'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCustomerQuery } from '../queries'
import useDeleteCustomerModal from './modal-actions/delete-customer-modal'

export default function CustomerSettings() {
  const { t } = useTranslation()
  const { id } = useParams()

  const { data: customer } = useCustomerQuery(id)

  const openDeleteCustomerModal = useDeleteCustomerModal()

  const handleDeleteCustomerModal = () => {
    if (!customer) return
    openDeleteCustomerModal(customer)
  }

  return (
    <div className="flex flex-col gap-4 ml-2 mt-4">
      <Title order={4} c="gray.9">{t('common:destructive-actions')}</Title>
      <Button className="w-40" color="red" variant="outline" onClick={handleDeleteCustomerModal}>{t('customer:delete-customer')}</Button>
    </div>
  )
}
