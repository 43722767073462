import PaymentInvoiceViewModel from '../../../interfaces/invoice/payment-invoice-view-model'
import { MatchKey } from '../../../interfaces/queries/match-key'
import Invoice from '../../../models/invoice'

const partialMatchKeys : MatchKey<Invoice>[] = ['id', 'customer.name']
const fullMatchKeys : MatchKey<Invoice>[] = []
const indexKey = 'id' as keyof Invoice
const paymentIndexKey = 'id' as keyof PaymentInvoiceViewModel

const tableInvoiceUtils = {
  partialMatchKeys,
  fullMatchKeys,
  indexKey,
  paymentIndexKey,
}

export default tableInvoiceUtils
