import { Badge } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BillingCycleStatus, ExternalProcessorSyncBillingCycleStatus } from '../../enum'
import { Path } from '../../../../shared/enums'

interface Props {
  status: BillingCycleStatus;
  externalProcessorSyncStatus: ExternalProcessorSyncBillingCycleStatus
  size?: 'sm' | 'md' | 'lg';
}

export function InvoiceStatusBadge({ status, externalProcessorSyncStatus, size = 'md' }: Props) {
  const { t } = useTranslation(['invoice'])
  const navigate = useNavigate()
  const formatStyleAttrs = () => {
    const stdAttrs = 'flex w-fit'

    switch (size) {
      case 'sm':
        return `p-2 ${stdAttrs}`
      case 'md':
        return `p-3 ${stdAttrs}`
      case 'lg':
        return `p-4 ${stdAttrs}`
      default:
        return `p-3 ${stdAttrs}`
    }
  }

  switch (status) {
    case BillingCycleStatus.Paid:
      return <Badge className={formatStyleAttrs()} variant="outline" bg="green.0" color="green">{t('invoice:status-label:paid')}</Badge>

    case BillingCycleStatus.Canceled:
      return <Badge className={formatStyleAttrs()} size={size} variant="outline" bg="gray.0" color="gray">{t('invoice:status-label:canceled')}</Badge>

    case BillingCycleStatus.Open:
      return <Badge className={formatStyleAttrs()} size={size} variant="outline" bg="blue.0" color="blue">{t('invoice:status-label:open')}</Badge>

    case BillingCycleStatus.Closed:
      switch (externalProcessorSyncStatus) {
        case ExternalProcessorSyncBillingCycleStatus.Pending:
          return (
            <Badge className={formatStyleAttrs()} size="md" variant="outline" bg="violet.0" color="violet" onClick={() => navigate(Path.InvoiceExternalProcessors)}>
              <span
                className="inline-block w-2 h-2 bg-violet-700 rounded-full mr-1 animate-blink"
                style={{
                  animation: 'blinker 2s linear infinite',
                }}
              />
              {t('invoice:external-processor:status-label:pending')}
            </Badge>
          )
        case ExternalProcessorSyncBillingCycleStatus.Success:
          return (
            <Badge className={formatStyleAttrs()} size={size} variant="outline" bg="violet.0" color="violet" onClick={() => navigate(Path.InvoiceExternalProcessors)}>
              {t('invoice:external-processor:status-label:success')}
            </Badge>
          )
        case ExternalProcessorSyncBillingCycleStatus.Failed:
          return (
            <Badge className={formatStyleAttrs()} size={size} variant="outline" bg="red.0" color="red" onClick={() => navigate(Path.InvoiceExternalProcessors)}>
              {t('invoice:external-processor:status-label:failed')}
            </Badge>
          )
        default:
          return <Badge className={formatStyleAttrs()} size={size} variant="outline" bg="yellow.0" color="yellow">{t('invoice:status-label:closed')}</Badge>
      }

    default:
      return <Badge className={formatStyleAttrs()} size={size} variant="outline" bg="blue.0" color="blue">{t('invoice:status-label:open')}</Badge>
  }
}
