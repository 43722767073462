import { toast } from 'react-toastify'
import UpsertCustomerAttributes from '../../../interfaces/customer/upsert-customer-attributes'
import { api } from '../../../libs'
import DeleteCustomerRequest from '../../../libs/api/requests/customers/delete-customer-request'
import { Customer } from '../../../models'
import i18n from '../../../config/i18n'

const fetchCustomers = async (): Promise<Customer[]> => {
  const getCustomersResponse = await api.customers.getCustomers()
  return getCustomersResponse.data
}

const fetchCustomer = async (customerId: string): Promise<Customer> => {
  const getCustomerResponse = await api.customers.getCustomer({ id: customerId })
  return getCustomerResponse.data
}

const createCustomer = async (createCustomerRequest: UpsertCustomerAttributes): Promise<Customer> => {
  const createCustomerResponse = await api.customers.createCustomer(createCustomerRequest)
  return createCustomerResponse?.data
}

const updateCustomer = async (upsertCustomerAttributes: UpsertCustomerAttributes): Promise<Customer> => {
  const { id } = upsertCustomerAttributes

  if (!id) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw toast.error(i18n.t('messages:error.default'))
  }

  const updateCustomerRequest = {
    id,
    externalId: upsertCustomerAttributes.externalId,
    name: upsertCustomerAttributes.name,
  }

  const updateCustomerResponse = await api.customers.updateCustomer(updateCustomerRequest)
  return updateCustomerResponse?.data
}

const deleteCustomer = async (deleteCustomerAttributes: DeleteCustomerRequest) => {
  await api.customers.deleteCustomer(deleteCustomerAttributes)
}

const customerService = {
  createCustomer,
  updateCustomer,
  fetchCustomers,
  fetchCustomer,
  deleteCustomer,
}

export default customerService
