function getEmptyKeys<T>(obj: T, ignoreKeys: (keyof T)[] = []): (keyof T)[] {
  const objectKeys = Object.keys(obj as object) as (keyof T)[]

  const incompleteKeys = objectKeys.filter((key) => {
    if (ignoreKeys.includes(key)) {
      return false
    }

    const value = obj[key]
    return value === undefined || value === null || value === ''
  })

  return incompleteKeys
}

export default getEmptyKeys
