import { Invoice } from '../../../models'
import { InvoiceFilter } from '../../../interfaces/invoice/invoice-filter'
import { ExternalProcessorSyncBillingCycleStatus } from '../enum'

const getDateRangeMatch = (invoice: Invoice, dateRange: [Date | null, Date | null]) => {
  const [startFilterDate, endFilterDate] = dateRange
  const endInvoiceDate = new Date(invoice.endDate)

  if (!startFilterDate && !endFilterDate) return true
  if (startFilterDate && !endFilterDate) return endInvoiceDate >= startFilterDate
  if (!startFilterDate && endFilterDate) return endInvoiceDate <= endFilterDate
  if (startFilterDate && endFilterDate) return endInvoiceDate >= startFilterDate && endInvoiceDate <= endFilterDate

  return true
}

const getFilteredInvoices = (invoices: Invoice[], filter: InvoiceFilter) => {
  const { customers, status, dateRange } = filter

  return invoices.filter((invoice) => {
    const isCustomerMatch = customers.length === 0 || customers.includes(invoice.customer.id)
    const isDateRangeMatch = getDateRangeMatch(invoice, dateRange)

    // For frontend display, merge invoice statuses into a unified display status.
    // Backend stores `status` and `externalProcessorSyncStatus` separately, but frontend
    // requires a distinct 'sync and closed' status for synced invoices that are also closed.
    const invoiceDisplayStatus = [
      ExternalProcessorSyncBillingCycleStatus.Failed,
      ExternalProcessorSyncBillingCycleStatus.Pending,
      ExternalProcessorSyncBillingCycleStatus.Success,
    ].includes(invoice.externalProcessorSyncStatus)
      ? invoice.externalProcessorSyncStatus
      : invoice.status

    const isStatusMatch = status.length === 0 || status.includes(invoiceDisplayStatus)

    return isCustomerMatch && isStatusMatch && isDateRangeMatch
  })
}

export { getFilteredInvoices }
