import axiosInstance from '../config/axios'
import GetPlanRequest from '../requests/plans/get-plan-request'
import CreatePlanRequest from '../requests/plans/create-plan-request'
import UpdatePlanRequest from '../requests/plans/update-plan-request'
import CreatePlanResponse from '../responses/plans/create-plan-response'
import GetPlansResponse from '../responses/plans/get-plans-response'
import GetPlanResponse from '../responses/plans/get-plan-response'
import UpdatePlanResponse from '../responses/plans/update-plan-response'
import DeletePlanRequest from '../requests/plans/delete-plan-request'
import RateAdjustPlanRequest from '../requests/plans/rate-adjust-plan-request'
import RateAdjustPlanResponse from '../responses/plans/rate-adjust-plan-response'

const PLANS_BASE_URL = '/plans'

const getPlan = async (request: GetPlanRequest) => (axiosInstance.get<GetPlanResponse>(`${PLANS_BASE_URL}/${request.id}`))

const getPlans = async () => (axiosInstance.get<GetPlansResponse>(PLANS_BASE_URL))

const createPlan = async (request: CreatePlanRequest) => (axiosInstance.post<CreatePlanResponse>(PLANS_BASE_URL, request))

const updatePlan = async (request: UpdatePlanRequest) => (axiosInstance.put<UpdatePlanResponse>(`${PLANS_BASE_URL}/${request.id}`, request))

const deletePlan = async (request: DeletePlanRequest) => (axiosInstance.delete(`${PLANS_BASE_URL}/${request.id}`))

const rateAdjustPlan = async (request: RateAdjustPlanRequest) => (axiosInstance.post<RateAdjustPlanResponse>(`${PLANS_BASE_URL}/${request.planId}/rate-adjust`, {
  percentage: request.percentage,
}))

export default {
  getPlan,
  getPlans,
  createPlan,
  updatePlan,
  deletePlan,
  rateAdjustPlan,
}
