import {
  Card, Text,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Customer, PaymentAccount } from '../../../../models'
import InfoItem from '../../../../shared/components/display/info-item'
import { formatTaxIdForDisplay } from '../../../payment-account/utils/formatters'

interface Props {
  customer: Customer
  paymentAccount: PaymentAccount
}

export default function InvoiceDetailCustomerCard({ customer, paymentAccount }: Props) {
  const { t } = useTranslation(['invoice', 'customer'])

  return (
    <Card padding="md" radius="md" className="flex flex-col border border-gray-border-default">
      <Text size="md" fw={600} className="text-grayscale-text-title mb-3">
        {t('customer:payment-account.name')}
      </Text>
      <div className="flex flex-col items-start gap-3">
        <Text size="md" fw={500} className="text-grayscale-text-body">{paymentAccount.tradeName ? paymentAccount.tradeName : paymentAccount.businessName}</Text>
        {paymentAccount.tradeName ? (
          <Text size="sm" className="text-grayscale-text-subtitle">{paymentAccount.businessName}</Text>
        ) : null}
      </div>

      <div>
        <InfoItem
          className="mt-2"
          title={t('customer:customer-name')}
          value={customer.name}
        />
        {paymentAccount.taxId && (
          <InfoItem
            className="mt-2"
            title={t('customer:tax-id')}
            value={formatTaxIdForDisplay(paymentAccount.taxId)}
          />
        )}
        {paymentAccount.email && (
          <InfoItem
            className="mt-2"
            title={t('customer:email')}
            value={paymentAccount.email}
          />
        )}
      </div>
    </Card>
  )
}
