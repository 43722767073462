import { LuUsers } from 'react-icons/lu'
import { MdAttachMoney } from 'react-icons/md'
import { RxStack } from 'react-icons/rx'
import { AiOutlineThunderbolt } from 'react-icons/ai'
import { FaRegHandshake } from 'react-icons/fa'
import { GrIntegration } from 'react-icons/gr'
import { Page } from '../interfaces'
import { Path } from '../shared/enums'
import Users from './users'
import Login from './login'
import Resources from './resources'
import Plans from './plans'
import CreatePlan from './create-plan'
import UpdatePlan from './update-plan'
import Customers from './customers'
import Invoices from './invoices'
import InvoiceDetail from './invoice-detail'
import ErrorFallback from '../features/error/components/error-fallback'
import Events from './events'
import CustomerDetails from './customer-details'
import InvoiceExternalProcessors from './invoice-external-processors'
import LayoutVersion from '../shared/enums/layout-version'

const pages: Page[] = [
  {
    component: <Login />,
    path: Path.Login,
    version: LayoutVersion.BASIC,
  },
  {
    component: <ErrorFallback />,
    path: Path.Error,
    version: LayoutVersion.BASIC,
  },
  {
    component: <Users />,
    path: Path.Users,
    titleInternationalizationKey: 'common:page-title.users',
    sideMenuOption: {
      icon: LuUsers,
      titleInternationalizationKey: 'common:side-menu-title.users',
    },
  },
  {
    component: <Plans />,
    path: Path.Plans,
    titleInternationalizationKey: 'common:page-title.plans',
    sideMenuOption: {
      icon: RxStack,
      titleInternationalizationKey: 'common:side-menu-title.plans',
    },
  },
  {
    component: <CreatePlan />,
    path: Path.CreatePlan,
    titleInternationalizationKey: 'common:page-title.create-plan',
    breadcrumbs: [
      {
        titleInternationalizationKey: 'common:breadcrumb-title.plans',
        path: Path.Plans,
      },
      { titleInternationalizationKey: 'common:breadcrumb-title.create-plan' },
    ],
  },
  {
    component: <CreatePlan />,
    path: Path.DuplicatePlan,
    titleInternationalizationKey: 'common:page-title.create-plan',
    breadcrumbs: [
      {
        titleInternationalizationKey: 'common:breadcrumb-title.plans',
        path: Path.Plans,
      },
      { titleInternationalizationKey: 'common:breadcrumb-title.create-plan' },
    ],
  },
  {
    component: <UpdatePlan />,
    path: Path.UpdatePlan,
    titleInternationalizationKey: 'common:page-title.edit-plan',
    breadcrumbs: [
      {
        titleInternationalizationKey: 'common:breadcrumb-title.plans',
        path: Path.Plans,
      },
      { titleInternationalizationKey: 'common:breadcrumb-title.edit-plan' },
    ],
  },
  {
    component: <Resources />,
    path: Path.Resources,
    titleInternationalizationKey: 'common:page-title.resources',
    sideMenuOption: {
      icon: AiOutlineThunderbolt,
      titleInternationalizationKey: 'common:side-menu-title.resources',
    },
  },
  {
    component: <Customers />,
    path: Path.Customers,
    titleInternationalizationKey: 'common:page-title.customers',
    sideMenuOption: {
      icon: FaRegHandshake,
      titleInternationalizationKey: 'common:side-menu-title.customers',
    },
  },
  {
    component: <CustomerDetails />,
    path: Path.CustomerDetails,
    version: LayoutVersion.V2,
    breadcrumbs: [
      {
        titleInternationalizationKey: 'common:breadcrumb-title.customers',
        path: Path.Customers,
      },
      { titleInternationalizationKey: 'common:breadcrumb-title.customer-details' },
    ],
  },
  {
    component: <Invoices />,
    path: Path.Invoices,
    titleInternationalizationKey: 'common:page-title.invoices',
    sideMenuOption: {
      icon: MdAttachMoney,
      titleInternationalizationKey: 'common:side-menu-title.invoices',
    },
  },
  {
    component: <InvoiceDetail />,
    path: Path.InvoiceDetail,
    version: LayoutVersion.V2,
    titleInternationalizationKey: 'common:page-title.view-invoice',
    breadcrumbs: [
      {
        titleInternationalizationKey: 'common:breadcrumb-title.invoices',
        path: Path.Invoices,
      },
      { titleInternationalizationKey: 'common:breadcrumb-title.view-invoice' },
    ],
  },
  {
    component: <InvoiceExternalProcessors />,
    path: Path.InvoiceExternalProcessors,
    breadcrumbs: [
      {
        titleInternationalizationKey: 'common:breadcrumb-title.invoices',
        path: Path.Invoices,
      },
      { titleInternationalizationKey: 'common:side-menu-title:processes' },
    ],
  },
  {
    component: <Events />,
    path: Path.Events,
    titleInternationalizationKey: 'common:page-title.events',
    sideMenuOption: {
      icon: GrIntegration,
      titleInternationalizationKey: 'common:side-menu-title.events',
    },
  },
]

export default pages
