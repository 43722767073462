import { useState } from 'react'
import TableMenuCell from '../../../../shared/components/table/table-menu-cell'
import ExtendedUser from '../../../../models/extended-user'
import UpdateUserModal from '../update-user-modal'
import { useDeleteUserHandlerModal } from '../modal-actions/delete-user-handler-modal'
import { Group } from '../../../../shared/enums'

interface Props {
  rowData: ExtendedUser;
}

export function TableUserMenuCell({ rowData }: Props) {
  const deleteUserHandlerModal = useDeleteUserHandlerModal()

  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false)

  return (
    <div className="cursor-pointer flex justify-end">
      {isUpdateUserModalOpen && (
        <UpdateUserModal
          opened={isUpdateUserModalOpen}
          onClose={() => setIsUpdateUserModalOpen(false)}
          user={rowData}
        />
      )}

      {rowData.groups.includes(Group.ExternalCustomer) && (
        <TableMenuCell
          name={rowData.name}
          onDelete={() => deleteUserHandlerModal(rowData)}
          onEdit={() => setIsUpdateUserModalOpen(true)}
        />
      )}
    </div>
  )
}
