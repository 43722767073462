import themeConfiguration from '../../theme/theme-configuration'

const ROW_HEIGHT_SIZE = 11 * themeConfiguration.spacingMultiplier
const USER_SESSION_KEY = 'user-session'
const MAX_TEXT_FIELD_LENGTH = 150
const MAX_LONG_TEXT_FIELD_LENGTH = 3000
const MIN_PASSWORD_FIELD_LENGTH = 8

export {
  ROW_HEIGHT_SIZE,
  USER_SESSION_KEY,
  MAX_TEXT_FIELD_LENGTH,
  MAX_LONG_TEXT_FIELD_LENGTH,
  MIN_PASSWORD_FIELD_LENGTH,
}
