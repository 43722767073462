import { EventsPageActions, TableEvents } from '../features/event/components'

export default function Events() {
  return (
    <div className="h-full w-full flex flex-col">
      <EventsPageActions />
      <TableEvents />
    </div>
  )
}
