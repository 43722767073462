import { Badge } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ExternalProcessorSyncBillingCycleStatus } from '../../enum'
import { Path } from '../../../../shared/enums'

interface Props {
  value: any
}

export function TableInvoiceExternalProcessorStatus({ value }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  switch (value) {
    case ExternalProcessorSyncBillingCycleStatus.Pending:
      return (
        <Badge size="md" variant="outline" bg="violet.0" color="violet" onClick={() => navigate(Path.InvoiceExternalProcessors)}>
          <span
            className="inline-block w-2 h-2 bg-violet-700 rounded-full mr-1 animate-blink"
            style={{
              animation: 'blinker 2s linear infinite',
            }}
          />
          {t('invoice:external-processor:status-label:pending')}
        </Badge>
      )
    case ExternalProcessorSyncBillingCycleStatus.Success:
      return (
        <Badge size="md" variant="outline" bg="violet.0" color="violet" onClick={() => navigate(Path.InvoiceExternalProcessors)}>
          {t('invoice:external-processor:status-label:success')}
        </Badge>
      )
    case ExternalProcessorSyncBillingCycleStatus.Failed:
      return (
        <Badge size="md" variant="outline" bg="red.0" color="red" onClick={() => navigate(Path.InvoiceExternalProcessors)}>
          {t('invoice:external-processor:status-label:failed')}
        </Badge>
      )
    default:
      return (
        <Badge size="md" variant="outline" bg="violet.0" color="violet" onClick={() => navigate(Path.InvoiceExternalProcessors)}>
          <span
            className="inline-block w-2 h-2 bg-violet-700 rounded-full mr-1 animate-blink"
            style={{
              animation: 'blinker 2s linear infinite',
            }}
          />
          {t('invoice:external-processor:status-label:pending')}
        </Badge>
      )
  }
}
