import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import AlertCard from '../../../../shared/components/alert/alert-card'

export const useDeletePlanErrorAlert = () => {
  const { t } = useTranslation()

  return () => {
    modals.open({
      withCloseButton: false,
      children: (
        <AlertCard
          variant="warning"
          title={t('plan:delete-error-alert:title') as string}
          description={t('plan:delete-error-alert:content') as string}
          onDismiss={modals.closeAll}
        />
      ),
    })
  }
}
