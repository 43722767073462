import { create } from 'zustand'

import { EventFilter } from '../interfaces/event-filter'

interface EventFilterState {
  filter: EventFilter;
  actions: {
    setCustomerId: (customerId: string | null) => void;
    setResourceId: (resourceId: string | null) => void;
    setCreatedAtRange: (createdAtRange: [Date | null, Date | null]) => void;
    setOccurredAtRange: (occurredAtRange: [Date | null, Date | null]) => void;
  }
}

const useEventFilterStore = create<EventFilterState>((set) => ({
  filter: {
    customerId: null,
    resourceId: null,
    createdAtRange: [null, null],
    occurredAtRange: [null, null],
  },
  actions: {
    setCustomerId: (customerId) => set((state) => ({ filter: { ...state.filter, customerId } })),
    setResourceId: (resourceId) => set((state) => ({ filter: { ...state.filter, resourceId } })),
    setCreatedAtRange: (createdAtRange) => set((state) => ({ filter: { ...state.filter, createdAtRange } })),
    setOccurredAtRange: (occurredAtRange) => set((state) => ({ filter: { ...state.filter, occurredAtRange } })),
  },
}))

export const useEventFilter = () => useEventFilterStore((state) => state.filter)
export const useEventFilterActions = () => useEventFilterStore((state) => state.actions)
