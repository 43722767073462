import { TextInput, PasswordInput, Select } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UpsertUserAttributes } from '../../../interfaces'
import { MAX_TEXT_FIELD_LENGTH, MIN_PASSWORD_FIELD_LENGTH } from '../../../shared/utils/constants'
import { emailValidationRegex } from '../../../shared/utils/regex'
import { Group } from '../../../shared/enums'
import { useCustomersQuery } from '../../customer/queries'
import buildCustomerOptions from '../../customer/utils/build-customer-options'

interface Props {
  isUpdate?: boolean
}

export default function UserForm({ isUpdate }: Props) {
  const { t } = useTranslation(['common', 'user'])

  const {
    register,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useFormContext<UpsertUserAttributes>()

  const groupsData = [
    {
      label: t('user:permission-name:admin'),
      value: Group.Admin,
    },
    {
      label: t('user:permission-name:external-customer'),
      value: Group.ExternalCustomer,
    },
  ]

  const userGroup = watch('group')

  const {
    data: customers,
    isLoading,
  } = useCustomersQuery()

  const customerOptions = buildCustomerOptions(customers)

  return (
    <div>
      <TextInput
        className="mb-4"
        label={t('user:name') as string}
        placeholder={t('user:name') as string}
        error={errors.name && errors.name.message}
        required
        {...register('name', {
          required: { value: true, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />

      <TextInput
        className="mb-4"
        label={t('user:email') as string}
        placeholder={t('user:email') as string}
        error={errors.email && errors.email.message}
        required
        {...register('email', {
          required: { value: true, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
          validate: {
            validEmail: (email) => emailValidationRegex.test(email) || t('common:form-error:invalid-email') as string,
          },
        })}
      />

      <PasswordInput
        className="mb-4"
        label={t('user:password') as string}
        placeholder={t('user:password') as string}
        error={errors.password && errors.password.message}
        required={!isUpdate}
        description={isUpdate && t('user:update-password-user-description')}
        {...register('password', {
          required: { value: !isUpdate, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
          minLength: { value: MIN_PASSWORD_FIELD_LENGTH, message: t('user:form:error-message:password-min-length') },
        })}
      />

      <Controller
        name="group"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            {...field}
            className="w-full mb-4"
            label={`${t('user:permission') as string}`}
            placeholder={t('user:permission') as string}
            description={t('user:permission-description') as string}
            data={groupsData}
            onChange={(group) => {
              if (group === Group.Admin) {
                setValue('customerId', null)
              }

              field.onChange(group)
            }}
            error={errors.group && errors.group?.message}
            required
            searchable
            disabled={isUpdate}
          />
        )}
      />

      {userGroup === Group.ExternalCustomer && (
        <Controller
          name="customerId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              className="w-full"
              label={`${t('user:customer') as string}`}
              placeholder={t('user:customer') as string}
              description={t('user:customer-description') as string}
              data={customerOptions}
              error={errors.customerId && errors.customerId?.message}
              required
              searchable
              disabled={isLoading}
            />
          )}
        />
      )}
    </div>
  )
}
