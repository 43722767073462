import { Card, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import MetricSummary from '../../metric/components/metric-summary'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import formatToCurrency from '../../../shared/utils/helpers/format-to-currency'
import Divider from '../../../shared/components/display/divider'
import PlanSummaryInfo from './plan-summary-info'

interface Props {
  plan: UpsertExtendedPlanAttributes;
}

export default function PlanSummary({ plan }: Props) {
  const { t, i18n } = useTranslation(['plan', 'common'])
  const { language } = i18n

  return (
    <Card padding="md" radius="md">
      <div className="flex flex-col w-full">
        <Text fz="sm" fw={600} className="">{t('plan:plan-summary')}</Text>
      </div>
      <div className="mt-4 gap-4 w-90">
        <Divider />
        {plan.planSettings && (
        <PlanSummaryInfo
          label={t('common:fixed-amount')}
          planFixedAmount={formatToCurrency({ price: plan.planSettings.fixedAmount, language })}
        />
        )}
        <MetricSummary metrics={plan.metrics} />
      </div>
    </Card>
  )
}
