import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TableColumn } from '../../../../../interfaces'
import tableUserUtils from '../../../utils/table-invoice-utils'
import { useFilteredInvoicesQuery } from '../../../queries'
import { TableDateCell } from '../../../../../shared/components/table/table-date-cell'
import InvoiceViewModel from '../../../../../interfaces/invoice/invoice-view-model'
import { TableInvoiceAmountCell } from '../table-invoice-amount-cell'
import { Path } from '../../../../../shared/enums'
import { TableInvoiceStatusCell } from '../table-invoice-status-cell'
import { BillingCycleStatus } from '../../../enum'
import { CoreTable } from '../../../../../shared/components/table'

export default function CoreTableInvoices() {
  const { t } = useTranslation(['invoice', 'common'])
  const navigate = useNavigate()

  const {
    data: invoices,
    isLoading: isInvoicesQueryLoading,
    isFetching,
  } = useFilteredInvoicesQuery()

  const invoicesToViewModel: InvoiceViewModel[] = invoices
    ? invoices.map((invoice) => ({
      ...invoice,
      customerName: invoice.customer.name,
      contractName: undefined,
      paymentAccountName: invoice.paymentAccount.tradeName || invoice.paymentAccount.businessName,
      displayAmount: {
        amount: invoice.totalAmount,
        status: invoice.status,
      },
    })) : []

  const tableUserColumns: TableColumn<InvoiceViewModel>[] = [
    {
      label: t('invoice:id'),
      key: 'id',
      columnKey: 'id',
      widthPercentage: 20,
    },
    {
      label: t('invoice:client'),
      key: 'customerName',
      columnKey: 'customerId',
      widthPercentage: 25,
    },
    {
      label: t('customer:payment-account:name'),
      key: 'paymentAccountName',
      columnKey: 'paymentAccountId',
      widthPercentage: 25,
    },
    {
      label: t('invoice:status'),
      key: 'status',
      columnKey: 'status',
      widthPercentage: 25,
      ignoreRowClick: (rowData: InvoiceViewModel) => rowData.status === BillingCycleStatus.Closed,
      Component: TableInvoiceStatusCell,
    },
    {
      label: t('invoice:billing-date'),
      key: 'endDate',
      columnKey: 'endDate',
      widthPercentage: 20,
      Component: TableDateCell,
    },
    {
      label: t('invoice:value'),
      key: 'displayAmount',
      columnKey: 'displayAmount',
      widthPercentage: 25,
      Component: TableInvoiceAmountCell,
    },
  ]

  const handleInvoiceDetail = (invoice: InvoiceViewModel) => {
    navigate(Path.InvoiceDetail.replace(':id', invoice.id))
  }

  return (
    <CoreTable<InvoiceViewModel>
      data={invoicesToViewModel || []}
      indexKey={tableUserUtils.indexKey}
      columns={tableUserColumns}
      isFetching={isFetching}
      isLoading={isInvoicesQueryLoading}
      pageConfiguration={{
        pageVariation: 1,
      }}
      rowAction={handleInvoiceDetail}
    />
  )
}
