import { Text } from '@mantine/core'

interface Props {
  label: string
  planFixedAmount: string
}

export default function PlanSummaryInfo({ label, planFixedAmount }: Props) {
  return (
    <div className="mt-4 flex flex-row w-full justify-between">
      <Text fz="md">
        {label}
      </Text>
      <Text fz="md" className="text-gray-600">
        {planFixedAmount}
      </Text>
    </div>
  )
}
