/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreatePlan, usePlanQuery } from '../queries/plan-queries'
import { Path } from '../../../shared/enums'
import PlanFormContainer from './plan-form-container'
import PlanSummaryContainer from './plan-summary-container'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import { duplicatedPlanToFormValue, upsertExtendedPlanAttributesToExtendedPlan } from '../utils/upsert-plan-utils'
import UpdatePlanFormSkeleton from './update-plan-form-skeleton'

export default function CreatePlanForm() {
  const navigate = useNavigate()

  const { id } = useParams()

  const [userIsDuplicatingPlan, setUserIsDuplicatingPlan] = useState(false)

  const { data: duplicatedPlan, isLoading: isQueryLoading } = usePlanQuery(id)

  const useFormMethods = useForm<UpsertExtendedPlanAttributes>({
    mode: 'onTouched',
    defaultValues: {
      planSettings: {
        fixedAmount: 0,
      },
    },
  })

  const {
    handleSubmit, reset, watch, formState: { isValid },
  } = useFormMethods

  useEffect(() => {
    if (!userIsDuplicatingPlan && id && duplicatedPlan) {
      setUserIsDuplicatingPlan(true)
      const duplicatedPlanForm = duplicatedPlanToFormValue(duplicatedPlan)
      reset(duplicatedPlanForm)
    }
  }, [userIsDuplicatingPlan, duplicatedPlan, id, reset])

  const handleCreatePlanSuccess = () => {
    navigate(Path.Plans)
    reset()
  }

  const handleCancel = () => {
    navigate(Path.Plans)
    reset()
  }

  const handleCreatePlan = (createPlanAttributes: UpsertExtendedPlanAttributes) => {
    const planAttributes = upsertExtendedPlanAttributesToExtendedPlan(createPlanAttributes)
    return planAttributes
  }

  const formValues = watch()

  const { mutate, isLoading } = useCreatePlan({ onSuccess: handleCreatePlanSuccess })

  if (isQueryLoading) {
    return (
      <UpdatePlanFormSkeleton />
    )
  }

  return (
    <FormProvider {...useFormMethods}>
      <div className="flex flex-row gap-4">

        <form
          id="plan-form"
          onSubmit={
           handleSubmit((createPlanAttributes: UpsertExtendedPlanAttributes) => mutate(handleCreatePlan(createPlanAttributes)))
         }
          onKeyDown={(e:React.KeyboardEvent<HTMLFormElement>) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
          className="flex flex-col w-full"
        >
          <PlanFormContainer isValid={isValid} isLoading={isLoading} handleCancel={handleCancel} />
        </form>

        <PlanSummaryContainer isValid={isValid} formValues={formValues} isLoading={isLoading} handleCancel={handleCancel} />

      </div>
    </FormProvider>
  )
}
