import { Button, TextInput, PasswordInput } from '@mantine/core'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoginAttributes } from '../../../interfaces'
import { emailValidationRegex } from '../../../shared/utils/regex'

interface Props {
  onLoginSubmit: (user: LoginAttributes) => void
}

export default function LoginForm({ onLoginSubmit }: Props) {
  const { t } = useTranslation(['common', 'user', 'auth'])

  const {
    handleSubmit, register, formState: { errors, isValid },
  } = useForm<LoginAttributes>({ mode: 'onTouched' })

  return (
    <div>
      <form onSubmit={handleSubmit(onLoginSubmit)}>
        <TextInput
          className="mb-6"
          placeholder={t('user:user') as string}
          error={errors.email && errors.email.message}
          {...register('email', {
            required: { value: true, message: t('common:form-error:required-field') },
            validate: {
              validEmail: (email) => emailValidationRegex.test(email) || t('common:form-error:invalid-email') as string,
            },
          })}
        />
        <PasswordInput
          placeholder={t('user:password') as string}
          error={errors.password && errors.password.message}
          {...register('password', {
            required: { value: true, message: t('common:form-error:required-field') },
          })}
        />
        <Button
          fullWidth
          disabled={!isValid}
          color="violet"
          className="mt-8"
          type="submit"
        >
          {t('auth:login.form.enter')}
        </Button>
      </form>
    </div>
  )
}
