import { modals } from '@mantine/modals'
import { Trans, useTranslation } from 'react-i18next'
import AlertCard from '../../../../shared/components/alert/alert-card'
import UpsertContractAttributes from '../../../../interfaces/contract/upsert-contract-attributes'
import i18n from '../../../../config/i18n'

interface Props {
  onContinue: () => void
  upsertContractAttributes: UpsertContractAttributes
}

const useUpdateContractAlert = () => {
  const { t } = useTranslation()

  return ({ onContinue, upsertContractAttributes }: Props) => modals.open({
    children: (
      <AlertCard
        variant="warning"
        title={<Trans i18nKey={t('customer:contract:editing-contract:title')} />}
        description={upsertContractAttributes.endDate ? (
          <Trans i18nKey={t('customer:contract:editing-contract:alert', {
            startDate: upsertContractAttributes.startDate.toLocaleDateString(i18n.language),
            endDate: upsertContractAttributes.endDate.toLocaleDateString(i18n.language),
          })}
          />
        ) : (
          <Trans i18nKey={t('customer:contract:editing-contract:alert-auto-renewal', {
            startDate: upsertContractAttributes.startDate.toLocaleDateString(i18n.language),
          })}
          />
        )}
        onContinue={() => {
          modals.closeAll()
          onContinue()
        }}
        onCancel={modals.closeAll}
      />
    ),
    withCloseButton: false,
  })
}

export default useUpdateContractAlert
