import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PageWrapper } from './features/layout'
import usePages from './shared/hooks/use-pages'
import RouteListener from './router-listener'
import NotFoundPage from './features/layout/components/exception-pages/not-found-page'

function Router() {
  const pages = usePages()

  return (
    <BrowserRouter>
      <RouteListener />
      <Routes>
        {pages.map((page) => (
          <Route
            key={page.path}
            path={page.path}
            element={<PageWrapper>{page.component}</PageWrapper>}
          />
        ))}
        <Route
          path="*"
          element={(<NotFoundPage />)}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
