import {
  Combobox, InputBase, useCombobox,
} from '@mantine/core'
import { useState } from 'react'
import { SelectOption } from './select-option'

interface Item<ModelSchema> {
  value: ModelSchema;
  label: string;
  description: string;
}

interface Props<ModelSchema> {
  placeholder: string;
  label: string;
  value: ModelSchema;
  setValue: (value: ModelSchema) => void;
  data: Item<ModelSchema>[];
}

export function SelectWithDescription<ModelSchema>({
  placeholder, label, value, setValue, data,
}: Props<ModelSchema>) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const selectedOption = data.find((item) => item.value === value)

  const [searchQuery, setSearchQuery] = useState(selectedOption?.label || '')

  const shouldFilterOptions = data.every((item) => item.label !== searchQuery)
  const filteredOptions = shouldFilterOptions
    ? data.filter((item) => item.label.toLowerCase().includes(searchQuery.toLowerCase().trim()))
    : data

  const comboboxOptions = filteredOptions.map((item) => (
    <Combobox.Option value={item.label} key={item.label}>
      <SelectOption {...item} />
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(selectedLabel) => {
        const option = data.find((item) => item.label === selectedLabel)
        if (option) setValue(option.value)
        setSearchQuery(selectedLabel)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          label={label}
          rightSection={<Combobox.Chevron />}
          value={searchQuery}
          onChange={(event) => {
            combobox.openDropdown()
            setSearchQuery(event.currentTarget.value)
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          placeholder={placeholder}
          rightSectionPointerEvents="none"
          multiline
          required
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {comboboxOptions}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
