import { Title } from '@mantine/core'
import { modals } from '@mantine/modals'
import { Trans, useTranslation } from 'react-i18next'
import AlertCard from '../../../../shared/components/alert/alert-card'
import { useCalculateInvoice } from '../../queries'

const useCalculateInvoiceModal = () => {
  const { t } = useTranslation()

  const { mutate, isLoading } = useCalculateInvoice({ onSuccess: modals.closeAll })

  return (invoiceId: string) => modals.open({
    withCloseButton: true,
    title: <Title order={4} fw={600}>{t('invoice:calculate-modal:title')}</Title>,
    children: (
      <AlertCard
        variant="warning"
        description={(<Trans i18nKey={t('invoice:calculate-modal:description')} />)}
        onContinue={() => {
          mutate(invoiceId)
          modals.closeAll()
        }}
        onCancel={modals.closeAll}
        isContinueLoading={isLoading}
      />
    ),
  })
}

export default useCalculateInvoiceModal
