import { useTranslation } from 'react-i18next'
import EmptyContentMessage from '../../../shared/components/designs/empty-content-message'
import { PaymentAccount } from '../../../models'
import PaymentAccountCard from './payment-account-card'

interface Props {
  paymentAccounts: PaymentAccount[];
}

export default function PaymentAccountsList({ paymentAccounts }: Props) {
  const { t } = useTranslation('customer')

  if (paymentAccounts && paymentAccounts.length === 0) {
    return (
      <div className="py-20">
        <EmptyContentMessage text={t('customer:empty-payment-accounts')} />
      </div>
    )
  }

  return (
    <div className="flex flex-col mt-4 gap-4">
      {paymentAccounts?.map((paymentAccount) => (
        <div key={paymentAccount.id}>
          <PaymentAccountCard paymentAccount={paymentAccount} />
        </div>
      ))}
    </div>
  )
}
