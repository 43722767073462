import ContractDueOffsetDaysInput from './contract-payment-due-offset-days-input'
import ContractScheduledPaymentDayInput from './contract-scheduled-payment-day-input'

interface Props {
  isUpdate?: boolean
}

export default function ContractPaymentScheduleForm({ isUpdate }: Props) {
  return (
    <div className="flex flex-col overflow-auto mt-2 mb-4">
      <div className="w-full flex flex-col gap-4">
        <ContractScheduledPaymentDayInput isUpdate={isUpdate} />
        <ContractDueOffsetDaysInput isUpdate={isUpdate} />
      </div>
    </div>
  )
}
