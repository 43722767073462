import { Paper } from '@mantine/core'
import CustomerTabs from '../features/customer/components/customer-tabs'
import UpdateCustomerForm from '../features/customer/components/update-customer-form'

export default function CustomerDetails() {
  return (
    <div className="h-full w-full flex flex-col overflow-hidden">
      <Paper shadow="xs" className="w-full h-full pt-5 pb-5 pr-8 pl-8">

        <div className="flex flex-col gap-8 mb-4">
          <UpdateCustomerForm />
          <CustomerTabs />
        </div>

      </Paper>
    </div>
  )
}
