import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextInput, Text } from '@mantine/core'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import { MAX_TEXT_FIELD_LENGTH } from '../../../shared/utils/constants'
import { ExtendedContract } from '../../../models'

interface Props {
  updatedContract?: ExtendedContract
}

export default function ContractOmieIntegrationForm({ updatedContract }: Props) {
  const { t } = useTranslation()

  const {
    register, formState: { errors },
  } = useFormContext<UpsertContractAttributes>()

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <Text fw={600} className="mb-4">
          { updatedContract?.processorId
            ? t('customer:contract:form-sections:integrations:omie-integration:contract-connected-success')
            : t('customer:contract:form-sections:integrations:omie-integration:connect-with-omie-contract')}
        </Text>

        <Text>
          { updatedContract?.processorId
            ? t('customer:contract:form-sections:integrations:omie-integration:change-omie-contract-description')
            : t('customer:contract:form-sections:integrations:omie-integration:connect-with-omie-contract-description')}
        </Text>
      </div>

      <TextInput
        label={t('customer:contract:form-sections:integrations:omie-integration:contract-number') as string}
        placeholder="2024/00007"
        error={errors.omieDetails?.contractNumber?.message}
        {...register('omieDetails.contractNumber', {
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />
    </div>
  )
}
