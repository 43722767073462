interface Props {
  price: number;
  language: string;
}

export default function formatToCurrency({ price, language }: Props) {
  const currencyFormat = language === 'pt' ? 'BRL' : 'USD'

  const formattedPrice = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'currency', currency: currencyFormat, minimumFractionDigits: 2, maximumFractionDigits: 6,
    },
  )
    .format(price / 100)

  return formattedPrice
}
