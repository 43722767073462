import { ReactElement } from 'react'
import { Modal as MantineModal, Text } from '@mantine/core'

interface Props {
  opened: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  children: ReactElement;
  size?: 'md' | 'lg' | 'xl';
  hideHeader?: boolean;
}

export default function Modal({
  opened, onClose, title, children, size = 'md', hideHeader = false,
}: Props) {
  return (
    <MantineModal
      opened={opened}
      onClose={onClose}
      size={size}
      title={title && <Text className="w-90 truncate text-h4" fw={600}>{title}</Text>}
      withCloseButton={!hideHeader}
      px={6}
    >
      {children}
    </MantineModal>
  )
}
