import { useEffect, useState } from 'react'
import { TextInput } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { AiOutlineSearch } from 'react-icons/ai'

interface Props {
  setSearchTerm: (searchTerm: string) => void;
  placeholder?: string;
  fullWidth?: boolean;
  searchTerm?: string;
  delay?: number;
}

export default function SearchInput({
  setSearchTerm, placeholder, fullWidth, searchTerm = '', delay,
}: Props) {
  const { t } = useTranslation('common')
  const placeholderText = placeholder || t('input.search-placeholder') as string
  const width = fullWidth ? 'w-full' : 'w-7/12'
  const [inputValue, setInputValue] = useState(searchTerm)

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerm(inputValue)
    }, delay || 0)

    return () => {
      clearTimeout(handler)
    }
  }, [inputValue, delay, setSearchTerm])

  return (
    <TextInput
      className={`max-w-md ${width}`}
      placeholder={placeholderText}
      mb="md"
      value={inputValue}
      leftSection={<AiOutlineSearch size="0.9rem" />}
      onChange={(e) => setInputValue(e.target.value)}
    />
  )
}
