import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import ProrationUsage from '../../../../../models/proration-usage'

interface Props {
  rowData: ProrationUsage;
}

export function TableProrationIntervalCell({ rowData }: Props) {
  const { t, i18n } = useTranslation('invoice')
  const { language } = i18n

  const intervalStart = new Date(rowData.intervalStart)
  const intervalEnd = new Date(rowData.intervalEnd)
  return (
    <div className="truncate">
      {intervalEnd.getTime() === intervalStart.getTime() ? (
        <Text>
          {intervalStart.toLocaleDateString(language)}
        </Text>
      ) : (
        <Text>
          {intervalStart.toLocaleDateString(language)}
          {' '}
          {t('invoice:proration-usage:until')}
          {' '}
          {intervalEnd.toLocaleDateString(language)}
        </Text>
      )}
    </div>
  )
}
