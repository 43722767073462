import BillingCycleExternalProcessorUserCodes from '../../../libs/api/enums/billing-cycle-external-processor-user-codes'
import billingCycleExternalProcessorErrorMessages from './billing-cycle-external-processor-messages'

const getBillingCycleExternalProcessorErrorMessage = (errorCode?: BillingCycleExternalProcessorUserCodes) => {
  if (!errorCode || !(errorCode in billingCycleExternalProcessorErrorMessages)) {
    return billingCycleExternalProcessorErrorMessages.default
  }

  return billingCycleExternalProcessorErrorMessages[errorCode as keyof typeof billingCycleExternalProcessorErrorMessages]
}

export default getBillingCycleExternalProcessorErrorMessage
