import { Params } from 'react-router-dom'
import { Page } from '../../../interfaces'
import Breadcrumb from '../../../interfaces/page/breadcrumb'

const getPageBreadcrumbs = (page: Page, pageParams: Params<string>) => {
  const breadcrumbs = page.breadcrumbs?.map(({ titleInternationalizationKey, path }) => {
    const breadcrumb: Breadcrumb = { titleInternationalizationKey, path }

    Object.entries(pageParams).forEach(([key, value]) => {
      breadcrumb.path = breadcrumb?.path?.replace(`:${key}`, value || '')
    })

    return breadcrumb
  })

  return breadcrumbs
}

const getBreadcrumbKey = (breadcrumb: Breadcrumb) => {
  if (!breadcrumb.path) return breadcrumb.titleInternationalizationKey

  return `${breadcrumb.titleInternationalizationKey}${breadcrumb.path}`
}

const breadcrumbsUtils = {
  getPageBreadcrumbs,
  getBreadcrumbKey,
}

export default breadcrumbsUtils
