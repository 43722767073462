import {
  Accordion, Card, Divider,
} from '@mantine/core'
import InvoicePriceTierItems from './invoice-price-tier-items'
import { InvoiceMetricInFull } from '../../../../../models/invoice-metric'
import InvoiceMetricItem from './invoice-metric-item'

interface Props {
  metric: InvoiceMetricInFull;
  isLastIndex: boolean;
}

export default function InvoiceInFullMetricDetailItem({ metric, isLastIndex }: Props) {
  return (
    <Accordion.Item key={metric.id} value={metric.id}>
      {metric.priceTiers.length > 0 ? (
        <>
          <Accordion.Control
            classNames={{
              chevron: '-mb-4',
            }}
          >
            <InvoiceMetricItem metric={metric} />
          </Accordion.Control>
          <Accordion.Panel>
            <Card shadow="none" className="-mt-5 bg-gray-50">
              <InvoicePriceTierItems metric={metric} />
            </Card>
          </Accordion.Panel>
        </>
      )
        : (
          <div className="px-4">
            <InvoiceMetricItem metric={metric} />
          </div>
        )}
      {!isLastIndex
        && <Divider className="mx-4" />}
    </Accordion.Item>
  )
}
