import { modals } from '@mantine/modals'
import { Trans, useTranslation } from 'react-i18next'
import AlertCard from '../../../../shared/components/alert/alert-card'
import { formatPlanNamesForDeleteErrorAlert } from '../../utils/format-plan-names-for-delete-error-alert'

export const useDeleteResourceErrorAlert = () => {
  const { t } = useTranslation()

  return (resourcePlanNames: string[]) => {
    const planFormattedNames = formatPlanNamesForDeleteErrorAlert(resourcePlanNames)

    modals.open({
      withCloseButton: false,
      children: (
        <AlertCard
          variant="warning"
          title={t('resource:delete-error-alert:title') as string}
          description={(
            <Trans
              i18nKey="resource:delete-error-alert:content"
              values={{
                plans: planFormattedNames,
              }}
            />
          )}
          onDismiss={modals.closeAll}
        />
      ),
    })
  }
}
