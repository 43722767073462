import { create } from 'zustand'

interface CustomersSearchTermState {
  searchTerm: string
  actions: {
    setSearchTerm: (searchTerm: string) => void
  }
}

const useCustomersSearchTermStore = create<CustomersSearchTermState>((set) => ({
  searchTerm: '',
  actions: {
    setSearchTerm: (searchTerm: string) => set({ searchTerm }),
  },
}))

export const useCustomersSearchTerm = () => useCustomersSearchTermStore((state) => state.searchTerm)
export const useCustomersSearchTermActions = () => useCustomersSearchTermStore((state) => state.actions)
