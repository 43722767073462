import { Skeleton, Table } from '@mantine/core'
import { ROW_HEIGHT_SIZE } from '../../utils/constants'

interface Props {
  rowsToFill: number;
  columnsAmount: number;
}

export default function SkeletonRows({ rowsToFill, columnsAmount }: Props) {
  return (
    <>
      {Array(rowsToFill).fill(0).map((_, index) => {
        const rowCounter = index
        return (
          <Table.Tr
            key={rowCounter}
            style={{
              height: `${ROW_HEIGHT_SIZE}px`,
            }}
          >
            <Table.Td colSpan={columnsAmount}>
              <Skeleton height={20} radius="xl" />
            </Table.Td>
          </Table.Tr>
        )
      })}
    </>
  )
}
