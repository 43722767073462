import { useTranslation } from 'react-i18next'
import { Badge } from '@mantine/core'
import { BillingType } from '../enum'

interface Props {
  type: BillingType;
}

export default function PriceTierBillingTypeBadge({ type }: Props) {
  const { t } = useTranslation(['priceTier'])

  switch (type) {
    case BillingType.Flat:
      return (
        <Badge color="red.8" variant="light">
          {t('priceTier:billing-type-flat-title')}
        </Badge>
      )

    case BillingType.Unit:
      return (
        <Badge color="violet.8" variant="light">
          {t('priceTier:billing-type-unit-title')}
        </Badge>
      )

    case BillingType.Package:
    default:
      return (
        <Badge color="green.8" variant="light">
          {t('priceTier:billing-type-package-title')}
        </Badge>
      )
  }
}
