import { useEffect } from 'react'
import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { PartialUpdateSelfUserAttributes } from '../../../interfaces'
import AccountDetailForm from './account-detail-form'
import User from '../../../models/user'
import { AvatarInitials } from '../../../shared/components/user/avatar-initials'
import { usePartialUpdateSelfUser } from '../queries'
import Modal from '../../../shared/components/general/modal'

interface Props {
  opened: boolean;
  onClose: () => void;
  user: User;
}

export default function PartialUpdateSelfUserModal({ opened, onClose, user }: Props) {
  const { t } = useTranslation(['common', 'user'])

  const useFormMethods = useForm<PartialUpdateSelfUserAttributes>({
    mode: 'onTouched',
    defaultValues: user,
  })

  const {
    handleSubmit, reset, formState: { isValid },
  } = useFormMethods

  const handleCloseAndReset = () => {
    onClose()
    reset()
  }

  useEffect(() => {
    reset(user)
  }, [user, reset])

  const { mutate, isLoading } = usePartialUpdateSelfUser({ onSuccess: handleCloseAndReset })

  return (
    <Modal opened={opened} onClose={onClose} title={t('user:account-detail')}>
      <div className="px-5">
        <div className="w-full flex items-center">
          <div className="m-auto my-9">
            <AvatarInitials text={user ? user.name : ''} size="100px" />
          </div>
        </div>

        <FormProvider {...useFormMethods}>
          <form
            onSubmit={handleSubmit((partialUpdateSelfUserAttributes: PartialUpdateSelfUserAttributes) => mutate(partialUpdateSelfUserAttributes))}
            className="flex flex-col"
          >
            <AccountDetailForm />

            <div className="flex flex-row justify-start">
              <Button
                className="mt-7 mr-5"
                color="violet"
                type="submit"
                disabled={!isValid}
                loading={isLoading}
              >
                {t('common:save')}
              </Button>

              <Button
                variant="outline"
                color="violet"
                className="mt-7"
                type="button"
                onClick={handleCloseAndReset}
              >
                {t('common:cancel')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  )
}
