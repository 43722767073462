import TableCellValue from '../../../interfaces/table/table-cell-value'
import { AvatarInitials } from '../user/avatar-initials'

interface Props {
  value: TableCellValue;
}

export function TableAvatarCell({ value }: Props) {
  return (
    <AvatarInitials text={value.toString()} size="1.8rem" />
  )
}
