import { Text, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { urls } from '../../../shared/utils/public'
import LoginForm from './login-form'
import { LoginAttributes } from '../../../interfaces'

interface Props {
  onLoginSubmit: (user: LoginAttributes) => void
}

export default function LoginFormContent({ onLoginSubmit }: Props) {
  const { t } = useTranslation('auth')

  return (
    <div className="flex flex-col">
      <Title className="mb-9" order={1}>{t('login.form.enter')}</Title>
      <LoginForm onLoginSubmit={onLoginSubmit} />
      <Text fz="sm" className="mt-7">
        {t('login.call-action')}
        {' '}
        <a className="text-primary underline" target="_blank" href={urls.airaLandingPage} rel="noreferrer">
          {t('login.call-action-link')}
        </a>
      </Text>
    </div>
  )
}
