import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import AlertCard from '../../../../shared/components/alert/alert-card'
import ExtendedUser from '../../../../models/extended-user'
import { useDeleteUser } from '../../queries'

export const useDeleteUserHandlerModal = () => {
  const { t } = useTranslation()

  const { mutate } = useDeleteUser()

  return (user: ExtendedUser) => {
    modals.open({
      withCloseButton: false,
      children: (
        <AlertCard
          variant="warning"
          title={t('user:delete-alert:title') as string}
          description={t('user:delete-alert:content') as string}
          onCancel={modals.closeAll}
          onContinue={() => {
            mutate(user.id)
            modals.closeAll()
          }}
        />
      ),
    })
  }
}
