import { useTranslation } from 'react-i18next'
import { Badge } from '@mantine/core'
import { ContractStatus } from '../enum'

interface Props {
  status: ContractStatus;
}

export default function ContractCardStatusBadge({ status }: Props) {
  const { t } = useTranslation(['customer'])

  switch (status) {
    case ContractStatus.Completed:
      return (
        <Badge color="gray.5" c="gray.6" variant="outline">
          {t('customer:contract-completed')}
        </Badge>
      )

    case ContractStatus.Canceled:
      return (
        <Badge color="gray.5" c="gray.6" variant="outline">
          {t('customer:contract-canceled')}
        </Badge>
      )

    case ContractStatus.Active:
    default:
      return (
        <Badge color="violet.7" bg="violet.1" variant="outline">
          {t('customer:contract-active')}
        </Badge>
      )
  }
}
