import { useTranslation } from 'react-i18next'
import { BillingType } from '../enum'

const usePriceTierSummaryTitle = () => {
  const { t } = useTranslation(['priceTier'])

  return (billingType: BillingType) => {
    switch (billingType) {
      case BillingType.Flat:
        return t('priceTier:summary:title-flat')
      case BillingType.Unit:
        return t('priceTier:summary:title-unit')
      case BillingType.Package:
        return t('priceTier:summary:title-package')
      default:
        return ''
    }
  }
}

export default usePriceTierSummaryTitle
