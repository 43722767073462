import { Button, Menu, Text } from '@mantine/core'
import { AiOutlinePlus } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import UpsertInvoiceAdditionalItemAttributes from '../../../../../interfaces/invoice/upsert-invoice-additional-item-attributes'
import { BillingAdditionalItemType } from '../../../enum'

interface Props {
  appendAdditionalItem: (value: UpsertInvoiceAdditionalItemAttributes) => void;
}

export default function ManageInvoiceAdditionalItemsModalHeader({ appendAdditionalItem }: Props) {
  const { t } = useTranslation(['common', 'invoice'])

  const handleAppendNewDiscount = () => {
    appendAdditionalItem({
      renderId: uuidv4(),
      name: t('invoice:item-form:new-discount'),
      description: '',
      amount: 0,
      type: BillingAdditionalItemType.Discount,
    })
  }

  const handleAppendNewCharge = () => {
    appendAdditionalItem({
      renderId: uuidv4(),
      name: t('invoice:item-form:new-charge'),
      description: '',
      amount: 0,
      type: BillingAdditionalItemType.AdditionalCharge,
    })
  }

  return (
    <div className="flex justify-between">
      <Text size="lg" fw={700} className="text-black">
        {t('invoice:manage')}
      </Text>

      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Button
            style={{
              justifyContent: 'flex-start',
            }}
            leftSection={<AiOutlinePlus size={22} />}
            variant="subtle"
            color="violet"
            size="md"
          >
            {t('invoice:item-form:add')}
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            onClick={handleAppendNewDiscount}
          >
            {t('invoice:item:label-discount')}
          </Menu.Item>
          <Menu.Item
            onClick={handleAppendNewCharge}
          >
            {t('invoice:item:label-charge')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
