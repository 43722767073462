import React, {
  useState, useRef, useLayoutEffect, ReactElement,
} from 'react'
import { Text, Tooltip } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'

interface Props {
  children: React.ReactNode;
  truncate?: boolean;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  fw?: number;
}

export default function Typography({
  className, size, children, truncate = true, fw,
}: Props): ReactElement {
  const { height, width } = useViewportSize()

  const elementRef = useRef<HTMLDivElement>(null)
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  useLayoutEffect(() => {
    const e = elementRef.current
    if (e == null || !truncate) {
      return
    }
    setShouldShowTooltip(e.scrollWidth > e.clientWidth)
  }, [truncate, children, height, width])

  if (truncate) {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Tooltip
          label={<div className="w-70 break-words whitespace-break-spaces">{children}</div>}
          opened={isHovered && shouldShowTooltip}
          position="bottom"
          w={300}
          multiline
          withArrow
        >
          <Text
            fw={fw}
            ref={elementRef}
            size={size}
            className={`truncate ${className || ''}`}
          >
            {children}
          </Text>
        </Tooltip>
      </div>
    )
  }

  return (
    <Text
      fw={fw}
      size={size}
      ref={elementRef}
      className={className}
    >
      {children}
    </Text>
  )
}
