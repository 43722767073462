import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { TableColumn } from '../../../interfaces'
import { CoreTable, TableDateTimeCell } from '../../../shared/components/table'
import { useEventsQuery } from '../queries'
import tableEventsUtils from '../utils/table-events-utils'
import EventViewModel from '../interfaces/event-view-model'
import EventsSumCard from './events-sum-card'

export default function TableEvents() {
  const { t } = useTranslation(['event', 'common'])

  const {
    data: events,
    isLoading,
    isFetching,
  } = useEventsQuery()

  const eventViewModels: EventViewModel[] = useMemo(() => (events
    ? events.map((event) => ({
      ...event,
      customerName: event.customer.name,
      resourceName: event.resource.name,
    })) : []), [events])

  const tableEventsColumns: TableColumn<EventViewModel>[] = [
    {
      label: t('event:idempotency-key'),
      key: 'idempotencyKey',
      columnKey: 'idempotencyKey',
      widthPercentage: 20,
    },
    {
      label: t('event:customer'),
      key: 'customerName',
      columnKey: 'customerName',
      widthPercentage: 18,
    },
    {
      label: t('event:resource'),
      key: 'resourceName',
      columnKey: 'resourceName',
      widthPercentage: 18,
    },
    {
      label: t('event:value'),
      key: 'value',
      columnKey: 'value',
      widthPercentage: 10,
    },
    {
      label: t('event:occurred-at'),
      key: 'occurredAt',
      columnKey: 'occurredAt',
      widthPercentage: 17,
      Component: TableDateTimeCell,
    },
    {
      label: t('event:created-at'),
      key: 'createdAt',
      columnKey: 'createdAt',
      widthPercentage: 17,
      Component: TableDateTimeCell,
    },
  ]

  return (
    <div>
      <CoreTable<EventViewModel>
        data={eventViewModels || []}
        indexKey={tableEventsUtils.indexKey}
        columns={tableEventsColumns}
        isFetching={isFetching}
        isLoading={isLoading}
      />
      <div className="flex items-center justify-between  pt-3 px-1">
        <Text className="text-gray-900">{t('event:table-warning')}</Text>
        <EventsSumCard />
      </div>
    </div>
  )
}
