import { Accordion } from '@mantine/core'
import { BillingModel } from '../../../../metric/enum'
import InvoiceInFullMetricDetailItem from './invoice-in-full-metric-detail-item'
import InvoiceProrationMetricDetailItem from './invoice-proration-metric-detail-item'
import { InvoiceMetric } from '../../../../../models/invoice-metric'

interface Props {
  metrics: InvoiceMetric[]
}

export default function InvoiceMetricsDetailAccordion({ metrics }: Props) {
  const accordionStyles = {
    item: {
      '&[dataActive]': {
        backgroundColor: 'var(--mantine-color-body)',
      },
    },
  }

  const lastIndex = metrics.length - 1

  return (
    <Accordion variant="filled" styles={accordionStyles} className="-mx-5 -mt-1">
      {metrics.map((metric, index) => (
        <div key={metric.id}>

          {metric.billingModel === BillingModel.Proration ? (
            <InvoiceProrationMetricDetailItem
              metric={metric}
              isLastIndex={index === lastIndex}
            />
          ) : (
            <InvoiceInFullMetricDetailItem
              metric={metric}
              isLastIndex={index === lastIndex}
            />
          )}

        </div>
      ))}
    </Accordion>
  )
}
