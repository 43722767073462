import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import i18n from 'i18next'
import queryClient from '../../../config/query-client'
import { ExtendedPlan, Plan } from '../../../models'
import searchHelper from '../../../shared/utils/helpers/search-helper'
import planService from '../services/plan-service'
import { usePlanSearchTerm } from '../store/plan-search-term-store'
import tablePlansUtils from '../utils/table-plan-utils'
import MutationParams from '../../../interfaces/queries/mutation-params'
import { extendedPlanToUpsertExtendedPlanAttributes } from '../utils/upsert-plan-utils'
import { QueryParams } from '../../../interfaces'
import PlanDetails from '../../../interfaces/plan/plan-details'

const plansKeys = {
  all: [{ scope: 'plans' }] as const,
  lists: () => [{ ...plansKeys.all[0], entity: 'list' }] as const,
  single: (id: string) => [{ ...plansKeys.all[0], entity: 'single', id }] as const,
  searchTermFilteredList: () => [{ ...plansKeys.lists()[0], cluster: 'filtered_by_search_term' }] as const,
}

export const usePlansQuery = <ModelSchema = PlanDetails[]>(params?: QueryParams<PlanDetails[], ModelSchema>) => useQuery({
  queryKey: plansKeys.lists(),
  queryFn: planService.fetchPlans,
  onSuccess: params?.onSuccess,
  staleTime: params?.staleTime,
  enabled: params?.enabled,
  select: params?.select,
})

export const refetchPlansQuery = () => {
  queryClient.refetchQueries(plansKeys.lists())
}

export const useFilteredPlansQuery = () => {
  const searchTerm = usePlanSearchTerm()
  const { partialMatchKeys, fullMatchKeys } = tablePlansUtils

  return usePlansQuery({ select: (queriedPlans) => searchHelper.getFilteredDataBySearchTerm<PlanDetails>(queriedPlans, partialMatchKeys, fullMatchKeys, searchTerm) })
}

export const useCreatePlan = (params?: MutationParams<ExtendedPlan>) => useMutation({
  mutationFn: planService.createPlan,
  onSuccess: (newPlan) => {
    queryClient.setQueryData<ExtendedPlan[]>(plansKeys.lists(), (plans) => {
      if (!plans) return [newPlan]

      return [newPlan, ...plans]
    })

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(newPlan)
  },
})

export const usePlanQuery = (id?: string) => useQuery({
  queryKey: plansKeys.single(id!),
  queryFn: () => planService.fetchPlan({ id: id! }),
  enabled: !!id,
  onSuccess: (plan) => {
    const upsertedPlan = extendedPlanToUpsertExtendedPlanAttributes(plan)
    return upsertedPlan
  },
})

export const useUpdatePlan = (params?: MutationParams<ExtendedPlan>) => useMutation({
  mutationFn: planService.updatePlan,
  onSuccess: (updatedPlan) => {
    queryClient.setQueryData<ExtendedPlan[]>(plansKeys.lists(), (plans) => {
      if (!plans) return []

      return plans.map((plan) => (plan.id === updatedPlan.id ? updatedPlan : plan))
    })

    queryClient.setQueryData<ExtendedPlan>(plansKeys.single(updatedPlan.id), updatedPlan)

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(updatedPlan)
  },
})

export const useDeletePlan = (params?: MutationParams<void>) => useMutation({
  mutationFn: (id: string) => planService.deletePlan({ id }),
  onSuccess: (_data, id) => {
    queryClient.setQueryData<Plan[]>(plansKeys.lists(), (plans) => {
      if (!plans) return []

      return plans.filter((plan) => (plan.id !== id))
    })
    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess()
  },
  onError: params?.onError,
})

export const useRateAdjustPlan = (params?: MutationParams<ExtendedPlan>) => useMutation({
  mutationFn: planService.rateAdjustPlan,
  onSuccess: (adjustedPlan) => {
    queryClient.setQueryData<ExtendedPlan[]>(plansKeys.lists(), (plans) => {
      if (!plans) return []

      return plans.map((plan) => (plan.id === adjustedPlan.id ? adjustedPlan : plan))
    })

    queryClient.setQueryData<ExtendedPlan>(plansKeys.single(adjustedPlan.id), adjustedPlan)

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(adjustedPlan)
  },
})

export default plansKeys
