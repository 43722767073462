import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'

interface Props {
  isValid: boolean
  isLoading: boolean;
  handleCancel: () => void;
}

export default function PlanSummaryButtons({
  isValid, isLoading, handleCancel,
}: Props) {
  const { t } = useTranslation(['common', 'plan'])

  return (
    <div className="flex flex-row gap-3">
      <Button
        form="plan-form"
        variant="filled"
        color="violet"
        className="mt-7"
        type="submit"
        disabled={!isValid}
        loading={isLoading}
      >
        {t('common:save')}
      </Button>
      <Button
        variant="outline"
        color="violet"
        className="mt-7"
        onClick={handleCancel}
      >
        {t('common:cancel')}
      </Button>
    </div>
  )
}
