import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { UpsertUserAttributes } from '../../../interfaces'
import { useUpdateUser } from '../queries'
import Modal from '../../../shared/components/general/modal'
import ExtendedUser from '../../../models/extended-user'
import UserForm from './user-form'

interface Props {
  opened: boolean;
  onClose: () => void;
  user: ExtendedUser;
}

export default function UpdateUserModal({ opened, onClose, user }: Props) {
  const { t } = useTranslation(['common', 'user'])

  const useFormMethods = useForm<UpsertUserAttributes>({
    mode: 'onTouched',
    defaultValues: {
      id: user.id,
      name: user.name,
      email: user.email,
      customerId: user.customer && user.customer.id,
      group: user.groups[0],
    },
  })

  const {
    handleSubmit, reset, formState: { isValid },
  } = useFormMethods

  const handleClose = () => {
    reset()
    onClose()
  }

  const { mutate, isLoading } = useUpdateUser({ onSuccess: handleClose })

  return (
    <Modal opened={opened} onClose={handleClose} title={t('resource:edit-resource')}>
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit((updateResourceAttributes: UpsertUserAttributes) => mutate(updateResourceAttributes))}
          className="flex flex-col"
        >

          <UserForm isUpdate />

          <Button
            className="mt-7 w-50"
            color="violet"
            type="submit"
            disabled={!isValid}
            loading={isLoading}
          >
            {t('common:edit')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  )
}
