import { forwardRef, useState } from 'react'
import { Button, TextInput, TextInputProps } from '@mantine/core'
import { BiLockAlt, BiLockOpenAlt } from 'react-icons/bi'

interface Props extends TextInputProps {
  descriptionText?: string;
  keepDescriptionText?: boolean;
}

const SafeTextInput = forwardRef<HTMLInputElement, Props>(
  ({ descriptionText, keepDescriptionText, ...props }, ref) => {
    const [isLocked, setIsLocked] = useState(true)

    return (
      <div className="flex flex-row w-full items-center">
        <div className="flex-grow">
          <TextInput
            ref={ref}
            {...props}
            disabled={isLocked}
            description={isLocked && !keepDescriptionText ? '' : descriptionText}
            inputWrapperOrder={['label', 'input', 'description', 'error']}
          />
        </div>

        <div className="my-auto mt-6 ml-1">
          <Button
            variant="outline"
            color="violet"
            className="w-5 min-w-0"
            onClick={() => setIsLocked(!isLocked)}
          >
            {isLocked ? <BiLockAlt size={20} /> : <BiLockOpenAlt size={20} />}
          </Button>
        </div>
      </div>
    )
  },
)

export default SafeTextInput
