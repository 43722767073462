export enum PaymentGateway {
  Celcoin = 'celcoin',
}

export enum OmieModules {
  ManuallySyncAiraInvoicesToOmie = 'manually_sync_aira_invoice_to_omie',
  AutomaticallySyncOmiePaymentsToAira = 'automatically_sync_omie_payments_to_aira',
  AutomaticallySyncFiscalInvoice = 'automatically_sync_fiscal_invoice',
}

interface Tenant {
  id: string;
  externalServices: {
    omie: {
      enabled: boolean,
      modules: OmieModules[] | null,
    },
    paymentGateway: {
      enabled: boolean,
      gateway: PaymentGateway | null,
    }
  },
}

export default Tenant
