import { create } from 'zustand'

interface UserSearchTermState {
  searchTerm: string
  actions: {
    setSearchTerm: (searchTerm: string) => void
  }
}

const useUserSearchTermStore = create<UserSearchTermState>((set) => ({
  searchTerm: '',
  actions: {
    setSearchTerm: (searchTerm: string) => set({ searchTerm }),
  },
}))

export const useUserSearchTerm = () => useUserSearchTermStore((state) => state.searchTerm)
export const useUserSearchTermActions = () => useUserSearchTermStore((state) => state.actions)
