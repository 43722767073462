import { Accordion, Badge, Text } from '@mantine/core'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import UpsertInvoiceAdditionalItemAttributes from '../../../../../interfaces/invoice/upsert-invoice-additional-item-attributes'
import { BillingAdditionalItemType } from '../../../enum'
import ManageInvoiceItemForm from './manage-invoice-additional-item-form'
import UpsertInvoiceAdditionalItems from '../../../../../interfaces/invoice/upsert-invoice-additional-items'
import Typography from '../../../../../shared/components/general/typography'
import formatToCurrency from '../../../../../shared/utils/helpers/format-to-currency'

interface Props {
  item: UpsertInvoiceAdditionalItemAttributes;
  index: number;
  remove: (index: number) => void;
}

export default function ManageInvoiceAdditionalItemContent({ item, index, remove }: Props) {
  const { t, i18n } = useTranslation(['common', 'invoice'])

  const { language } = i18n

  const {
    watch,
  } = useFormContext<UpsertInvoiceAdditionalItems>()

  const accordionStyles = {
    item: {
      '&[dataActive]': {
        backgroundColor: 'var(--mantine-color-body)',
      },
    },
  }

  const itemName = watch(`additionalItems.${index}.name`)
  const itemAmount = watch(`additionalItems.${index}.amount`)

  return (
    <Accordion variant="contained" radius="md" defaultValue="customization" styles={accordionStyles}>
      <Accordion.Item value={item.id || item.renderId}>

        <Accordion.Control>
          <div className="flex justify-between items-center">
            <div className="flex w-full gap-3">
              <Badge color="violet" variant="filled">
                {item.type === BillingAdditionalItemType.AdditionalCharge
                  ? <Text>{t('invoice:item-form:charge')}</Text> : <Text>{t('invoice:item-form:discount')}</Text>}
              </Badge>
              <div className="w-95">
                <Typography size="sm">{`${formatToCurrency({ price: itemAmount, language })} - ${itemName}` }</Typography>
              </div>
            </div>
          </div>
        </Accordion.Control>

        <Accordion.Panel>
          <ManageInvoiceItemForm index={index} remove={remove} />
        </Accordion.Panel>

      </Accordion.Item>
    </Accordion>
  )
}
