import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { loader } from './loader'

export function FooterLoading() {
  const { t } = useTranslation('common')

  return (
    <div className="flex h-full">
      <div className="my-auto">
        {loader}
      </div>
      <Text size="sm" className="text-gray-600 my-auto ml-2">{t('searching')}</Text>
    </div>
  )
}
