import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useCurrentPage from '../../../shared/hooks/use-current-page'
import sideBarMenuUtils from '../utils/side-bar-menu-utils'

export default function SidebarMenu() {
  const { t } = useTranslation('common')
  const { sideBarMenuPages } = sideBarMenuUtils
  const { currentPage } = useCurrentPage()

  return (
    <div className="w-full">
      {sideBarMenuPages.map((page) => {
        const isCurrentPage = page.path === currentPage?.path
        const Icon = page.sideMenuOption?.icon

        return (
          <Button
            style={{
              justifyContent: 'flex-start',
            }}
            component={Link}
            to={page.path}
            leftSection={Icon && <Icon size={15} />}
            variant={isCurrentPage ? 'light' : 'subtle'}
            color={isCurrentPage ? 'violet' : 'gray'}
            className="mb-5 w-full"
            size="sm"
            key={page.path}
          >
            {t(page.sideMenuOption?.titleInternationalizationKey as string)}
          </Button>
        )
      })}
    </div>
  )
}
