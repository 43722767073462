import { Badge, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { BillingType } from '../../../../price-tier/enum'

interface Props {
  billingType: BillingType;
  from: number;
  to: number | null;
  packageSize: number | null;
}

export default function InvoicePriceTierItemHeader({
  from, to, billingType, packageSize = null,
}: Props) {
  const { t } = useTranslation(['invoice', 'metric'])

  const isLastTier = to === null

  return (
    <div className="flex gap-2 text-gray-700">
      <Badge className="normal-case" color="violet" variant="filled">
        {isLastTier ? (
          <Text>{`${t('metric:summary:from')} ${from}`}</Text>
        ) : (
          <Text>{`${from} ${t('metric:summary:until')} ${to}`}</Text>
        )}
      </Badge>
      <Text size="sm" fw={600}>
        {packageSize ? (`${t(`invoice:billing-type-${billingType}`)} ${packageSize} ${t('invoice:units')}`)
          : t(`invoice:billing-type-${billingType}`)}
      </Text>
    </div>
  )
}
