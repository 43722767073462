import { TextInput } from '@mantine/core'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import IMask from 'imask'
import { cnpj } from 'cpf-cnpj-validator'
import { emailValidationRegex } from '../../../shared/utils/regex'

export default function PaymentAccountGeneralInformationForm() {
  const { t } = useTranslation(['customer', 'common'])
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { ref, ...field } = register('taxId', {
    required: { value: true, message: t('common:form-error:required-field') },
    validate: {
      validEmail: (taxId) => cnpj.isValid(taxId) || t('common:form-error:invalid-cnpj') as string,
    },
  })

  const taxIdMaskConfig = {
    mask: '00.000.000/0000-00',
  }

  return (
    <div className="flex flex-col w-full gap-2">
      <TextInput
        required
        label={t('customer:tax-id') as string}
        placeholder={t('customer:tax-id') as string}
        error={errors.taxId && errors.taxId.message as string}
        {...field}
        ref={(el) => {
          ref(el)
          if (!el) return
          IMask(el, taxIdMaskConfig)
        }}
      />

      <TextInput
        required
        label={t('customer:business-name') as string}
        placeholder={t('customer:business-name') as string}
        error={errors.businessName && errors.businessName.message as string}
        {...register('businessName', {
          required: { value: true, message: t('common:form-error:required-field') },
        })}
      />

      <TextInput
        label={t('customer:trade-name') as string}
        placeholder={t('customer:trade-name') as string}
        error={errors.tradeName && errors.tradeName.message as string}
        {...register('tradeName')}
      />

      <TextInput
        label={t('user:email') as string}
        placeholder={t('user:email') as string}
        error={errors.email && errors.email.message as string}
        {...register('email', {
          validate: {
            validEmail: (email) => email === ''
              || email === null
              || emailValidationRegex.test(email || '')
              || t('common:form-error:invalid-email') as string,
          },
        })}
      />
    </div>
  )
}
