import { Card, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import InvoicePlan from '../../../../../models/invoice-plan'
import InvoiceMetricsDetailAccordion from './invoice-metrics-detail-accordion'
import formatToCurrency from '../../../../../shared/utils/helpers/format-to-currency'

interface Props {
  plan: InvoicePlan
}

export default function InvoicePlanDetailCard({ plan }: Props) {
  const { t, i18n } = useTranslation(['invoice', 'common'])
  const { language } = i18n
  return (
    <Card padding="lg" radius="md" className="flex justify-between items-center border border-gray-border-default">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-row w-full justify-between">
          <div>
            <Text size="sm" fw={400} className="text-grayscale-text-subtitle">
              {plan.product?.name}
            </Text>
            <Text size="md" fw={600} className="text-grayscale-text-title">
              {plan.name}
            </Text>

          </div>
          <div className="flex flex-col items-end">
            <Text size="sm" fw={400} className="text-grayscale-text-subtitle">
              {t('invoice:detail:plan_subtotal')}
            </Text>
            <Text size="md" fw={600} className="text-primary-text-label flex w-fit">
              {formatToCurrency({ price: plan.totalAmount, language })}
            </Text>
          </div>
        </div>
        <hr className="border-t border-grayscale-border-default" />

        <div className="flex justify-between">
          <Text size="sm">{t('common:fixed-amount')}</Text>
          <Text size="sm">{formatToCurrency({ price: plan.planSettings.fixedAmount, language })}</Text>
        </div>

        {plan.metrics.length > 0 && (
          <div className="flex justify-between">
            <Text size="md" fw={500} className="text-grayscale-text-body">
              {t('invoice:detail:resource')}
            </Text>
            <Text size="mf" fw={500} className="text-grayscale-text-body">
              {t('invoice:detail:total')}
            </Text>
          </div>
        )}

        <InvoiceMetricsDetailAccordion metrics={plan.metrics} />

      </div>
    </Card>
  )
}
