import axiosInstance from '../config/axios'
import CreateUserRequest from '../requests/users/create-user-request'
import DeleteUserRequest from '../requests/users/delete-user-request'
import PartialUpdateSelfUserRequest from '../requests/users/partial-update-self-user-request'
import UpdateUserRequest from '../requests/users/update-user-request'
import CreateUserResponse from '../responses/users/create-user-response'
import GetUsersResponse from '../responses/users/get-users-response'
import PartialUpdateSelfUserResponse from '../responses/users/partial-update-self-user-response'
import UpdateUserResponse from '../responses/users/update-user-response'

const USERS_BASE_URL = '/users'

const getUsers = async () => (axiosInstance.get<GetUsersResponse>(USERS_BASE_URL))

const createUser = async (request: CreateUserRequest) => (axiosInstance.post<CreateUserResponse>(USERS_BASE_URL, request))

// eslint-disable-next-line arrow-body-style
const partialUpdateSelfUser = async (request: PartialUpdateSelfUserRequest) => {
  return axiosInstance.patch<PartialUpdateSelfUserResponse>(`${USERS_BASE_URL}/${request.id}`, request)
}

const updateUser = async (request: UpdateUserRequest) => (axiosInstance.put<UpdateUserResponse>(`${USERS_BASE_URL}/${request.id}`, request))

const deleteUser = async (request: DeleteUserRequest) => (axiosInstance.delete(`${USERS_BASE_URL}/${request.id}`))

export default {
  getUsers,
  createUser,
  partialUpdateSelfUser,
  updateUser,
  deleteUser,
}
