import { Table } from '@mantine/core'
import { ROW_HEIGHT_SIZE } from '../../utils/constants'

interface Props {
  rowsToFill: number;
  columnsAmount: number;
}

function EmptyRows({ rowsToFill, columnsAmount }: Props) {
  return (
    <>
      {Array(rowsToFill).fill(0).map((_, index) => {
        const rowCounter = index
        return (
          <Table.Tr
            key={rowCounter}
            style={{
              height: `${ROW_HEIGHT_SIZE}px`,
            }}
          >
            <Table.Td colSpan={columnsAmount} />
          </Table.Tr>
        )
      })}
    </>
  )
}

export default EmptyRows
