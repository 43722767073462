enum UserCodes {
  EmailBelongsToAnotherUser = 'email_belongs_to_another_user',
  ExternalIdBelongsToAnotherResource = 'external_id_belongs_to_another_resource',
  ExternalIdBelongsToAnotherCustomer = 'external_id_belongs_to_another_customer',
  TaxIdBelongsToAnotherPaymentAccount = 'tax_id_belongs_to_another_payment_account',
  FailedToPerformExternalProcessorContractSync = 'failed_to_perform_external_processor_contract_sync',
  DuplicateContractProcessorId = 'duplicate_contract_processor_id',
}

export default UserCodes
