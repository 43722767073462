import { Breadcrumbs as MantineBreadcrumbs, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import breadcrumbsUtils from '../../utils/breadcrumbs-utils'
import useCurrentPage from '../../../../shared/hooks/use-current-page'

export default function Breadcrumb() {
  const { t } = useTranslation('common')
  const { currentPage, currentPageParams } = useCurrentPage()
  const breadcrumbs = currentPage ? breadcrumbsUtils.getPageBreadcrumbs(currentPage, currentPageParams) : null

  return (
    <div>
      <MantineBreadcrumbs>
        {breadcrumbs?.map((breadcrumb) => (
          <div key={breadcrumbsUtils.getBreadcrumbKey(breadcrumb)}>
            {breadcrumb.path
              ? (
                <Text component={Link} size="sm" to={breadcrumb.path} className="text-gray-500">
                  {t(breadcrumb.titleInternationalizationKey)}
                </Text>
              )
              : (
                <Text size="sm">
                  {t(breadcrumb.titleInternationalizationKey)}
                </Text>
              )}
          </div>
        ))}
      </MantineBreadcrumbs>
    </div>
  )
}
