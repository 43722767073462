import { useTranslation } from 'react-i18next'
import { useInvoiceSearchTerm, useInvoiceSearchTermActions } from '../../store/invoice-search-term-store'
import SearchInput from '../../../../shared/components/form/search-input'
import InvoiceFilterPopover from './invoice-filter-popover'

export default function InvoicePageActions() {
  const { t } = useTranslation('invoice')

  const { setSearchTerm } = useInvoiceSearchTermActions()
  const searchTerm = useInvoiceSearchTerm()

  return (
    <div className="flex flex-row justify-between gap-4">
      <InvoiceFilterPopover />
      <SearchInput
        fullWidth
        placeholder={t('invoice:search-placeholder') as string}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </div>
  )
}
