import Typography from '../general/typography'

interface Props {
  value: any;
}

export function TableCustomerCell({ value }: Props) {
  return (
    <Typography>
      {value?.name}
    </Typography>
  )
}
