import Typography from '../general/typography'

interface Props {
  value: number | string;
}

export function TableDefaultCell({ value }: Props) {
  return (
    <Typography className="2xl:max-w-100 xl:max-w-75 lg:max-w-55">
      {value}
    </Typography>
  )
}
