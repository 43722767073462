type AnyObject = { [key: string]: any }

function checkDeepEquality(obj1: AnyObject | null | undefined, obj2: AnyObject | null | undefined): boolean {
  // Both are null or undefined
  if (obj1 === obj2) {
    return true
  }

  // One of them is null or undefined
  if (!obj1 || !obj2) {
    return false
  }

  // Both should be objects to be considered deeply equal
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // They should have the same number of keys
  if (keys1.length !== keys2.length) {
    return false
  }

  // Test for A's keys different from B.
  return keys1.every((key) => keys2.includes(key) && checkDeepEquality(obj1[key], obj2[key]))
}

export default checkDeepEquality
