import { useState } from 'react'
import TableMenuCell from '../../../shared/components/table/table-menu-cell'
import useDeleteResourceHandler from './modal-actions/delete-resource-modal'
import { Resource } from '../../../models'
import UpdateResourceModal from './update-resource-modal'

interface Props {
  rowData: Resource;
}

export function TableResourceMenuCell({ rowData }: Props) {
  const deleteResourceHandler = useDeleteResourceHandler()

  const [isUpdateResourceModalOpen, setIsUpdateResourceModalOpen] = useState(false)
  const [selectedResource, setSelectedResource] = useState<Resource | null>(null)

  const onUpdateResourceHandler = (resource: Resource) => {
    setSelectedResource(resource)
    setIsUpdateResourceModalOpen(true)
  }

  const handleUpdateResourceModalClose = () => {
    setIsUpdateResourceModalOpen(false)
    setSelectedResource(null)
  }

  return (
    <div className="cursor-pointer flex justify-end">
      {selectedResource && (
      <UpdateResourceModal
        opened={isUpdateResourceModalOpen}
        onClose={handleUpdateResourceModalClose}
        resource={selectedResource}
      />
      )}
      <TableMenuCell
        name={rowData.name}
        onDelete={() => deleteResourceHandler(rowData)}
        onEdit={() => onUpdateResourceHandler(rowData)}
      />
    </div>
  )
}
