import { Text } from '@mantine/core'
import { images } from '../../utils/public'

interface Props {
  text: string;
}

export default function EmptyContentMessage({ text }: Props) {
  return (
    <div className="flex flex-col w-full items-center">
      <div className="w-35 h-35 flex items-center">
        <img
          src={images.emptyBox.src}
          alt={images.emptyBox.alt}
          className="w-35"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          onLoad={(e) => {
            e.currentTarget.style.opacity = '1'
          }}
        />
      </div>
      <Text size="lg" fw={700} className="text-black mt-4">
        {text}
      </Text>
    </div>
  )
}
