import CustomersPageActions from '../features/customer/components/customers-page-actions'
import TableCustomer from '../features/customer/components/table-customer'

export default function Customers() {
  return (
    <div className="h-full w-full flex flex-col">
      <CustomersPageActions />
      <TableCustomer />
    </div>
  )
}
