import { MatchKey } from '../../../interfaces/queries/match-key'
import { InvoiceExternalProcessor } from '../../../models/invoice-external-processor'

const partialMatchKeys : MatchKey<InvoiceExternalProcessor>[] = ['displayId', 'invoiceId']
const fullMatchKeys : MatchKey<InvoiceExternalProcessor>[] = []
const indexKey = 'displayId' as keyof InvoiceExternalProcessor

const tableInvoiceExternalProcessorUtils = {
  partialMatchKeys,
  fullMatchKeys,
  indexKey,
}

export default tableInvoiceExternalProcessorUtils
