import { Text } from '@mantine/core'
import { Trans, useTranslation } from 'react-i18next'
import Tooltip from '../../../../../shared/components/form/tooltip-input'
import { BillingModel, PriceTierDivision } from '../../../../metric/enum'
import { InvoiceMetric } from '../../../../../models/invoice-metric'

interface Props {
  metric: InvoiceMetric;
}

export default function BillingModelPriceTierDivisionInfo({ metric }: Props) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-1">
      <Text size="xs" c="gray.6">
        {t('invoice:billing-model-and-price-tier-division', {
          billingModel: metric.billingModel === BillingModel.InFull ? t('metric:billing-model:in-full-charge')
            : t('metric:billing-model:proration-charge'),
          priceTierDivision: metric.priceTierDivision === PriceTierDivision.Progressive ? t('invoice:division-progressive')
            : t('priceTier:division-unique-tier'),
        })}
      </Text>
      <Tooltip
        color="dimmed"
        size="xs"
        text={(
          <>
            <Text>
              {metric.billingModel === BillingModel.InFull
                ? <Trans i18nKey="metric:billing-model:in-full-tooltip" />
                : <Trans i18nKey="metric:billing-model:proration-tooltip" />}
            </Text>
            <Text>
              {metric.priceTierDivision === PriceTierDivision.Progressive
                ? <Trans i18nKey="priceTier:division-progressive-tooltip" />
                : <Trans i18nKey="priceTier:division-unique-tier-tooltip" />}
            </Text>
          </>
        )}
      />
    </div>
  )
}
