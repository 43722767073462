import ReactGA from 'react-ga4'

export const initializeGA4 = () => {
  ReactGA.initialize(import.meta.env.VITE_GA_ID)
}

export enum GA4EventsHitType {
  PAGE_VIEW = 'page_view',
}

interface SendPageViewParams {
  pathname: string
  tenantId: string
  userEmail: string
}

export const sendPageView = ({
  pathname,
  tenantId,
  userEmail,
}: SendPageViewParams) => {
  ReactGA.send({
    hitType: GA4EventsHitType.PAGE_VIEW,
    page: pathname,
    tenantId,
    userEmail,
  })
}
