import { create } from 'zustand'

interface ResourceSearchTermState {
  searchTerm: string
  actions: {
    setSearchTerm: (searchTerm: string) => void
  }
}

const useResourceSearchTermStore = create<ResourceSearchTermState>((set) => ({
  searchTerm: '',
  actions: {
    setSearchTerm: (searchTerm: string) => set({ searchTerm }),
  },
}))

export const useResourceSearchTerm = () => useResourceSearchTermStore((state) => state.searchTerm)
export const useResourceSearchTermActions = () => useResourceSearchTermStore((state) => state.actions)
