import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import searchHelper from '../../shared/utils/helpers/search-helper'
import { useInvoiceSearchTerm } from './store/invoice-search-term-store'
import tableInvoiceUtils from './utils/table-invoice-utils'
import invoicesService from './services/invoice-service'
import { useInvoiceFilter } from './store/invoice-filter-store'
import { getFilteredInvoices } from './utils/invoice-filter-helper'
import { ExtendedInvoice, Invoice } from '../../models'
import { QueryParams } from '../../interfaces'
import MutationParams from '../../interfaces/queries/mutation-params'
import queryClient from '../../config/query-client'
import i18n from '../../config/i18n'
import UpsertInvoiceAdditionalItemAttributes from '../../interfaces/invoice/upsert-invoice-additional-item-attributes'
import { InvoiceExternalProcessor } from '../../models/invoice-external-processor'
import { useInvoiceExternalProcessorSearchTerm } from './store/invoice-external-processor-search-term-store'
import tableInvoiceExternalProcessorUtils from './utils/table-invoice-external-processor-utils'
import { useInvoiceExternalProcessorFilter } from './store/invoice-external-processor-filter-store'
import { getFilteredinvoiceExternalProcessors } from './utils/invoice-external-processor-sync-filter-helper'
import { ExternalProcessorSyncBillingCycleStatus } from './enum'

const invoiceKeys = {
  all: [{ scope: 'invoices' }] as const,
  lists: () => [{ ...invoiceKeys.all[0], entity: 'list' }] as const,
  single: (id: string) => [{ ...invoiceKeys.all[0], entity: 'single', id }] as const,
}

const invoiceExternalProcessorKeys = {
  all: [{ scope: 'invoiceExternalProcessor' }] as const,
  lists: () => [{ ...invoiceExternalProcessorKeys.all[0], entity: 'list' }] as const,
  single: (id: string) => [{ ...invoiceExternalProcessorKeys.all[0], entity: 'single', id }] as const,
}

export const useInvoicesQuery = <ModelSchema = Invoice[]>(params?: QueryParams<Invoice[], ModelSchema>) => useQuery({
  queryKey: invoiceKeys.lists(),
  queryFn: invoicesService.fetchInvoices,
  select: params?.select,
})

export const useInvoiceExternalProcessorsQuery = <ModelSchema = InvoiceExternalProcessor[]>(params?: QueryParams<InvoiceExternalProcessor[], ModelSchema>) => useQuery({
  queryKey: invoiceExternalProcessorKeys.lists(),
  queryFn: invoicesService.fetchInvoiceExternalProcessors,
  select: params?.select,
})

export const useFilteredInvoicesQuery = () => {
  const searchTerm = useInvoiceSearchTerm()
  const { partialMatchKeys, fullMatchKeys } = tableInvoiceUtils

  const filter = useInvoiceFilter()
  return useInvoicesQuery({
    select: (queriedInvoices) => getFilteredInvoices(searchHelper.getFilteredDataBySearchTerm(queriedInvoices, partialMatchKeys, fullMatchKeys, searchTerm), filter),
  })
}

export const useFilteredInvoiceExternalProcessorsQuery = () => {
  const searchTerm = useInvoiceExternalProcessorSearchTerm()
  const { partialMatchKeys, fullMatchKeys } = tableInvoiceExternalProcessorUtils

  const filter = useInvoiceExternalProcessorFilter()
  return useInvoiceExternalProcessorsQuery(({
    select: (queriedInvoiceExternalProcessors) => getFilteredinvoiceExternalProcessors(
      searchHelper.getFilteredDataBySearchTerm(queriedInvoiceExternalProcessors, partialMatchKeys, fullMatchKeys, searchTerm),
      filter,
    ),
  }))
}

export const useInvoiceQuery = (id?: string) => useQuery({
  queryKey: invoiceKeys.single(id!),
  queryFn: () => invoicesService.fetchInvoice({ id: id! }),
  enabled: !!id,
})

export const useDownloadInvoiceDocument = () => useMutation({
  mutationFn: invoicesService.downloadInvoiceDocument,
})

export const useUpdateInvoiceAdditionalItems = (params?: MutationParams<ExtendedInvoice>) => useMutation(
  (data: { invoiceId: string; additionalItems: UpsertInvoiceAdditionalItemAttributes[], currentInvoiceAdditionalItems: UpsertInvoiceAdditionalItemAttributes[] }) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    invoicesService.updateInvoiceAdditionalItems(data.invoiceId, data.additionalItems, data.currentInvoiceAdditionalItems),
  {
    onSuccess: (updatedInvoice: ExtendedInvoice) => {
      queryClient.setQueryData<ExtendedInvoice>(
        invoiceKeys.single(updatedInvoice.id),
        updatedInvoice,
      )
      toast.success(i18n.t('messages:success.default'))
      params?.onSuccess?.(updatedInvoice)
    },
  },
)

export const useSynchronizeInvoices = () => useMutation({
  mutationFn: invoicesService.synchronizeInvoices,
  onSuccess: (_response, ids) => {
    if (ids.length === 1) {
      const existingInvoice = queryClient.getQueryData<ExtendedInvoice>(invoiceKeys.single(ids[0]))
      if (existingInvoice) {
        queryClient.setQueryData<ExtendedInvoice>(
          invoiceKeys.single(ids[0]),
          {
            ...existingInvoice,
            externalProcessorSyncStatus: ExternalProcessorSyncBillingCycleStatus.Pending,
            isLocked: true,
          },
        )
        queryClient.refetchQueries(invoiceKeys.single(ids[0]))
      }
    }
    queryClient.setQueryData<ExtendedInvoice[]>(
      invoiceKeys.lists(),
      (invoices) => (invoices ? invoices.map((invoice) => (ids.includes(invoice.id)
        ? {
          ...invoice,
          externalProcessorSyncStatus: ExternalProcessorSyncBillingCycleStatus.Pending,
          isLocked: true,
        } : invoice)) : []),
    )
    queryClient.refetchQueries(invoiceKeys.lists())
  },
})

export const useCalculateInvoice = (params?: MutationParams<ExtendedInvoice>) => useMutation({
  mutationFn: invoicesService.calculateInvoice,
  onSuccess: (updatedInvoice: ExtendedInvoice) => {
    queryClient.setQueryData<ExtendedInvoice>(
      invoiceKeys.single(updatedInvoice.id),
      updatedInvoice,
    )
    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(updatedInvoice)
  },
})

export const useDownloadFiscalInvoiceFile = () => useMutation({
  mutationFn: invoicesService.downloadFiscalInvoiceFile,
})

export default {
  invoiceKeys,
  useFilteredInvoicesQuery,
  useDownloadInvoiceDocument,
  useUpdateInvoiceAdditionalItems,
  useSynchronizeInvoices,
  useCalculateInvoice,
  useDownloadFiscalInvoiceFile,
}
