import { UserSession } from '../../../interfaces'
import { User } from '../../../models'
import { USER_SESSION_KEY } from '../../../shared/utils/constants'

const setUserSession = (userSession: UserSession) => {
  localStorage.setItem(USER_SESSION_KEY, JSON.stringify(userSession))
}

const getUserSession = (): UserSession | null => {
  const localStorageUserSession = localStorage.getItem(USER_SESSION_KEY)

  if (!localStorageUserSession) return null

  const userSession = JSON.parse(localStorageUserSession) as UserSession

  return userSession
}

const removeUserSession = () => {
  localStorage.removeItem(USER_SESSION_KEY)
}

const updateUserSessionUser = (user: User) => {
  const localStorageUserSession = localStorage.getItem(USER_SESSION_KEY)

  if (!localStorageUserSession) return

  const userSession = JSON.parse(localStorageUserSession) as UserSession

  const updatedUserSession = {
    accessToken: userSession.accessToken,
    tenantId: userSession.tenantId,
    tenant: userSession.tenant,
    user,
  }

  localStorage.setItem(USER_SESSION_KEY, JSON.stringify(updatedUserSession))
}

const userSessionLocalStorageUtils = {
  setUserSession,
  getUserSession,
  removeUserSession,
  updateUserSessionUser,
}

export default userSessionLocalStorageUtils
