import { useFormContext } from 'react-hook-form'
import { TextInput } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { MAX_TEXT_FIELD_LENGTH } from '../../../shared/utils/constants'
import Tooltip from '../../../shared/components/form/tooltip-input'
import { UpsertCustomerAttributes } from '../../../interfaces'

export default function CreateCustomerForm() {
  const { t } = useTranslation(['customer', 'common'])

  const {
    register, formState: { errors },
  } = useFormContext<UpsertCustomerAttributes>()

  return (
    <div className="w-full flex flex-col gap-4">
      <TextInput
        label={t('customer:name') as string}
        placeholder={t('customer:name') as string}
        error={errors.name && errors.name.message}
        required
        {...register('name', {
          required: { value: true, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />

      <TextInput
        required
        label={t('customer:external-id') as string}
        placeholder={t('customer:external-id') as string}
        error={errors.externalId && errors.externalId.message}
        rightSection={<Tooltip text={t('customer:form:external-id-description') as string} />}
        {...register('externalId', {
          required: { value: true, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />
    </div>
  )
}
