import { create } from 'zustand'
import { Tenant, User } from '../../../models'
import userSessionLocalStorageUtils from '../utils/user-session-local-storage-utils'
import { UserSession } from '../../../interfaces'

interface AuthState {
  user: User
  tenant: Tenant
  isUserAuthenticated: boolean
  actions: {
    setUser: (user: User) => void
    loginUser: (userSession: UserSession) => void
  }
}

const getDefaultUser = () => {
  const userSession = userSessionLocalStorageUtils.getUserSession()

  if (!userSession) {
    return {
      id: '',
      name: '',
      email: '',
      groups: [],
    }
  }

  return userSession.user
}

const getDefaultTenant = () => {
  const userSession = userSessionLocalStorageUtils.getUserSession()

  if (!userSession) {
    return {
      id: '',
      externalServices: {
        omie: {
          enabled: false,
          modules: null,
        },
        paymentGateway: {
          enabled: false,
          gateway: null,
        },
      },
    }
  }

  return userSession.tenant
}

const getDefaultIsUserAuthenticated = () => {
  const userSession = userSessionLocalStorageUtils.getUserSession()

  return userSession !== null
}

const useAuthStore = create<AuthState>((set) => ({
  user: getDefaultUser(),
  tenant: getDefaultTenant(),
  isUserAuthenticated: getDefaultIsUserAuthenticated(),
  actions: {
    setUser: (user: User) => {
      set({ user, isUserAuthenticated: true })
      userSessionLocalStorageUtils.updateUserSessionUser(user)
    },
    loginUser: (userSession: UserSession) => {
      set({
        user: userSession.user,
        tenant: userSession.tenant,
        isUserAuthenticated: true,
      })
      userSessionLocalStorageUtils.setUserSession(userSession)
    },
  },
}))

export const useAuthUser = () => useAuthStore((state) => state.user)
export const useAuthTenant = () => useAuthStore((state) => state.tenant)
export const useAuthIsUserAuthenticated = () => useAuthStore((state) => state.isUserAuthenticated)
export const useAuthActions = () => useAuthStore((state) => state.actions)
