import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { modals } from '@mantine/modals'
import Modal from '../../../shared/components/general/modal'
import PaymentAccountForm from './payment-account-form'
import UpsertPaymentAccountAttributes from '../../../interfaces/payment-account/payment-account-attributes'
import { UseCreatePaymentAccount } from '../queries'
import { useIncompleteAddressAlertModal } from './modal-actions/incomplete-address-alert-modal'
import getEmptyKeys from '../../../shared/utils/helpers/get-invalid-keys'
import PaymentAccountAddressAttributes from '../../../interfaces/payment-account/payment-account-address-attributes'

interface Props {
  opened: boolean;
  onClose: () => void;
}

export default function CreatePaymentAccountModal({ opened, onClose }: Props) {
  const { t } = useTranslation('customer')
  const { id: customerId } = useParams()

  const useFormMethods = useForm<UpsertPaymentAccountAttributes>({
    mode: 'onTouched',
  })

  const {
    handleSubmit, reset, formState: { isValid }, setValue, getValues,
  } = useFormMethods

  const handleCreatePaymentAccountSuccess = () => {
    onClose()
    reset()
  }

  const { mutate, isLoading } = UseCreatePaymentAccount({ onSuccess: handleCreatePaymentAccountSuccess })

  const incompleteAddressModal = useIncompleteAddressAlertModal({
    onCancel() {
      modals.closeAll()
      setValue('address', null)
      handleCreatePaymentAccount(getValues())
    },
  })

  const handleCreatePaymentAccount = (
    upsertPaymentAccountAttributes: UpsertPaymentAccountAttributes,
  ) => {
    const { address } = upsertPaymentAccountAttributes

    if (address) {
      const incompleteAddressAttributes = getEmptyKeys<PaymentAccountAddressAttributes>(address, ['complement'])
      const isAddressEmpty = incompleteAddressAttributes.length === 7

      if (isAddressEmpty) {
        mutate({ ...upsertPaymentAccountAttributes, address: null, customerId: customerId! })
        return
      }

      if (incompleteAddressAttributes.length > 0) {
        incompleteAddressModal()
        return
      }
    }

    mutate({ ...upsertPaymentAccountAttributes, customerId: customerId! })
  }

  return (
    <Modal size="xl" opened={opened} onClose={onClose} title={t('customer:payment-account:add-payment-account')}>
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit(handleCreatePaymentAccount)}
          className="flex flex-col px-2"
        >

          <PaymentAccountForm />

          <Button
            className="mt-7 w-50"
            color="violet"
            type="submit"
            disabled={!isValid}
            loading={isLoading}
          >
            {t('common:save')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  )
}
