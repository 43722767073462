import BillingCycelExternalProcessorUserCodes from '../../../libs/api/enums/billing-cycle-external-processor-user-codes'

const billingCycleExternalProcessorErrorMessages = {
  [BillingCycelExternalProcessorUserCodes.MultipleMatchedOmieContractsForAiraContract]: 'messages:billing-cycle-external-processor:multiple-matched-omie-contracts-for-aira-contract',
  [BillingCycelExternalProcessorUserCodes.MultipleOmieContractsWithTheSameContractNumber]: 'messages:billing-cycle-external-processor:multiple-omie-contracts-with-the-same-contract-number',
  [BillingCycelExternalProcessorUserCodes.NoOmieContractMatchedForProducts]: 'messages:billing-cycle-external-processor:no-omie-contract-matched-for-products',
  [BillingCycelExternalProcessorUserCodes.NoOmieContractMatchedForRangeDates]: 'messages:billing-cycle-external-processor:no-omie-contract-matched-for-range-dates',
  [BillingCycelExternalProcessorUserCodes.CustomerContractsInOmieAreNotActive]: 'messages:billing-cycle-external-processor:customer-contracts-in-omie-are-not-active',
  [BillingCycelExternalProcessorUserCodes.ContractInOmieHasRepeatedServices]: 'messages:billing-cycle-external-processor:contract-in-omie-has-repeated-services',
  [BillingCycelExternalProcessorUserCodes.PaymentAccountMissingTaxId]: 'messages:billing-cycle-external-processor:payment-account-missing-tax-id',
  [BillingCycelExternalProcessorUserCodes.CustomerNotFoundInOmie]: 'messages:billing-cycle-external-processor:customer-not-found-in-omie',
  [BillingCycelExternalProcessorUserCodes.NoActiveContractFoundInOmieForContractNumber]: 'messages:billing-cycle-external-processor:no-active-contract-found-in-omie-for-contract-number',
  [BillingCycelExternalProcessorUserCodes.NoActiveContractFoundForCustomerInOmie]: 'messages:billing-cycle-external-processor:no-active-contract-found-for-customer-in-omie',
  default: 'messages:billing-cycle-external-processor:unexpected',
}

export default billingCycleExternalProcessorErrorMessages
