import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import ResourceForm from './resource-form'
import { UpdateResourceAttributes } from '../../../interfaces'
import Resource from '../../../models/resource'
import { useUpdateResource } from '../queries'
import Modal from '../../../shared/components/general/modal'

interface Props {
  opened: boolean;
  onClose: () => void;
  resource: Resource;
}

export default function UpdateResourceModal({ opened, onClose, resource }: Props) {
  const { t } = useTranslation(['common', 'resource'])

  const useFormMethods = useForm<UpdateResourceAttributes>({
    mode: 'onTouched',
    defaultValues: resource,
  })

  const {
    handleSubmit, formState: { isValid },
  } = useFormMethods

  const { mutate, isLoading } = useUpdateResource({ onSuccess: onClose })

  return (
    <Modal opened={opened} onClose={onClose} title={t('resource:edit-resource')}>
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit((updateResourceAttributes: UpdateResourceAttributes) => mutate(updateResourceAttributes))}
          className="flex flex-col"
        >

          <ResourceForm isUpdate />
          <Button
            className="mt-7 w-50"
            color="violet"
            type="submit"
            disabled={!isValid}
            loading={isLoading}
          >
            {t('common:edit')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  )
}
