import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { modals } from '@mantine/modals'
import Modal from '../../../shared/components/general/modal'
import PaymentAccountForm from './payment-account-form'
import UpsertPaymentAccountAttributes from '../../../interfaces/payment-account/payment-account-attributes'
import { UseUpdatePaymentAccount } from '../queries'
import { PaymentAccount } from '../../../models'
import { formatTaxIdForDisplay, formatZipCodeForDisplay } from '../utils/formatters'
import getEmptyKeys from '../../../shared/utils/helpers/get-invalid-keys'
import PaymentAccountAddressAttributes from '../../../interfaces/payment-account/payment-account-address-attributes'
import { useIncompleteAddressAlertModal } from './modal-actions/incomplete-address-alert-modal'

interface Props {
  opened: boolean;
  onClose: () => void;
  paymentAccount: PaymentAccount
}

export default function UpdatePaymentAccountModal({ opened, onClose, paymentAccount }: Props) {
  const { t } = useTranslation('customer')
  const { id: customerId } = useParams()

  const useFormMethods = useForm<UpsertPaymentAccountAttributes>({
    mode: 'onTouched',
    defaultValues: {
      ...paymentAccount,
      taxId: formatTaxIdForDisplay(paymentAccount.taxId),
      address: paymentAccount.address ? {
        ...paymentAccount.address,
        zipCode: formatZipCodeForDisplay(paymentAccount.address.zipCode),
        complement: paymentAccount.address.complement || undefined,
      } : null,
    },
  })

  const {
    handleSubmit, reset, formState: { isDirty, isValid }, setValue, getValues,
  } = useFormMethods

  const handleUpdatePaymentAccountSuccess = () => {
    onClose()
    reset()
  }

  const { mutate, isLoading } = UseUpdatePaymentAccount({ onSuccess: handleUpdatePaymentAccountSuccess })

  useEffect(() => {
    if (paymentAccount && !isDirty) {
      reset({
        ...paymentAccount,
        taxId: formatTaxIdForDisplay(paymentAccount.taxId),
        address: paymentAccount.address ? {
          ...paymentAccount.address,
          zipCode: formatZipCodeForDisplay(paymentAccount.address.zipCode),
          complement: paymentAccount.address.complement || undefined,
        } : null,
      })
    }
  }, [paymentAccount, reset, isDirty])

  const incompleteAddressModal = useIncompleteAddressAlertModal({
    onCancel() {
      modals.closeAll()
      setValue('address', null)
      handleUpdatePaymentAccount(getValues())
    },
  })

  const handleUpdatePaymentAccount = (
    upsertPaymentAccountAttributes: UpsertPaymentAccountAttributes,
  ) => {
    const { address } = upsertPaymentAccountAttributes

    if (address) {
      const incompleteAddressAttributes = getEmptyKeys<PaymentAccountAddressAttributes>(address, ['complement'])
      const isAddressEmpty = incompleteAddressAttributes.length === 7

      if (isAddressEmpty) {
        mutate({
          ...upsertPaymentAccountAttributes, address: null, id: paymentAccount.id, customerId: customerId!,
        })
        return
      }

      if (incompleteAddressAttributes.length > 0) {
        incompleteAddressModal()
        return
      }
    }

    mutate({ ...upsertPaymentAccountAttributes, id: paymentAccount.id, customerId: customerId! })
  }

  return (
    <Modal
      size="xl"
      opened={opened}
      onClose={onClose}
      title={t('customer:payment-account:edit-payment-account')}
    >
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit(handleUpdatePaymentAccount)}
          className="flex flex-col px-2"
        >

          <PaymentAccountForm />

          <Button
            className="mt-7 w-50"
            color="violet"
            type="submit"
            disabled={!isValid || !isDirty}
            loading={isLoading}
          >
            {t('common:save')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  )
}
