import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { registerClarityEvent } from './config/clarity'
import { USER_SESSION_KEY } from './shared/utils/constants'
import { sendPageView } from './config/ga4'

function RouteListener() {
  const location = useLocation()

  useEffect(() => {
    const localStorageUserSession = localStorage.getItem(USER_SESSION_KEY)

    if (localStorageUserSession) {
      const userSession = JSON.parse(localStorageUserSession)

      const userId = userSession.user.id || null
      const tenantId = userSession.tenantId || null
      const userEmail = userSession.user.email || null

      if (userId && tenantId && userEmail) {
        registerClarityEvent(userId, location.pathname, tenantId)
        sendPageView({
          pathname: location.pathname,
          tenantId,
          userEmail,
        })
      }
    }
  }, [location])

  return null
}

export default RouteListener
