import ProductAttributes from '../../../interfaces/product/product-attributes'
import { api } from '../../../libs'
import { Product } from '../../../models'

const fetchProducts = async (): Promise<Product[]> => {
  const getProductsResponse = await api.products.getProducts()
  return getProductsResponse.data
}

const createProduct = async (createProductAttributes: ProductAttributes): Promise<Product> => {
  const createPlanResponse = await api.products.createProduct(createProductAttributes)
  return createPlanResponse.data
}

const productsService = {
  fetchProducts,
  createProduct,
}

export default productsService
