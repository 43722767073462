import { Avatar } from '@mantine/core'
import { getInitials } from '../../utils/helpers/avatar-helper'

interface Props {
  text: string;
  size?: string;
  color?: string;
}

export function AvatarInitials({ text, size, color }: Props) {
  return (
    <Avatar size={size || 'lg'} color={color || 'violet'} radius="xl">
      {getInitials(text)}
    </Avatar>
  )
}
