import {
  Checkbox, Button, Menu, ActionIcon,
} from '@mantine/core'
import { TbRefresh } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import { HiDotsVertical } from 'react-icons/hi'
import { VscServerProcess } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../enums'

interface SelectionSummaryProps {
  selectedCount: number
  itemName: string
  onSynchronize: () => void
}

export function SelectTableSummary({
  selectedCount,
  itemName,
  onSynchronize,
}: SelectionSummaryProps) {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <div className="flex items-center justify-between mb-2 h-8">
      <div className="flex gap-5 mb-1">
        <div>
          {selectedCount > 0
          && (
          <div className="flex gap-2 items-center">
            <Checkbox checked={selectedCount > 0} readOnly />
            <p>
              {t('common:selected-itens', {
                numberSelected: selectedCount,
                itemName,
              })}
            </p>
          </div>
          )}
        </div>
        {selectedCount > 0 && (
          <Button
            size="xs"
            radius="lg"
            bg="violet.0"
            c="violet.9"
            leftSection={<TbRefresh size={18} />}
            onClick={() => {
              onSynchronize()
            }}
          >
            {t('invoice:synchronize')}
          </Button>
        )}
      </div>
      <Menu
        shadow="md"
        position="bottom"
        withArrow
        arrowOffset={35}
        offset={-5}
        width={200}
      >
        <Menu.Target>
          <ActionIcon variant="transparent">
            <HiDotsVertical color="#343A40" size={18} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={() => navigate(Path.InvoiceExternalProcessors)}>
            <div className="flex gap-2 items-center">
              <VscServerProcess size={17} />
              {t('invoice:processes')}
            </div>
          </Menu.Item>
        </Menu.Dropdown>

      </Menu>
    </div>
  )
}
