import { Skeleton } from '@mantine/core'

export default function ViewPlanModalSkeleton() {
  return (
    <div className="flex flex-col items-flex-start">
      <Skeleton height={24} width="70%" radius="xl" />
      <Skeleton height={24} mt={6} width="70%" radius="xl" />
      <Skeleton height={16} mt={6} width="100%" radius="xl" />
      <Skeleton height={16} mt={6} width="100%" radius="xl" />
      <Skeleton height={16} mt={6} width="100%" radius="xl" />
    </div>
  )
}
