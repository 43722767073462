import {
  Accordion, Button, Card, Divider,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useProrationUsageViewModal } from '../../modal-actions/proration-usage-view-modal'
import { InvoiceMetricProration } from '../../../../../models/invoice-metric'
import InvoicePriceTierItems from './invoice-price-tier-items'
import InvoiceMetricItem from './invoice-metric-item'

interface Props {
  metric: InvoiceMetricProration;
  isLastIndex: boolean;
}

export default function InvoiceProrationMetricDetailItem({ metric, isLastIndex }: Props) {
  const { t } = useTranslation()

  const prorationUsageViewModal = useProrationUsageViewModal()

  return (
    <Accordion.Item key={metric.id} value={metric.id}>
      <Accordion.Control classNames={{
        chevron: '-mb-4',
      }}
      >
        <InvoiceMetricItem metric={metric} />
      </Accordion.Control>
      <Accordion.Panel>
        <Card shadow="none" className="-mt-5 bg-gray-50">
          <Button className="mb-5 w-70" variant="outline" onClick={() => prorationUsageViewModal(metric)}>{t('invoice:proration-usage-view-detail')}</Button>
          {metric.priceTiers.length > 0 && <InvoicePriceTierItems metric={metric} />}
        </Card>
      </Accordion.Panel>
      { !isLastIndex
          && <Divider className="mx-4" />}
    </Accordion.Item>
  )
}
