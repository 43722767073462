import { MatchKey } from '../../../interfaces/queries/match-key'
import { Customer } from '../../../models'

const partialMatchKeys : MatchKey<Customer>[] = ['name', 'externalId']
const fullMatchKeys : MatchKey<Customer>[] = []
const indexKey = 'id' as keyof Customer

const tableCustomersUtils = {
  partialMatchKeys,
  fullMatchKeys,
  indexKey,
}

export default tableCustomersUtils
