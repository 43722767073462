import { Card } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/en'
import 'dayjs/locale/pt'
import { InvoiceStatusBadge } from '../general-component/invoice-status-badge'
import formatToCurrency from '../../../../shared/utils/helpers/format-to-currency'
import InfoItem from '../../../../shared/components/display/info-item'
import { ExtendedInvoice } from '../../../../models'

dayjs.extend(utc)

interface Props {
  invoice: ExtendedInvoice
}

export default function InvoiceDetailCard({ invoice }: Props) {
  const { t } = useTranslation(['invoice'])
  const { i18n } = useTranslation()
  const { language } = i18n

  dayjs.locale(language)

  const formatDate = (date: Date): string => dayjs(date).utc().format('D MMM YYYY')

  return (
    <Card padding="sm" radius="md" className="border border-gray-border-default">
      <InvoiceStatusBadge size="md" status={invoice.status} externalProcessorSyncStatus={invoice.externalProcessorSyncStatus} />
      <InfoItem
        title={t('invoice:total-value')}
        value={formatToCurrency({ price: invoice.totalAmount, language })}
        size="xl"
        className="mb-3 mt-2"
      />
      <InfoItem
        title={t('invoice:end-date')}
        value={formatDate(new Date(invoice.endDate))}
      />
    </Card>
  )
}
