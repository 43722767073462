import { useAuthTenant } from '../../../auth/store/auth-store'
import CoreTableInvoices from './variants/core-table-invoices'
import OmieTableInvoices from './variants/omie-table-invoices'
import PaymentTableInvoices from './variants/payment-table-invoices'
import { OmieModules } from '../../../../models/tenant'

export default function TableInvoices() {
  const tenant = useAuthTenant()

  const tenantFeatures = {
    omie_automatic_sync: tenant.externalServices.omie.modules?.includes(OmieModules.AutomaticallySyncOmiePaymentsToAira),
    omie_manual_sync: tenant.externalServices.omie.modules?.includes(OmieModules.ManuallySyncAiraInvoicesToOmie),
    payment: tenant.externalServices.paymentGateway.enabled,
  }

  if (tenantFeatures.omie_manual_sync) {
    return <OmieTableInvoices />
  }

  if (tenantFeatures.payment || tenantFeatures.omie_automatic_sync) {
    return <PaymentTableInvoices />
  }

  return <CoreTableInvoices />
}
