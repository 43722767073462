import { Path } from '../../../../../shared/enums'

const getDefaultPath = (isUserAuthenticated: boolean): Path => {
  if (isUserAuthenticated) return Path.Invoices

  return Path.Login
}

const notFoundPageUtils = {
  getDefaultPath,
}

export default notFoundPageUtils
