import { ExtendedInvoice } from '../../../../models'
import { PaymentStatusBadge } from '../../../payment/components/payment-status-badge'
import { getInvoicePayment } from '../../../payment/utils/get-invoice-payment'

interface Props {
  rowData: ExtendedInvoice;
}

export function TableInvoicePaymentStatusCell({ rowData }: Props) {
  const latestPayment = getInvoicePayment(rowData.payments)

  const paymentStatus = latestPayment ? latestPayment.status : null
  const paymentDate = latestPayment ? latestPayment.dueDate : null

  return <PaymentStatusBadge status={paymentStatus} paymentDueDate={paymentDate} />
}
