import axiosInstance from '../config/axios'
import GetEventsRequest from '../requests/events/get-events-request'
import GetEventsSumRequest from '../requests/events/get-events-sum-request'
import GetEventsResponse from '../responses/events/get-events-response'
import GetEventsSumResponse from '../responses/events/get-events-sum-response'

const EVENTS_BASE_URL = '/events'

// eslint-disable-next-line arrow-body-style
const getEvents = async (getEventsRequest: GetEventsRequest) => {
  return axiosInstance.get<GetEventsResponse>(EVENTS_BASE_URL, { params: getEventsRequest })
}

// eslint-disable-next-line arrow-body-style
const getEventsSum = async (getEventsSumRequest: GetEventsSumRequest) => {
  return axiosInstance.get<GetEventsSumResponse>(`${EVENTS_BASE_URL}/sum`, { params: getEventsSumRequest })
}

export default {
  getEvents,
  getEventsSum,
}
