import auth from './controllers/auth'
import resources from './controllers/resources'
import users from './controllers/users'
import plans from './controllers/plans'
import customers from './controllers/customers'
import invoices from './controllers/invoices'
import events from './controllers/events'
import paymentAccounts from './controllers/payment-accounts'
import contracts from './controllers/contracts'
import products from './controllers/products'
import payments from './controllers/payments'

const api = {
  auth,
  users,
  resources,
  plans,
  customers,
  invoices,
  events,
  paymentAccounts,
  contracts,
  products,
  payments,
}

export default api
