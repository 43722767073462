import { ExtendedInvoice } from '../../../../models'
import { InvoiceStatusBadge } from '../general-component/invoice-status-badge'

interface Props {
  rowData: ExtendedInvoice;
}

export function TableInvoiceStatusCell({ rowData }: Props) {
  return <InvoiceStatusBadge status={rowData.status} externalProcessorSyncStatus={rowData.externalProcessorSyncStatus} />
}
