import { useTranslation } from 'react-i18next'
import { Title, Text } from '@mantine/core'
import { images } from '../../../../shared/utils/public'

export default function MobilePage() {
  const { t } = useTranslation(['common'])

  return (
    <div className="flex flex-col bg-violet-50 justify-center items-center h-screen">
      <img
        className="w-3/12 mb-15"
        src={images.airaLogoIcon.src}
        alt={images.airaLogoIcon.alt}
      />

      <Title order={3} className="w-10/12 mb-5" ta="center">
        {t('common:mobile-error:title')}
      </Title>

      <Text className="text-gray-700 w-7/12" ta="center">
        {t('common:mobile-error:description')}
      </Text>
    </div>
  )
}
