import { ReactElement } from 'react'
import PageLayoutBasic from './page-layout-basic'
import PageLayoutV1 from './v1/page-layout-v1'
import useCurrentPage from '../../../shared/hooks/use-current-page'
import MobilePage from './exception-pages/mobile-page'
import useIsMobile from '../../../shared/hooks/use-is-mobile'
import LayoutVersion from '../../../shared/enums/layout-version'
import PageLayoutV2 from './v2/page-layout-v2'

interface Props {
  children: ReactElement;
}

export default function PageWrapper({ children }: Props) {
  const { currentPage } = useCurrentPage()
  const isMobile = useIsMobile()

  if (isMobile) return <MobilePage />

  switch (currentPage?.version) {
    case LayoutVersion.BASIC:
      return (
        <PageLayoutBasic>
          {children}
        </PageLayoutBasic>
      )

    case LayoutVersion.V2:
      return (
        <PageLayoutV2>
          {children}
        </PageLayoutV2>
      )

    case LayoutVersion.V1:
    default:
      return (
        <PageLayoutV1>
          {children}
        </PageLayoutV1>
      )
  }
}
