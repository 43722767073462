import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import { Plan, Resource } from '../../../../models'
import DeleteCard from '../../../../shared/components/alert/delete-card'
import { useDeleteResource } from '../../queries'
import { useDeleteResourceErrorAlert } from './delete-resource-error-alert'

const useDeleteResourceHandler = () => {
  const { t } = useTranslation()

  const { mutate } = useDeleteResource()

  const deleteResourceErrorAlert = useDeleteResourceErrorAlert()

  return (resource: Resource) => {
    modals.open({
      withCloseButton: false,
      children: (
        <DeleteCard
          title={t('resource:delete-alert:title')}
          subtitle={t('resource:delete-alert:subtitle')}
          deletedItemName={t('resource:delete-alert:target-resource', {
            resource: resource.name,
          })}
          conditions={[t('resource:delete-alert:conditions')]}
          consequences={[
            { variant: 'danger', text: t('resource:delete-alert:consequences:delete-danger') },
            { variant: 'secure', text: t('resource:delete-alert:consequences:invoices-check') },
          ]}
          onDelete={() => {
            mutate(resource.id, {
              onSuccess: () => modals.closeAll(),
              onError: (error: any) => {
                if (error.response && error.response.status === 409) {
                  modals.closeAll()
                  const planNames : string[] = error.response.data.details.map((plan: Plan) => plan.name)
                  deleteResourceErrorAlert(planNames)
                }
              },
            })
          }}
          onCancel={() => modals.closeAll()}
        />
      ),
    })
  }
}

export default useDeleteResourceHandler
