import { useMutation } from 'react-query'
import PaymentsService from './services/payments-service'

export const useDowloadPaymentFile = () => useMutation({
  mutationFn: PaymentsService.downloadPaymentFile,
})

export default {
  useDowloadPaymentFile,
}
