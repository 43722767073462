import { NumberInput, Select, TextInput } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import IMask from 'imask/holder'
import { UpsertPaymentAccountAttributes } from '../../../interfaces'
import { zipCodeValidationRegex } from '../../../shared/utils/regex'
import { useAuthTenant } from '../../auth/store/auth-store'
import { State } from '../enum/state'
import { Country } from '../enum/country'
import InfoBox from '../../../shared/components/alert/info-box'

export default function PaymentAccountAddressForm() {
  const { t } = useTranslation(['customer', 'payment-account'])
  const tenant = useAuthTenant()

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<UpsertPaymentAccountAttributes>()

  const isAddressRequired = tenant.externalServices.paymentGateway.enabled

  const { ref, ...zipCodeField } = register('address.zipCode', {
    ...(isAddressRequired && {
      required: { value: true, message: t('common:form-error:required-field') },
    }),
    validate: {
      validZipCode: (zipCode) => (zipCode ? zipCodeValidationRegex.test(zipCode) || t('common:form-error:invalid-zip-code') as string : true),
    },
  })

  const stateOptions = Object.values(State)
  const countryOptions = Object.values(Country)

  const zipCodeMaskConfig = {
    mask: '00000-000',
  }

  return (
    <div className="flex flex-col w-full gap-2">
      <TextInput
        required={isAddressRequired}
        label={t('customer:payment-account:address:zipCode')}
        placeholder={t('customer:payment-account:address:zipCode') as string}
        error={errors.address?.zipCode && errors.address?.zipCode.message as string}
        {...zipCodeField}
        ref={(el) => {
          ref(el)
          if (!el) return
          IMask(el, zipCodeMaskConfig)
        }}
      />
      <TextInput
        required={isAddressRequired}
        label={t('customer:payment-account:address:street')}
        placeholder={t('customer:payment-account:address:street') as string}
        error={errors.address?.street && errors.address?.street.message as string}
        {...register('address.street', {
          ...(isAddressRequired && { required: { value: true, message: t('common:form-error:required-field') } }),
        })}
      />
      <div className="flex gap-2 w-full">
        <Controller
          name="address.number"
          rules={{ required: isAddressRequired }}
          control={control}
          render={({ field }) => (
            <NumberInput
              className="w-full"
              required={isAddressRequired}
              label={t('customer:payment-account:address:number')}
              placeholder={t('customer:payment-account:address:number') as string}
              allowDecimal={false}
              hideControls
              {...field}
            />
          )}
        />
        <TextInput
          className="w-full"
          required={isAddressRequired}
          label={t('customer:payment-account:address:neighborhood')}
          placeholder={t('customer:payment-account:address:neighborhood') as string}
          error={errors.address?.neighborhood && errors.address?.neighborhood.message as string}
          {...register('address.neighborhood', {
            ...(isAddressRequired && { required: { value: true, message: t('common:form-error:required-field') } }),
          })}
        />
      </div>
      <div className="flex gap-2 w-full">
        <TextInput
          className="w-full"
          required={isAddressRequired}
          label={t('customer:payment-account:address:city')}
          placeholder={t('customer:payment-account:address:city') as string}
          error={errors.address?.city && errors.address?.city.message as string}
          {...register('address.city', {
            ...(isAddressRequired && { required: { value: true, message: t('common:form-error:required-field') } }),
          })}
        />
        <Controller
          name="address.state"
          control={control}
          rules={{ required: isAddressRequired }}
          render={({ field }) => (
            <Select
              {...field}
              className="w-full"
              allowDeselect
              label={t('customer:payment-account:address:state')}
              placeholder={t('customer:payment-account:address:state') as string}
              data={stateOptions}
              error={errors.address?.state && errors.address?.state.message as string}
              required={isAddressRequired}
              searchable
            />
          )}
        />
        <Controller
          name="address.country"
          control={control}
          rules={{ required: isAddressRequired }}
          render={({ field }) => (
            <Select
              {...field}
              className="w-full"
              allowDeselect
              label={t('customer:payment-account:address:country')}
              placeholder={t('customer:payment-account:address:country') as string}
              data={countryOptions}
              error={errors.address?.country && errors.address?.country.message as string}
              required={isAddressRequired}
              searchable
            />
          )}
        />
      </div>

      <TextInput
        label={t('customer:payment-account:address:complement')}
        placeholder={t('customer:payment-account:address:complement') as string}
        error={errors.address?.complement && errors.address?.complement.message as string}
        {...register('address.complement')}
      />

      {!isAddressRequired && (
        <div className="mt-2">
          <InfoBox
            title={t('customer:payment-account:not-required-address:info-title')}
            description={t('customer:payment-account:not-required-address:info-content')}
          />
        </div>
      )}
    </div>
  )
}
