import axiosInstance from '../config/axios'
import CreateCustomerRequest from '../requests/customers/create-customer-request'
import DeleteCustomerRequest from '../requests/customers/delete-customer-request'
import GetCustomerRequest from '../requests/customers/get-customer-request'
import UpdateCustomerRequest from '../requests/customers/update-customer-request'
import CreateCustomerResponse from '../responses/customers/create-customer-response'
import GetCustomerResponse from '../responses/customers/get-customer-response'
import GetCustomersResponse from '../responses/customers/get-customers-response'
import UpdateCustomerResponse from '../responses/customers/update-customer-response'

const CUSTOMERS_BASE_URL = '/customers'

const getCustomer = async (request: GetCustomerRequest) => (axiosInstance.get<GetCustomerResponse>(`${CUSTOMERS_BASE_URL}/${request.id}`))

const getCustomers = async () => (axiosInstance.get<GetCustomersResponse>(CUSTOMERS_BASE_URL))

const createCustomer = async (request: CreateCustomerRequest) => (axiosInstance.post<CreateCustomerResponse>(CUSTOMERS_BASE_URL, request))

const updateCustomer = async (request: UpdateCustomerRequest) => (axiosInstance.put<UpdateCustomerResponse>(`${CUSTOMERS_BASE_URL}/${request.id}`, request))

const deleteCustomer = async (request: DeleteCustomerRequest) => (axiosInstance.delete(`${CUSTOMERS_BASE_URL}/${request.id}`))

export default {
  getCustomer,
  getCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
}
