import { Button, Menu } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { FaChevronDown } from 'react-icons/fa'
import { BiDownload, BiMoney } from 'react-icons/bi'
import { MdOutlineCalculate, MdOutlineDiscount } from 'react-icons/md'
import { TbRefresh } from 'react-icons/tb'
import { useMemo } from 'react'
import { IoDocumentOutline, IoDocumentTextOutline } from 'react-icons/io5'
import { useDowloadPaymentFile } from '../../../payment/queries'
import { useDownloadFiscalInvoiceFile, useDownloadInvoiceDocument } from '../../queries'
import { ExtendedInvoice } from '../../../../models'
import useCalculateInvoiceModal from '../modal-actions/calculate-invoice-modal'
import { BillingCycleStatus, ExternalProcessorSyncBillingCycleStatus, FiscalInvoiceFile } from '../../enum'
import { useAuthTenant } from '../../../auth/store/auth-store'
import { OmieModules } from '../../../../models/tenant'
import useInvoiceSynchronizeModal from '../modal-actions/invoice-synchronize-modal'
import { getInvoicePayment } from '../../../payment/utils/get-invoice-payment'
import { PaymentStatus } from '../../../payment/enum/payment-status'
import PaymentMethod from '../../../payment/enum/payment-methods'
import PaymentFile from '../../../payment/enum/payment-file'
import Payment from '../../../../models/payment'

interface Props {
  invoice: ExtendedInvoice;
  onManageItems: () => void;
}

function InvoiceDetailsHeaderMenu({ invoice, onManageItems }: Props) {
  const { t } = useTranslation()
  const tenant = useAuthTenant()

  const calculateInvoiceModal = useCalculateInvoiceModal()
  const synchronizeModal = useInvoiceSynchronizeModal()

  const { mutate, isLoading: isDownloadingInvoiceDocument } = useDownloadInvoiceDocument()
  const { mutate: mutateDownloadPaymentFile, isLoading: isDownloadingPaymentFile } = useDowloadPaymentFile()
  const { mutate: mutateDownloadFiscalInvoiceFile, isLoading: isDownloadingFiscalInvoiceFile } = useDownloadFiscalInvoiceFile()

  const latestPayment = useMemo(() => getInvoicePayment(invoice.payments), [invoice.payments])

  const isSyncInvoiceAvailable = () => (
    tenant.externalServices.omie.modules?.includes(OmieModules.ManuallySyncAiraInvoicesToOmie)
    && invoice.status === BillingCycleStatus.Closed && !invoice.isLocked
  && [ExternalProcessorSyncBillingCycleStatus.Failed, ExternalProcessorSyncBillingCycleStatus.NotInitiated].includes(invoice.externalProcessorSyncStatus))

  const isPaymentFileAvailable = (payment: Payment) => (
    (tenant.externalServices.paymentGateway.enabled || tenant.externalServices.omie.modules?.includes(OmieModules.AutomaticallySyncOmiePaymentsToAira))
    && ![PaymentStatus.Failed, PaymentStatus.Canceled, PaymentStatus.Expired].includes(payment.status)
  )

  return (
    <Menu>
      <Menu.Target>
        <Button
          leftSection={<FaChevronDown />}
          size="sm"
          variant="outline"
          className="w-fit"
          loading={isDownloadingPaymentFile || isDownloadingInvoiceDocument || isDownloadingFiscalInvoiceFile}
        >
          {t('invoice:quick-actions')}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          onClick={() => mutate(invoice.id)}
          leftSection={<BiDownload size={18} />}
        >
          {t('invoice:download')}
        </Menu.Item>
        {
            !invoice.isLocked && (
              <Menu.Item
                onClick={onManageItems}
                leftSection={<MdOutlineDiscount size={18} />}
              >
                {t('invoice:manage')}
              </Menu.Item>
            )
          }
        {
            !invoice.isLocked && (
              <Menu.Item
                onClick={() => calculateInvoiceModal(invoice.id)}
                leftSection={<MdOutlineCalculate size={18} />}
              >
                {t('invoice:calculate')}
              </Menu.Item>
            )
          }
        {
            isSyncInvoiceAvailable() && (
              <Menu.Item
                onClick={() => synchronizeModal([invoice.id])}
                leftSection={<TbRefresh size={18} />}
              >
                {t('invoice:synchronize')}
              </Menu.Item>
            )
          }
        {
            latestPayment && isPaymentFileAvailable(latestPayment) && [PaymentMethod.Bolepix, PaymentMethod.BankSlip].includes(latestPayment.paymentMethod) && (
              <Menu.Item
                onClick={() => mutateDownloadPaymentFile({ id: latestPayment.id, paymentFile: PaymentFile.BankSlip })}
                leftSection={<BiMoney size={18} />}
              >
                {t('invoice:download-bank-slip-file')}
              </Menu.Item>
            )
          }
        {
            latestPayment && isPaymentFileAvailable(latestPayment) && latestPayment.paymentMethod === PaymentMethod.Pix && (
              <Menu.Item
                onClick={() => mutateDownloadPaymentFile({ id: latestPayment.id, paymentFile: PaymentFile.Pix })}
                leftSection={<BiMoney size={18} />}
              >
                {t('invoice:download-pix-file')}
              </Menu.Item>
            )
          }
        {
            tenant.externalServices.omie.modules?.includes(
              OmieModules.AutomaticallySyncFiscalInvoice,
            ) && invoice.fiscalInvoice?.hasFiscalInvoicePdf && (
              <Menu.Item
                onClick={() => mutateDownloadFiscalInvoiceFile({ id: invoice.id, fiscalInvoiceFile: FiscalInvoiceFile.Pdf })}
                leftSection={<IoDocumentTextOutline size={18} />}
              >
                {t('invoice:download-fiscal-invoice-pdf-file')}
              </Menu.Item>
            )
          }
        {
            tenant.externalServices.omie.modules?.includes(
              OmieModules.AutomaticallySyncFiscalInvoice,
            ) && invoice.fiscalInvoice?.hasFiscalInvoiceXml && (
              <Menu.Item
                onClick={() => mutateDownloadFiscalInvoiceFile({ id: invoice.id, fiscalInvoiceFile: FiscalInvoiceFile.Xml })}
                leftSection={<IoDocumentOutline size={18} />}
              >
                {t('invoice:download-fiscal-invoice-xml-file')}
              </Menu.Item>
            )
          }
      </Menu.Dropdown>
    </Menu>
  )
}

export default InvoiceDetailsHeaderMenu
