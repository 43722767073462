import { useTranslation } from 'react-i18next'
import SearchInput from '../../../shared/components/form/search-input'
import EventFilterPopover from './event-filter-popover'
import { useEventSearchTerm, useEventSearchTermActions } from '../store/event-search-term-store'

export default function EventsPageActions() {
  const { t } = useTranslation('event')

  const { setSearchTerm } = useEventSearchTermActions()
  const searchTerm = useEventSearchTerm()

  return (
    <div className="flex flex-row justify-between gap-4">
      <EventFilterPopover />
      <SearchInput
        fullWidth
        placeholder={t('event:search-placeholder') as string}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        delay={600}
      />
    </div>
  )
}
