import axiosInstance from '../config/axios'
import CreateResourceRequest from '../requests/resources/create-resource-request'
import DeleteResourceRequest from '../requests/resources/delete-resource-request'
import UpdateResourceRequest from '../requests/resources/update-resource-request'
import CreateResourceResponse from '../responses/resources/create-resource-response'
import GetResourcesResponse from '../responses/resources/get-resourcers-response'
import UpdateResourceResponse from '../responses/resources/update-resource-response'

const RESOURCES_BASE_URL = '/resources'

const getResources = async () => (axiosInstance.get<GetResourcesResponse>(RESOURCES_BASE_URL))

const createResource = async (request: CreateResourceRequest) => (axiosInstance.post<CreateResourceResponse>(RESOURCES_BASE_URL, request))

const updateResource = async (request: UpdateResourceRequest) => (axiosInstance.put<UpdateResourceResponse>(`${RESOURCES_BASE_URL}/${request.id}`, request))

const deleteResource = async (request: DeleteResourceRequest) => (axiosInstance.delete(`${RESOURCES_BASE_URL}/${request.id}`))

export default {
  getResources,
  createResource,
  updateResource,
  deleteResource,
}
