import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, ScrollArea, Text } from '@mantine/core'
import Modal from '../../../../../shared/components/general/modal'
import ManageInvoiceAdditionalItemsModalHeader from './manage-invoice-additional-items-modal-header'
import ManageInvoiceAdditionalItemContent from './manage-invoice-additional-item-content'
import EmptyContentMessage from '../../../../../shared/components/designs/empty-content-message'
import UpsertInvoiceAdditionalItems from '../../../../../interfaces/invoice/upsert-invoice-additional-items'
import UpsertInvoiceAdditionalItemAttributes from '../../../../../interfaces/invoice/upsert-invoice-additional-item-attributes'
import { useUpdateInvoiceAdditionalItems } from '../../../queries'

interface Props {
  opened: boolean;
  onClose: () => void;
  additionalItems: UpsertInvoiceAdditionalItemAttributes[];
  invoiceId: string;
}

export default function ManageInvoiceAdditionalItemsModal({
  opened, onClose, additionalItems, invoiceId,
}: Props) {
  const { t } = useTranslation(['common', 'invoice'])

  const { mutate, isLoading } = useUpdateInvoiceAdditionalItems({ onSuccess: () => onClose() })

  const useFormMethods = useForm<UpsertInvoiceAdditionalItems>({
    mode: 'onTouched',
    defaultValues: {
      additionalItems,
    },
  })

  const {
    handleSubmit, control, reset, setError, formState: { errors },
  } = useFormMethods

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'additionalItems',
  })

  const fixAdditionalItemDescriptionAttributes = (data: UpsertInvoiceAdditionalItems) => data.additionalItems.map((item) => ({
    ...item,
    description: item.description === '' ? null : item.description,
  }))

  const validateAdditionalItemsAmount = (data: UpsertInvoiceAdditionalItems) => {
    let isValid = true
    data.additionalItems.forEach((item, index) => {
      if (item.amount <= 0) {
        setError(`additionalItems.${index}.amount`, { message: t('invoice:item-form:amount-error') as string })
        isValid = false
      }
    })
    return isValid
  }

  const handleSubmitAdditionalItem = (data: UpsertInvoiceAdditionalItems) => {
    const formAttributes = fixAdditionalItemDescriptionAttributes(data)
    if (!validateAdditionalItemsAmount(data)) return
    mutate({ invoiceId, additionalItems: formAttributes, currentInvoiceAdditionalItems: additionalItems })
  }

  const handleRemoveAdditionalItem = (index: number) => {
    remove(index)
  }

  const handleCloseModal = () => {
    onClose()
    reset({ additionalItems })
  }

  const emptyFields = fields.length === 0

  return (
    <Modal
      size="xl"
      opened={opened}
      onClose={handleCloseModal}
      hideHeader
    >
      <FormProvider {...useFormMethods}>
        <div className="flex flex-col items-center h-115">
          <div className="w-[calc(100%-20px)]">

            <form
              id="plan-form"
              onSubmit={handleSubmit((createInvoiceItemAttributes: UpsertInvoiceAdditionalItems) => handleSubmitAdditionalItem(createInvoiceItemAttributes))}
              className="flex flex-col w-full"
            >
              <ManageInvoiceAdditionalItemsModalHeader
                appendAdditionalItem={append}
              />

              <div className="h-90">
                <ScrollArea.Autosize className="h-full" offsetScrollbars>
                  <div className="mt-4">
                    {emptyFields ? (
                      <div className="mt-20">
                        <EmptyContentMessage text={t('invoice:item:empty')} />
                      </div>
                    ) : (
                      <div className="flex flex-col gap-4">
                        {fields.map((field, index) => (
                          <div key={field.id || field.renderId}>
                            <ManageInvoiceAdditionalItemContent item={field} index={index} remove={handleRemoveAdditionalItem} />
                          </div>
                        ))}
                        {Object.keys(errors).length > 0 && <Text size="xs" color="red">{t('invoice:item-form:error')}</Text>}
                      </div>
                    )}
                  </div>
                </ScrollArea.Autosize>
              </div>

              <Button
                color="violet"
                className="mt-4 w-50"
                type="submit"
                loading={isLoading}
              >
                {t('common:save')}
              </Button>

            </form>
          </div>
        </div>
      </FormProvider>
    </Modal>
  )
}
