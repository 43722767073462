import { UpsertUserAttributes, PartialUpdateSelfUserAttributes } from '../../../interfaces'
import { api } from '../../../libs'
import DeleteUserRequest from '../../../libs/api/requests/users/delete-user-request'
import PartialUpdateSelfUserRequest from '../../../libs/api/requests/users/partial-update-self-user-request'
import { User } from '../../../models'
import ExtendedUser from '../../../models/extended-user'

const fetchUsers = async (): Promise<ExtendedUser[]> => {
  const getUsersResponse = await api.users.getUsers()

  return getUsersResponse.data
}

const createUser = async (createUserAttributes: UpsertUserAttributes): Promise<ExtendedUser> => {
  const user = {
    id: createUserAttributes.id,
    name: createUserAttributes.name,
    email: createUserAttributes.email,
    password: createUserAttributes.password,
    customerId: createUserAttributes.customerId || null,
    groups: [createUserAttributes.group],
  }

  const createUserResponse = await api.users.createUser(user)

  return createUserResponse?.data
}

const partialUpdateSelfUser = async (partialUpdateSelfUserAttributes: PartialUpdateSelfUserAttributes): Promise<User> => {
  const updatedUserRequest: PartialUpdateSelfUserRequest = {
    id: partialUpdateSelfUserAttributes.id,
    name: partialUpdateSelfUserAttributes.name,
    email: partialUpdateSelfUserAttributes.email,
    password: partialUpdateSelfUserAttributes.password === '' ? undefined : partialUpdateSelfUserAttributes.password,
  }

  const createUserResponse = await api.users.partialUpdateSelfUser(updatedUserRequest)

  return createUserResponse?.data
}

const updateUser = async (updateUserAttributes: UpsertUserAttributes): Promise<ExtendedUser> => {
  const user = {
    id: updateUserAttributes.id,
    name: updateUserAttributes.name,
    email: updateUserAttributes.email,
    password: updateUserAttributes.password.length ? updateUserAttributes.password : undefined,
    customerId: updateUserAttributes.customerId,
    groups: [updateUserAttributes.group],
  }

  const updateUserResponse = await api.users.updateUser(user)

  return updateUserResponse?.data
}

const deleteUser = async (deleteUserAttributes: DeleteUserRequest) => {
  await api.users.deleteUser(deleteUserAttributes)
}

const UsersService = {
  fetchUsers,
  createUser,
  partialUpdateSelfUser,
  updateUser,
  deleteUser,
}

export default UsersService
