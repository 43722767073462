/* eslint-disable @typescript-eslint/no-unused-vars */
import { v4 as uuidv4 } from 'uuid'
import {
  UpsertMetricAttributes, UpsertExtendedPlanAttributes, UpsertPriceTierAttributes, UpsertPlanAttributes,
} from '../../../interfaces'
import { ExtendedPlan, Plan } from '../../../models'

const planToUpsertPlanAttributes = (plan: Plan): UpsertPlanAttributes => {
  const upsertPlanAttrbutes = {
    ...plan,
    renderId: uuidv4(),
  }

  return upsertPlanAttrbutes
}

const extendedPlanToUpsertExtendedPlanAttributes = (plan: ExtendedPlan): UpsertExtendedPlanAttributes => {
  const upsertMetrics = plan.metrics.map((metric: UpsertMetricAttributes) => {
    const upsertPriceTiers = metric.priceTiers.map((priceTier: UpsertPriceTierAttributes) => ({
      ...priceTier,
      renderId: uuidv4(),
    }))
    return {
      ...metric,
      renderId: uuidv4(),
      priceTiers: upsertPriceTiers,
    }
  })
  return {
    ...plan,
    planSettings: {
      fixedAmount: plan.planSettings.fixedAmount,
    },
    metrics: upsertMetrics,
  }
}

const upsertExtendedPlanAttributesToExtendedPlan = (upsertPlanAttributes: UpsertExtendedPlanAttributes): UpsertExtendedPlanAttributes => {
  const metrics = upsertPlanAttributes.metrics.map((metric: UpsertMetricAttributes) => {
    const priceTiers = metric.priceTiers.map((priceTier: UpsertPriceTierAttributes) => {
      const { renderId, ...restOfPriceTier } = priceTier
      return { ...restOfPriceTier }
    })

    const { renderId, ...restOfMetric } = metric
    return { ...restOfMetric, priceTiers }
  })

  return {
    ...upsertPlanAttributes,
    planSettings: {
      fixedAmount: upsertPlanAttributes.planSettings.fixedAmount,
    },
    metrics,
  }
}

const upsertExtendedPlanAttributesToUpdatedPlan = (upsertPlanAttributes: UpsertExtendedPlanAttributes): UpsertExtendedPlanAttributes => {
  const metrics = upsertPlanAttributes.metrics.map((metric: UpsertMetricAttributes) => {
    const priceTiers = metric.priceTiers.map((priceTier: UpsertPriceTierAttributes) => {
      const { renderId, id, ...restOfPriceTier } = priceTier
      if (!metric.id) {
        return { ...restOfPriceTier }
      }
      return { ...restOfPriceTier, id }
    })

    const { renderId, ...restOfMetric } = metric
    return { ...restOfMetric, priceTiers }
  })

  return {
    ...upsertPlanAttributes,
    planSettings: {
      fixedAmount: upsertPlanAttributes.planSettings.fixedAmount,
    },
    metrics,
  }
}

const duplicatedPlanToFormValue = (plan: UpsertExtendedPlanAttributes) => ({
  name: '',
  description: '',
  product: plan.product,
  planSettings: {
    fixedAmount: plan.planSettings.fixedAmount,
  },
  metrics: plan.metrics.map((metric: UpsertMetricAttributes) => ({
    renderId: uuidv4(),
    resourceId: metric.resourceId,
    name: metric.name,
    aggregation: metric.aggregation,
    billingModel: metric.billingModel,
    priceTierDivision: metric.priceTierDivision,
    fixedAmount: metric.fixedAmount,
    priceTiers: metric.priceTiers.map((priceTier:UpsertPriceTierAttributes) => ({
      renderId: uuidv4(),
      billingType: priceTier.billingType,
      from: priceTier.from,
      to: priceTier.to,
      packageSize: priceTier.packageSize,
      price: priceTier.price,
      fixedPrice: priceTier.fixedPrice,
    })),
  })),
})

export {
  extendedPlanToUpsertExtendedPlanAttributes,
  upsertExtendedPlanAttributesToExtendedPlan,
  upsertExtendedPlanAttributesToUpdatedPlan,
  planToUpsertPlanAttributes,
  duplicatedPlanToFormValue,
}
