import { ScrollArea } from '@mantine/core'
import PlanForm from './plan-form'
import MetricForm from '../../metric/components/metric-form'
import PlanSummaryButtons from './plan-summary-buttons'

interface Props {
  isValid: boolean
  isLoading: boolean;
  handleCancel: () => void;
}

export default function PlanFormContainer({
  isValid, isLoading, handleCancel,
}: Props) {
  return (
    <div className="h-[calc(100vh-120px)] w-content">
      <ScrollArea.Autosize className="h-full" offsetScrollbars>
        <div className="w-[99%] min-h-[calc(100vh-150px)]">
          <PlanForm />
          <MetricForm />
          <div className="block xl:hidden w-full -mt-6">
            <PlanSummaryButtons isValid={isValid} isLoading={isLoading} handleCancel={handleCancel} />
          </div>
        </div>
      </ScrollArea.Autosize>
    </div>
  )
}
