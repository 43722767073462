import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useInvoiceQuery } from '../../queries'
import InvoiceDetailHeader from './invoice-detail-header'
import InvoicePlansDetail from './plan-detail/invoice-plans-detail'
import FetchInvoiceSkeleton from '../general-component/fetch-invoice-skeleton'
import ManageInvoiceAdditionalItemsModal from './additional-item/manage-invoice-additional-items-modal'
import InvoiceDetailSideContent from './invoice-detail-side-content'
import { extendedInvoiceAdditionalItemsToUpsertInvoiceAdditionalItems } from '../../utils/upsert-invoice-utils'
import InvoiceAdditionalItemsDetail from './additional-item/invoice-additional-items-detail'

export default function InvoiceDetailContent() {
  const { id } = useParams()

  const {
    data: invoice,
    isLoading: isQueryLoading,
  } = useInvoiceQuery(id)

  const [isManageInvoiceModalOpened, setIsManageInvoiceModalOpened] = useState(false)

  if (isQueryLoading || !invoice) {
    return <FetchInvoiceSkeleton />
  }

  const upsertedAdditionalItems = extendedInvoiceAdditionalItemsToUpsertInvoiceAdditionalItems(
    invoice.additionalItems,
  )

  const handleOpenManageInvoiceModal = () => {
    setIsManageInvoiceModalOpened(true)
  }

  return (
    <div className="h-full w-full">
      <ManageInvoiceAdditionalItemsModal
        invoiceId={invoice.id}
        opened={isManageInvoiceModalOpened}
        onClose={() => setIsManageInvoiceModalOpened(false)}
        additionalItems={upsertedAdditionalItems}
      />

      <InvoiceDetailHeader
        invoice={invoice}
        onManageItems={handleOpenManageInvoiceModal}
      />

      <div className="flex w-full mt-6">
        <div className="w-9/12 mr-4">
          <div>
            <InvoicePlansDetail plans={invoice.plans} />
            <InvoiceAdditionalItemsDetail
              additionalItems={upsertedAdditionalItems}
            />
          </div>
        </div>

        <InvoiceDetailSideContent invoice={invoice} />
      </div>
    </div>
  )
}
