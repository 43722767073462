import { UpsertPaymentAccountAttributes } from '../../../interfaces'
import { api } from '../../../libs'
import { PaymentAccount } from '../../../models'
import upsertPaymentAccountUtils from '../utils/upsert-payment-account-utils'

const fetchPaymentAccounts = async (customerId: string): Promise<PaymentAccount[]> => {
  const getCustomersResponse = await api.paymentAccounts.getPaymentAccounts({ customerId })
  return getCustomersResponse.data
}

const createPaymentAccount = async (paymentAccountAttributes: UpsertPaymentAccountAttributes) => {
  const createPaymentAccountRequest = upsertPaymentAccountUtils.upsertPaymentAccountAttributesToCreatePaymentAccountRequest(paymentAccountAttributes)
  const createPaymentAccountResponse = await api.paymentAccounts.createPaymentAccount(createPaymentAccountRequest)
  return createPaymentAccountResponse.data
}

const updatePaymentAccount = async (paymentAccountAttributes: UpsertPaymentAccountAttributes) => {
  const updatePaymentAccountRequest = upsertPaymentAccountUtils.upsertPaymentAccountAttributesToUpdatePaymentAccountRequest(paymentAccountAttributes)
  const updatePaymentAccountResponse = await api.paymentAccounts.updatePaymentAccount(updatePaymentAccountRequest)
  return updatePaymentAccountResponse.data
}

const deletePaymentAccount = async (id: string, customerId: string) => {
  const deletePaymentAccountResponse = await api.paymentAccounts.deletePaymentAccount({ id, customerId })
  return deletePaymentAccountResponse.data
}

const paymentAccountsService = {
  fetchPaymentAccounts,
  createPaymentAccount,
  updatePaymentAccount,
  deletePaymentAccount,
}

export default paymentAccountsService
