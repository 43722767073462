import { useTranslation } from 'react-i18next'
import SearchInput from '../../../../shared/components/form/search-input'
import { useInvoiceExternalProcessorSearchTerm, useInvoiceExternalProcessorSearchTermActions } from '../../store/invoice-external-processor-search-term-store'
import InvoiceExternalProcessorFilterPopover from './invoice-external-processor-filter-popover'

export default function InvoiceExternalProcessorPageActions() {
  const { t } = useTranslation()

  const { setSearchTerm } = useInvoiceExternalProcessorSearchTermActions()
  const searchTerm = useInvoiceExternalProcessorSearchTerm()

  return (
    <div className="flex flex-row justify-between">
      <InvoiceExternalProcessorFilterPopover />
      <SearchInput placeholder={t('invoice:external-processor:search-placeholder') as string} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
    </div>
  )
}
