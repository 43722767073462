import { ActionIcon, Menu } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { FiEdit2 } from 'react-icons/fi'
import { HiDotsVertical, HiOutlineDuplicate } from 'react-icons/hi'
import { BiTrashAlt } from 'react-icons/bi'
import { MdOutlinePercent } from 'react-icons/md'
import { RiProhibitedLine } from 'react-icons/ri'
import Typography from '../general/typography'

interface Props {
  name: string;
  color?: 'violet' | 'gray'
  onDuplicate?: (rowData: any) => void;
  onAdjust?: (rowData: any) => void;
  onCancel?: (rowData: any) => void;
  onDelete?: (rowData: any) => void;
  onEdit: (rowData: any) => void;
}

export default function TableMenuCell({
  name, color = 'violet', onDuplicate, onAdjust, onDelete, onCancel, onEdit,
}: Props) {
  const { t } = useTranslation('common')

  return (
    <Menu
      shadow="md"
      position="bottom"
      withArrow
      arrowOffset={35}
      offset={-5}
      width={200}
    >
      <Menu.Target>
        <ActionIcon variant="transparent">
          <HiDotsVertical color={color === 'violet' ? '#6741d9' : '#adb5bd'} size={18} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>
          <Typography>
            {name}
          </Typography>
        </Menu.Label>
        {onDuplicate && (
        <Menu.Item onClick={onDuplicate}>
          <div className="flex gap-2 items-center">
            <HiOutlineDuplicate size={17} />
            {t('common:actions:duplicate')}
          </div>
        </Menu.Item>
        )}

        {onAdjust && (
        <Menu.Item onClick={onAdjust}>
          <div className="flex gap-2 items-center">
            <MdOutlinePercent size={17} />
            {t('common:actions:adjust')}
          </div>
        </Menu.Item>
        )}

        <Menu.Item onClick={onEdit}>
          <div className="flex gap-2 items-center">
            <FiEdit2 size={16} />
            {t('common:actions:edit')}
          </div>
        </Menu.Item>

        <Menu.Divider />
        {onDelete
        && (
        <Menu.Item
          color="red.9"
          onClick={onDelete}
        >
          <div className="flex gap-2 items-center">
            <BiTrashAlt size={16} />
            {t('common:actions:delete')}
          </div>
        </Menu.Item>
        )}

        {onCancel
        && (
        <Menu.Item
          color="red.9"
          onClick={onCancel}
        >
          <div className="flex gap-2 items-center">
            <RiProhibitedLine size={18} />
            {t('common:cancel')}
          </div>
        </Menu.Item>
        )}

      </Menu.Dropdown>
    </Menu>
  )
}
