function getInitials(name: string) {
  const nameArray = name.trim().split(' ').filter((word) => word.length > 0)
  const initials = nameArray
    .map((word) => word[0].toUpperCase())
    .reduce((acc, cur) => (acc.length < 2 ? acc + cur : acc), '')

  if (initials.length < 2 && nameArray.length > 0) {
    const secondInitial = nameArray[0][1]?.toUpperCase() || ''
    return initials + secondInitial
  }

  return initials
}

export { getInitials }
