import { useTranslation } from 'react-i18next'
import Typography from '../../../../shared/components/general/typography'
import getBillingCycleExternalProcessorErrorMessage from '../../../../shared/utils/messages/get-billing-cycle-external-processor-error-message'
import BillingCycelExternalProcessorUserCodes from '../../../../libs/api/enums/billing-cycle-external-processor-user-codes'

interface Props {
  value: number | string;
}

export function TableInvoiceExternalProcessorMessage({ value }: Props) {
  const { t } = useTranslation()

  return (
    <Typography>
      {value ? t(getBillingCycleExternalProcessorErrorMessage(value as BillingCycelExternalProcessorUserCodes)) : ''}
    </Typography>
  )
}
