import { useTranslation } from 'react-i18next'
import { Button, Text } from '@mantine/core'
import { Controller, useForm } from 'react-hook-form'
import Modal from '../../../shared/components/general/modal'
import RateAdjustPlanAttributes from '../../../interfaces/plan/rate-adjust-plan-attributes'
import { useRateAdjustPlan } from '../queries/plan-queries'
import DecimalInput from '../../../shared/components/form/decimal-input'

interface Props {
  opened: boolean;
  onClose: () => void;
  planId: string;
}

export default function RateAdjustPlanModal({ opened, onClose, planId }: Props) {
  const { t } = useTranslation(['plan', 'common'])

  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<RateAdjustPlanAttributes>({
    defaultValues: {
      planId,
      percentage: '0',
    },
    mode: 'onTouched',
  })

  const handleModalClose = () => {
    reset()
    onClose()
  }

  const { mutate, isLoading: isMutateLoading } = useRateAdjustPlan()

  return (
    <Modal size="lg" opened={opened} onClose={handleModalClose} title={t('plan:rate-adjust-plan-modal:title')}>
      <form
        className="flex flex-col items-center"
        onSubmit={handleSubmit(
          (rateAdjustPlanAttributes: RateAdjustPlanAttributes) => mutate(rateAdjustPlanAttributes, { onSuccess: handleModalClose }),
        )}
      >
        <Text fw={500} className="text-gray-700">
          {t('plan:rate-adjust-plan-modal:description')}
        </Text>

        <div className="mt-7 w-full">
          <div className="w-1/2">
            <Controller
              name="percentage"
              control={control}
              render={({ field }) => (
                <DecimalInput
                  decimalInputType="percentage"
                  className="w-full"
                  label={`${t('plan:rate-adjust-plan-modal:percentage-input-label') as string}`}
                  placeholder={t('common:form-placeholder:zero') as string}
                  error={errors.percentage && errors.percentage.message}
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                />
              )}
              rules={{
                required: { value: true, message: t('common:form-error:required-field') },
                min: 0.000001,
              }}
            />

          </div>
        </div>

        <div className="mt-7 w-full flex">
          <Button
            className="mr-3 w-40"
            color="violet"
            type="submit"
            disabled={!isValid}
            loading={isMutateLoading}
          >
            {t('plan:rate-adjust-plan-modal:apply-button')}
          </Button>

          <Button
            variant="outline"
            className="w-30"
            onClick={() => handleModalClose()}
            disabled={isMutateLoading}
          >
            {t('common:cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
