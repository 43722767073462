import { useTranslation } from 'react-i18next'
import { Aggregation } from '../enum'

const useAggregationOptions = () => {
  const { t } = useTranslation(['common', 'metric'])

  return [
    { value: Aggregation.Sum, label: t('metric:aggregation-sum') },
    { value: Aggregation.LastDuringPeriod, label: t('metric:aggregation-last-during-period') },
  ]
}

export default useAggregationOptions
