/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ActionIcon } from '@mantine/core'
import { FiTrash2 } from 'react-icons/fi'
import { useViewportSize } from '@mantine/hooks'
import stringHelper from '../../utils/helpers/string-helper'

interface Props {
  title: string;
  content: string;
  onClick?: () => void;
  onDelete?: () => void;
}

export default function ContentCard({
  title, content, onClick, onDelete,
}: Props) {
  const { width } = useViewportSize()

  return (
    <div
      className="w-full min-h-12 overflow-hidden bg-gray-50 flex p-2 rounded-xl items-center justify-between"
    >
      <div
        className="w-[98%] flex flex-col"
        onClick={onClick}
        role={onClick ? 'button' : undefined}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={onClick ? 0 : undefined}
      >
        <p className="text-md font-500 text-gray-900">
          {stringHelper.limitText(title, width / 16)}
        </p>
        {content && (
        <p className="text-sm text-gray-700 mt-2">
          {stringHelper.limitText(content, width / 15)}
        </p>
        )}
      </div>

      {onDelete && (
      <ActionIcon className="w-[2%]" onClick={onDelete} variant="transparent">
        <FiTrash2 size={18} color="#000000" />
      </ActionIcon>
      )}
    </div>
  )
}
