import { useTranslation } from 'react-i18next'
import Modal from '../../../shared/components/general/modal'
import MetricSummary from '../../metric/components/metric-summary'
import { usePlanQuery } from '../queries/plan-queries'
import ViewPlanModalSkeleton from './view-plan-modal-skeleton'
import PlanSummaryInfo from './plan-summary-info'
import formatToCurrency from '../../../shared/utils/helpers/format-to-currency'

interface Props {
  opened: boolean;
  onClose: () => void;
  planId: string;
}

export default function ViewPlanModal({ opened, onClose, planId }: Props) {
  const { t, i18n } = useTranslation('common')
  const { language } = i18n

  const { data, isLoading } = usePlanQuery(planId)

  return (
    <Modal size="lg" opened={opened} onClose={onClose} title={data?.name}>
      {isLoading ? (
        <ViewPlanModalSkeleton />
      )
        : (
          <div className="flex flex-col items-center">
            <div className="w-[calc(100%-20px)]">
              {data?.planSettings && (
              <PlanSummaryInfo
                label={t('common:fixed-amount')}
                planFixedAmount={formatToCurrency({ price: data.planSettings.fixedAmount, language })}
              />
              )}
              <MetricSummary metrics={data?.metrics || []} />
            </div>
          </div>
        )}
    </Modal>
  )
}
