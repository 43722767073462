import { modals } from '@mantine/modals'
import { Trans, useTranslation } from 'react-i18next'
import AlertCard from '../../../../shared/components/alert/alert-card'
import { UseCancelContract } from '../../queries'
import { ExtendedContract } from '../../../../models'

export const useCancelContractAlertModal = (customerId?: string) => {
  const { t } = useTranslation()

  const { mutate } = UseCancelContract(customerId)

  return (contract: ExtendedContract) => {
    modals.open({
      withCloseButton: false,
      children: (
        <AlertCard
          variant="warning"
          title={t('customer:contract:cancel-alert-modal:title') as string}
          description={(
            <Trans i18nKey={t('customer:contract:cancel-alert-modal:content')} />
          )}
          onContinue={() => {
            mutate(contract.id)
            modals.closeAll()
          }}
          onCancel={modals.closeAll}
        />
      ),
    })
  }
}
