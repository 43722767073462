import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { FormProvider, useForm } from 'react-hook-form'
import { ErrorResponse, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import { AxiosError } from 'axios'
import Modal from '../../../shared/components/general/modal'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import { UseUpdateContract } from '../queries'
import ContractForm from './contract-form'
import { ExtendedContract } from '../../../models'
import upsertContractUtils from '../utils/upsert-contract-utils'
import useUpdateContractAlert from './modal-actions/update-contract-alert'
import { useAuthTenant } from '../../auth/store/auth-store'
import useMutateContractErrorModal from './modal-actions/mutate-contract-error-modal'

interface Props {
  opened: boolean;
  onClose: () => void;
  contract: ExtendedContract
}

export default function UpdateContractModal({ opened, onClose, contract }: Props) {
  const { t } = useTranslation('customer')
  const { id: customerId } = useParams()

  const tenant = useAuthTenant()
  const isOmieEnabled = tenant.externalServices.omie.enabled

  const useFormMethods = useForm<UpsertContractAttributes>({
    mode: 'onTouched',
  })

  const {
    handleSubmit, reset, formState: { isValid, isDirty },
  } = useFormMethods

  const mutateContractErrorModal = useMutateContractErrorModal()
  const updateContractAlert = useUpdateContractAlert()

  const handleUpdatePaymentAccountSuccess = () => {
    onClose()
    reset()
  }

  const handleUpdateContractError = (error: AxiosError<ErrorResponse | any>) => {
    mutateContractErrorModal({
      error,
      onOverrideOmieSync: handleSubmit(handleOverrideOmieSync),
      onRetry: handleSubmit(handleRetry),
    })
  }

  const { mutate, isLoading } = UseUpdateContract({
    onSuccess: handleUpdatePaymentAccountSuccess,
    onError: isOmieEnabled ? handleUpdateContractError : undefined,
  })

  useEffect(() => {
    if (contract && !isDirty) {
      reset(upsertContractUtils.extendedContractToUpsertContractAttributes(contract))
    }
  }, [contract, reset, isDirty])

  const handleSubmitUpdateContractForm = (upsertContractAttributes: UpsertContractAttributes) => {
    const parsedCurrentContract = upsertContractUtils.extendedContractToUpsertContractAttributes(contract)
    const contractToBeUpdated = { ...upsertContractAttributes, id: contract.id, customerId: customerId! }

    const parsedCurrentContractStartDate = dayjs(parsedCurrentContract.startDate)
    const upsertContractAttributesStartDate = dayjs(upsertContractAttributes.startDate)

    if (parsedCurrentContractStartDate.isSame(upsertContractAttributesStartDate, 'millisecond')) {
      mutate(contractToBeUpdated)
      return
    }

    updateContractAlert({
      upsertContractAttributes,
      onContinue: () => mutate(contractToBeUpdated),
    })
  }

  const handleOverrideOmieSync = (upsertContractAttributes: UpsertContractAttributes) => {
    const contractToBeUpdated = {
      ...upsertContractAttributes,
      id: contract.id,
      customerId: customerId!,
      omieDetails: {
        ...upsertContractAttributes.omieDetails,
        overrideOmieSync: true,
      },
    }

    mutate(contractToBeUpdated)
  }

  const handleRetry = (upsertContractAttributes: UpsertContractAttributes) => {
    const contractToBeUpdated = { ...upsertContractAttributes, id: contract.id, customerId: customerId! }
    mutate(contractToBeUpdated)
  }

  return (
    <Modal size="xl" opened={opened} onClose={onClose} title={t('customer:contract:edit-contract')}>
      <FormProvider {...useFormMethods}>
        <div className="flex flex-col items-center">
          <div className="w-[calc(100%-20px)]">
            <form
              onSubmit={handleSubmit((upsertContractAttributes) => handleSubmitUpdateContractForm(upsertContractAttributes))}
              className="flex flex-col w-full"
            >
              <ContractForm updatedContract={contract} />

              <div className="w-full h-15 bg-white bottom-0 -mb-4 sticky">
                <Button
                  className="w-50 mt-3"
                  color="violet"
                  type="submit"
                  disabled={!isValid}
                  loading={isLoading}
                >
                  {t('common:save')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </FormProvider>
    </Modal>
  )
}
