import { useTranslation } from 'react-i18next'
import formatToCurrency from '../../utils/helpers/format-to-currency'
import Typography from '../general/typography'

interface Props {
  value: any;
}

export function TableCurrencyCell({ value }: Props) {
  const { i18n } = useTranslation()
  const { language } = i18n

  return (
    <Typography>
      {formatToCurrency({ price: value, language })}
    </Typography>
  )
}
