import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import PaymentAccountAddressForm from './payment-account-address-form'
import PaymentAccountGeneralInformationForm from './payment-account-general-information-form'
import CollapseForm from '../../../shared/components/form/collapse-form'
import CollapseFormSection from '../../../shared/components/form/collapse-form-section'
import { UpsertPaymentAccountAttributes } from '../../../interfaces'

enum Tabs {
  GENERAL_INFORMATION = 'general-information',
  PAYMENT_ADDRESS = 'payment-address',
}

export default function PaymentAccountForm() {
  const { t } = useTranslation(['customer', 'common'])

  const [activeSection, setActiveSection] = useState<Tabs>(Tabs.GENERAL_INFORMATION)

  const handleSectionChange = (section: string | null) => {
    if (Object.values(Tabs).includes(section as Tabs)) setActiveSection(section as Tabs)
  }

  const { watch, formState: { errors } } = useFormContext<UpsertPaymentAccountAttributes>()

  const formValues = watch()

  const isGeneralInformationComplete = useMemo(
    () => (!!formValues.taxId
    && !!formValues.businessName
    && !errors.taxId
    && !errors.businessName),
    [formValues, errors],
  )

  const isPaymentAddressComplete = useMemo(
    () => (!!formValues.address?.zipCode
    && !!formValues.address?.number
    && !!formValues.address?.street
    && !!formValues.address?.neighborhood
    && !!formValues.address?.city
    && !!formValues.address?.state
    && !!formValues.address?.country
    && !errors.address?.zipCode),
    [formValues, errors],
  )

  return (
    <CollapseForm activeSection={activeSection} setActiveSection={handleSectionChange}>

      <CollapseFormSection
        value={Tabs.GENERAL_INFORMATION}
        isSectionComplete={isGeneralInformationComplete}
        sectionTitle={t('customer:payment-account:form-sections:general-information')}
      >
        <PaymentAccountGeneralInformationForm />
      </CollapseFormSection>

      <CollapseFormSection
        value={Tabs.PAYMENT_ADDRESS}
        isSectionComplete={isPaymentAddressComplete}
        sectionTitle={t('customer:payment-account:form-sections:payment-address')}
      >
        <PaymentAccountAddressForm />
      </CollapseFormSection>
    </CollapseForm>
  )
}
