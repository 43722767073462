import { Button } from '@mantine/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CreateContractModal from './create-contract-modal'
import ContractsList from './contracts-list'
import { UseContractsQuery } from '../queries'
import ContractsListSkeleton from './contracts-list-skeletion'

export default function ContractTab() {
  const { t } = useTranslation('customer')
  const { id: customerId } = useParams()

  const {
    data: contracts,
    isLoading,
  } = UseContractsQuery(customerId)

  const [isCreateContractModalOpen, setIsCreateContractModalOpen] = useState<boolean>(false)

  return (
    <div>
      <CreateContractModal
        opened={isCreateContractModalOpen}
        onClose={() => setIsCreateContractModalOpen(false)}
      />

      <Button
        className="mt-2"
        variant="outline"
        onClick={() => setIsCreateContractModalOpen(true)}
      >
        {t('customer:contract:add-contract')}
      </Button>

      <div className="mt-4">
        {isLoading ? (
          <ContractsListSkeleton />)
          : (<ContractsList contracts={contracts || []} />
          )}
      </div>
    </div>
  )
}
