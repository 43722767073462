import { MatchKey } from '../../../interfaces/queries/match-key'
import { Plan } from '../../../models'

const partialMatchKeys : MatchKey<Plan>[] = ['name', 'description']
const fullMatchKeys : MatchKey<Plan>[] = []
const indexKey = 'id' as keyof Plan

const tablePlansUtils = {
  partialMatchKeys,
  fullMatchKeys,
  indexKey,
}

export default tablePlansUtils
