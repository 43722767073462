import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TableColumn } from '../../../interfaces'
import { CoreTable } from '../../../shared/components/table'
import { useFilteredCustomersQuery } from '../queries'
import tableCustomersUtils from '../utils/table-customers-utils'
import { TableCustomerArrowCell } from './table-customer-menu-cell'
import { Customer } from '../../../models'
import { Path } from '../../../shared/enums'

export default function TableCustomers() {
  const { t } = useTranslation(['customer', 'common'])
  const navigate = useNavigate()

  const navigateToCustomerDetails = async (customer: Customer) => {
    navigate(Path.CustomerDetails.replace(':id', customer.id))
  }

  const {
    data: customers,
    isLoading,
    isFetching,
  } = useFilteredCustomersQuery()

  const tableCustomersColumns: TableColumn<Customer>[] = [
    {
      label: t('customer:name'),
      key: 'name',
      columnKey: 'name',
      widthPercentage: 45,
    },
    {
      label: t('customer:external-id'),
      key: 'externalId',
      columnKey: 'externalId',
      widthPercentage: 45,
    },
    {
      label: '',
      key: 'id',
      columnKey: 'customerUpdate',
      Component: TableCustomerArrowCell,
      widthPercentage: 10,
    },
  ]

  return (
    <CoreTable<Customer>
      data={customers || []}
      indexKey={tableCustomersUtils.indexKey}
      columns={tableCustomersColumns}
      isFetching={isFetching}
      isLoading={isLoading}
      rowAction={navigateToCustomerDetails}
      pageConfiguration={{
        pageVariation: 1,
      }}
    />
  )
}
