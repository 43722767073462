import React from 'react'

interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
}

export default function InvoicePriceTierItemContent({ left, right }: Props) {
  return (
    <div className="flex items-center w-full text-gray-700">
      <span className="flex-shrink-0">{left}</span>
      {right && (
        <>
          <div className="flex-grow mx-4">
            <hr className="mt-1 border-t border-dashed border-gray-700" />
          </div>
          <span className="flex-shrink-0">{right}</span>
        </>
      )}
    </div>
  )
}
