import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TableColumn } from '../../../../../interfaces'
import tableUserUtils from '../../../utils/table-invoice-utils'
import { useFilteredInvoicesQuery } from '../../../queries'
import { TableDateCell } from '../../../../../shared/components/table/table-date-cell'
import { Path } from '../../../../../shared/enums'
import { TableInvoiceStatusCell } from '../table-invoice-status-cell'
import { BillingCycleStatus } from '../../../enum'
import { CoreTable } from '../../../../../shared/components/table'
import PaymentInvoiceViewModel from '../../../../../interfaces/invoice/payment-invoice-view-model'
import { TableInvoiceAmountCell } from '../table-invoice-amount-cell'
import { TableInvoicePaymentStatusCell } from '../table-invoice-payment-status-cell'
import { getInvoicePayment } from '../../../../payment/utils/get-invoice-payment'

export default function PaymentTableInvoices() {
  const { t } = useTranslation(['invoice', 'common'])
  const navigate = useNavigate()

  const {
    data: invoices,
    isLoading: isInvoicesQueryLoading,
    isFetching,
  } = useFilteredInvoicesQuery()

  const invoicesToViewModel: PaymentInvoiceViewModel[] = invoices?.map((invoice) => {
    const latestPayment = getInvoicePayment(invoice.payments)

    return {
      ...invoice,
      id: invoice.id,
      paymentAccountName: invoice.paymentAccount.tradeName || invoice.paymentAccount.businessName,
      status: invoice.status,
      paymentStatus: latestPayment ? latestPayment.status : null,
      paymentDueDate: latestPayment ? latestPayment.dueDate : null,
      displayAmount: {
        amount: invoice.totalAmount,
        status: invoice.status,
      },
      invoiceEndDate: invoice.endDate,
      paymentStartDate: latestPayment ? latestPayment.startDate : null,
      customerName: invoice.customer.name,
    }
  }) ?? []

  const tableUserColumns: TableColumn<PaymentInvoiceViewModel>[] = [
    {
      label: t('invoice:id'),
      key: 'id',
      columnKey: 'id',
      widthPercentage: 18,
    },
    {
      label: t('customer:payment-account:name'),
      key: 'paymentAccountName',
      columnKey: 'paymentAccount.id',
      widthPercentage: 25,
    },
    {
      label: t('invoice:status'),
      key: 'status',
      columnKey: 'status',
      widthPercentage: 20,
      ignoreRowClick: (rowData: PaymentInvoiceViewModel) => rowData.status === BillingCycleStatus.Closed,
      Component: TableInvoiceStatusCell,
    },
    {
      label: t('invoice:payment-status'),
      key: 'paymentStatus',
      columnKey: 'paymentStatus',
      widthPercentage: 25,
      Component: TableInvoicePaymentStatusCell,
    },
    {
      label: t('invoice:due-date'),
      key: 'paymentDueDate',
      columnKey: 'paymentDueDate',
      widthPercentage: 25,
      Component: TableDateCell,
    },
    {
      label: t('invoice:value'),
      key: 'displayAmount',
      columnKey: 'displayAmount',
      widthPercentage: 25,
      Component: TableInvoiceAmountCell,
    },
    {
      label: t('invoice:closed-date'),
      key: 'invoiceEndDate',
      columnKey: 'invoiceEndDate',
      widthPercentage: 25,
      Component: TableDateCell,
    },
    {
      label: t('invoice:payment-start-date'),
      key: 'paymentStartDate',
      columnKey: 'paymentStartDate',
      widthPercentage: 30,
      Component: TableDateCell,
    },
    {
      label: t('invoice:client'),
      key: 'customerName',
      columnKey: 'customerId',
      widthPercentage: 25,
    },
  ]

  const handleInvoiceDetail = (invoice: PaymentInvoiceViewModel) => {
    navigate(Path.InvoiceDetail.replace(':id', invoice.id))
  }

  return (
    <CoreTable<PaymentInvoiceViewModel>
      minWidth={1500}
      data={invoicesToViewModel || []}
      indexKey={tableUserUtils.paymentIndexKey}
      columns={tableUserColumns}
      isFetching={isFetching}
      isLoading={isInvoicesQueryLoading}
      pageConfiguration={{
        pageVariation: 1,
      }}
      rowAction={handleInvoiceDetail}
    />
  )
}
