import axiosInstance from '../config/axios'
import CalculateInvoiceRequest from '../requests/invoices/calculate-invoice-request'
import CreateExternalProcessorSyncRequest from '../requests/invoices/create-external-processor-sync-request'
import GetFiscalInvoiceFileRequest from '../requests/invoices/get-fiscal-invoice-file-request'
import GetInvoiceDocumentRequest from '../requests/invoices/get-invoice-document-request'
import GetInvoiceRequest from '../requests/invoices/get-invoice-request'
import UpdateInvoiceAdditionalItemsRequest from '../requests/invoices/update-invoice-additional-items-request'
import CalculateInvoiceResponse from '../responses/invoices/calculate-invoice-response'
import GetFiscalInvoiceFileResponse from '../responses/invoices/get-fiscal-invoice-file-response'
import GetInvoiceDocumentResponse from '../responses/invoices/get-invoice-document-response'
import GetInvoiceResponse from '../responses/invoices/get-invoice-response'
import GetInvoiceExternalProcessorsResponse from '../responses/invoices/get-invoices-external-processors'
import GetInvoicesResponse from '../responses/invoices/get-invoices-response'
import UpdateInvoiceAdditionalItemsResponse from '../responses/invoices/update-invoice-additional-items-response'

const INVOICE_BASE_URL = '/invoices'
const BILLING_CYCLE_BASE_URL = '/billing-cycles'

const getInvoice = async (request: GetInvoiceRequest) => (axiosInstance.get<GetInvoiceResponse>(`${INVOICE_BASE_URL}/${request.id}`))

const getInvoices = async () => (axiosInstance.get<GetInvoicesResponse>(INVOICE_BASE_URL))

const getInvoiceExternalProcessors = async () => (axiosInstance.get<GetInvoiceExternalProcessorsResponse>(`${BILLING_CYCLE_BASE_URL}/external-processor-sync`))

const getDocument = async (request: GetInvoiceDocumentRequest) => (axiosInstance.get<GetInvoiceDocumentResponse>(
  `${INVOICE_BASE_URL}/${request.id}/document/download`,
  {
    responseType: 'blob',
  },
))

const getFiscalInvoiceFile = async (request: GetFiscalInvoiceFileRequest) => axiosInstance.get<GetFiscalInvoiceFileResponse>(
  `${INVOICE_BASE_URL}/${request.id}/files/fiscal-invoice/download`,
  {
    responseType: 'blob',
    params: {
      documentType: request.fiscalInvoiceFile,
    },
  },
)

// eslint-disable-next-line arrow-body-style
const updateAdditionalItems = async (request: UpdateInvoiceAdditionalItemsRequest) => {
  return (axiosInstance.put<UpdateInvoiceAdditionalItemsResponse>(`${INVOICE_BASE_URL}/${request.invoiceId}/additional-items`, request.additionalItems))
}

// eslint-disable-next-line arrow-body-style
const createExternalProcessorSync = async (request: CreateExternalProcessorSyncRequest) => {
  return axiosInstance.post<UpdateInvoiceAdditionalItemsResponse>(`${INVOICE_BASE_URL}/external-processor-sync`, request)
}

// eslint-disable-next-line arrow-body-style
const calculate = async (request: CalculateInvoiceRequest) => {
  return axiosInstance.post<CalculateInvoiceResponse>(`${INVOICE_BASE_URL}/${request.id}/calculate`, request)
}

export default {
  getInvoice,
  getInvoices,
  getDocument,
  updateAdditionalItems,
  createExternalProcessorSync,
  getInvoiceExternalProcessors,
  calculate,
  getFiscalInvoiceFIle: getFiscalInvoiceFile,
}
