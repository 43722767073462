import UserCodes from '../../../libs/api/enums/user-codes'

const userCodesMessages = {
  [UserCodes.ExternalIdBelongsToAnotherResource]: 'messages:error.external-id-belongs-to-another-resource',
  [UserCodes.ExternalIdBelongsToAnotherCustomer]: 'messages:error.external-id-belongs-to-another-customer',
  [UserCodes.EmailBelongsToAnotherUser]: 'messages:error.email-belongs-to-another-user',
  [UserCodes.TaxIdBelongsToAnotherPaymentAccount]: 'messages:error.tax-id-belongs-to-another-payment-account',
  [UserCodes.DuplicateContractProcessorId]: 'messages:error.duplicate-contract-processor-id',
  default: 'messages:error.unexpected',
}

export default userCodesMessages
