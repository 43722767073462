import { useTranslation } from 'react-i18next'
import { Tabs } from '@mantine/core'
import ContractTab from '../../contract/components/contract-tab'
import PaymentAccountTab from '../../payment-account/components/payment-account-tab'
import CustomerSettings from './customer-settings'

export default function CustomerTabs() {
  const { t } = useTranslation(['customer', 'common'])

  return (
    <div>
      <Tabs color="violet" defaultValue="first">
        <Tabs.List>
          <Tabs.Tab value="first">
            {t('customer:contracts')}
          </Tabs.Tab>
          <Tabs.Tab value="second">
            {t('customer:payment-accounts')}
          </Tabs.Tab>
          <Tabs.Tab value="third">
            {t('customer:settings')}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="first" pt="xs">
          <ContractTab />
        </Tabs.Panel>

        <Tabs.Panel value="second" pt="xs">
          <PaymentAccountTab />
        </Tabs.Panel>

        <Tabs.Panel value="third" pt="xs">
          <CustomerSettings />
        </Tabs.Panel>
      </Tabs>
    </div>
  )
}
