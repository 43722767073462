import { Card, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import UpsertInvoiceAdditionalItemAttributes from '../../../../../interfaces/invoice/upsert-invoice-additional-item-attributes'
import InvoiceAdditionalItemDetailAccordion from './invoice-additional-item-detail-accordion'

interface Props {
  additionalItems: UpsertInvoiceAdditionalItemAttributes[];
}

export default function InvoiceAdditionalItemsDetail({ additionalItems }: Props) {
  const { t } = useTranslation(['common', 'invoice'])

  const invoiceWithoutAdditionalItems = additionalItems.length === 0
  const lastAdditionalItemsIndex = additionalItems.length - 1

  return (
    <div>
      {invoiceWithoutAdditionalItems ? null : (
        <Card padding="lg" radius="md" className="flex justify-between items-center border border-gray-border-default">
          <div className="flex flex-col gap-3 w-full">
            <Text size="lg" fw={700} className="text-black">
              {t('invoice:additional-items') as string}
            </Text>
            {(additionalItems.map((item, index) => (
              <div key={item.id || item.renderId}>
                <InvoiceAdditionalItemDetailAccordion
                  additionalItem={item}
                  currentAdditionalItemIndex={index}
                  lastAdditionalItemsIndex={lastAdditionalItemsIndex}
                />
              </div>
            )))}
          </div>
        </Card>
      )}
    </div>
  )
}
