import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ExtendedContract } from '../../../models'
import Typography from '../../../shared/components/general/typography'
import ContractCardStatusBadge from './contract-card-status-badge'
import TableMenuCell from '../../../shared/components/table/table-menu-cell'
import UpdateContractModal from './update-contract-modal'
import { useCancelContractAlertModal } from './modal-actions/cancel-contract-alert-modal'
import { formatPlanNames } from '../../../shared/utils/helpers/format-plan-names'
import { ContractStatus } from '../enum'

interface Props {
  contract: ExtendedContract;
}

export default function ContractCard({ contract }: Props) {
  const { t, i18n } = useTranslation(['customer'])
  const { id: customerId } = useParams()

  const [isUpdateContractModalOpen, setIsUpdateContractModalOpen] = useState(false)

  const cancelContract = useCancelContractAlertModal(customerId)

  const contractName = formatPlanNames(contract.plans?.map((plan) => plan.name))

  const formatDate = (dateString: string): string => {
    const date = dayjs(dateString).toDate()
    return date.toLocaleDateString(i18n.language)
  }

  return (
    <div className="flex border border-gray-400 rounded w-full p-4 justify-between items-center">
      <UpdateContractModal
        opened={isUpdateContractModalOpen}
        onClose={() => setIsUpdateContractModalOpen(false)}
        contract={contract}
      />
      <div className={`flex flex-col ${contract.status !== ContractStatus.Active ? 'w-full' : 'w-[95%]'}`}>
        <div className="flex gap-4 justify-between items-center">
          <div className="flex flex-col 2xl:w-[92%] xl:w-[90%] lg:w-[85%] w-[80%]">
            <Typography size="md" fw={400} truncate>
              {contractName}
            </Typography>
            <Typography className="text-gray-600" size="sm" fw={400} truncate>
              {contract.paymentAccount.tradeName || contract.paymentAccount.businessName}
              {' • '}
              {formatDate(contract.startDate)}
              {' > '}
              {contract.endDate === null ? t('customer:contract-no-end-date') : formatDate(contract.endDate)}
              {' • '}
              {t('customer:closes-every-label')}
              {' '}
              {contract.billingEndDay}
            </Typography>
          </div>
          <ContractCardStatusBadge status={contract.status} />
        </div>
      </div>
      {contract.status === ContractStatus.Active && (
        <TableMenuCell
          name={contractName}
          color="gray"
          onCancel={() => cancelContract(contract)}
          onEdit={() => setIsUpdateContractModalOpen(true)}
        />
      )}
    </div>
  )
}
