import { ResourcePageActions, TableResource } from '../features/resource/components'

export default function Resources() {
  return (
    <div className="h-full w-full flex flex-col">
      <ResourcePageActions />
      <TableResource />
    </div>
  )
}
