import {
  Button, MultiSelect, Popover,
} from '@mantine/core'
import { TbChecks } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import DateRangeInput from '../../../../shared/components/form/date-range-input'
import { useInvoiceExternalProcessorFilter, useInvoiceExternalProcessorFilterActions } from '../../store/invoice-external-processor-filter-store'
import { useInvoiceExternalProcessorStatusOptions } from '../../utils/use-invoice-external-processor-status-options'

export default function InvoiceExternalProcessorFilterPopover() {
  const { t } = useTranslation(['invoice', 'common'])

  const filter = useInvoiceExternalProcessorFilter()
  const { setStatus, setDateRange } = useInvoiceExternalProcessorFilterActions()

  const billingCycleStatusOptions = useInvoiceExternalProcessorStatusOptions()

  const handleClear = () => {
    setStatus([])
    setDateRange([null, null])
  }

  return (
    <Popover width={350} position="bottom" shadow="md" withArrow trapFocus={false}>
      <Popover.Target>
        <Button variant="outline">{t('common:filter:label')}</Button>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="flex flex-col gap-4">
          <MultiSelect
            rightSection={(<TbChecks size={18} />)}
            variant="default"
            className="w-full"
            placeholder={t('invoice:filter:status') as string}
            nothingFoundMessage={t('common:input.not-found')}
            data={billingCycleStatusOptions}
            value={filter.status}
            onChange={setStatus}
            searchable
            comboboxProps={{ withinPortal: false }}
          />
          <DateRangeInput
            dateRange={filter.dateRange}
            setDateRange={setDateRange}
            placeholder={t('invoice:external-processor:sync-interval') as string}
          />
        </div>
        <div className="flex justify-between w-full mt-4">
          <Button variant="outline" onClick={handleClear}>{t('common:filter:clear')}</Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}
