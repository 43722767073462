import { useTranslation } from 'react-i18next'
import { ExternalProcessorSyncBillingCycleStatus } from '../enum'

const useInvoiceExternalProcessorStatusOptions = () => {
  const { t } = useTranslation('invoice')

  return Object.values(ExternalProcessorSyncBillingCycleStatus)
    .map((status) => ({
      label: t(`invoice:external-processor:status-label:${status}`),
      value: status,
    }))
    .filter(({ value }) => value !== ExternalProcessorSyncBillingCycleStatus.NotApplicable && value !== ExternalProcessorSyncBillingCycleStatus.NotInitiated)
}

export { useInvoiceExternalProcessorStatusOptions }
