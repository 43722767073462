import { TextInput, PasswordInput } from '@mantine/core'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiUser, BiAt, BiLock } from 'react-icons/bi'
import { PartialUpdateSelfUserAttributes } from '../../../interfaces'
import { emailValidationRegex } from '../../../shared/utils/regex'
import { MAX_TEXT_FIELD_LENGTH, MIN_PASSWORD_FIELD_LENGTH } from '../../../shared/utils/constants'

export default function AccountDetailForm() {
  const { t } = useTranslation(['common', 'user'])

  const {
    register,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<PartialUpdateSelfUserAttributes>()

  const password = watch('password')
  const passwordConfirmation = watch('passwordConfirmation')

  return (
    <div>
      <TextInput
        className="mb-4"
        leftSection={<BiUser className="text-violet-900" size="20px" />}
        placeholder={t('user:name') as string}
        error={errors.name && errors.name.message}
        {...register('name', {
          required: { value: true, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />

      <TextInput
        className="mb-4"
        leftSection={<BiAt className="text-violet-900" size="20px" />}
        placeholder={t('user:email') as string}
        error={errors.email && errors.email.message}
        {...register('email', {
          required: { value: true, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
          validate: {
            validEmail: (email) => emailValidationRegex.test(email) || t('common:form-error:invalid-email') as string,
          },
        })}
      />

      <PasswordInput
        className="mb-4"
        leftSection={<BiLock className="text-violet-900" size="20px" />}
        label={t('user:change-password-label') as string}
        placeholder={t('user:new-password-placeholder') as string}
        error={errors.password && errors.password.message}
        {...register('password', {
          required: { value: !!passwordConfirmation && passwordConfirmation.length > 0, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
          minLength: { value: MIN_PASSWORD_FIELD_LENGTH, message: t('user:form:error-message:password-min-length') },
          onBlur: () => trigger('passwordConfirmation'),
        })}
      />

      <PasswordInput
        leftSection={<BiLock className="text-violet-900" size="20px" />}
        placeholder={t('user:confirm-password-placeholder') as string}
        error={errors.passwordConfirmation && errors.passwordConfirmation.message}
        {...register('passwordConfirmation', {
          required: { value: !!password && password.length > 0, message: t('common:form-error:required-field') },
          validate: {
            matchPassowords: (currentPasswordConfirmationFieldValue) => currentPasswordConfirmationFieldValue === password
              || t('user:form:error-message:match-password') as string,
          },
          onBlur: () => trigger('password'),
        })}
      />
    </div>
  )
}
