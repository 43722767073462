import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DeleteCard from '../../../../shared/components/alert/delete-card'
import { useDeleteCustomer } from '../../queries'
import { Customer } from '../../../../models'
import { Path } from '../../../../shared/enums'

const useDeleteCustomerModal = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { mutate } = useDeleteCustomer()

  return (customer: Customer) => modals.open({
    withCloseButton: false,
    children: (
      <DeleteCard
        title={t('customer:delete-alert:title')}
        subtitle={t('customer:delete-alert:subtitle')}
        deletedItemName={t('customer:delete-alert:target-customer', {
          customer: customer.name,
        })}
        consequences={[
          { variant: 'danger', text: t('customer:delete-alert:consequences:delete-danger') },
          { variant: 'danger', text: t('customer:delete-alert:consequences:invoices-danger') },
        ]}
        onDelete={() => {
          mutate(customer.id, {
            onSuccess: () => {
              modals.closeAll()
              navigate(Path.Customers)
            },
          })
        }}
        onCancel={modals.closeAll}
      />
    ),
  })
}

export default useDeleteCustomerModal
