import { QueryFunctionContext, useQuery } from 'react-query'
import { QueryParams } from '../../interfaces'
import { Event } from '../../models'
import { EventFilter } from './interfaces/event-filter'
import eventService from './services/event-service'
import { useEventFilter } from './store/event-filter-store'
import { useEventSearchTerm } from './store/event-search-term-store'

const eventKeys = {
  all: [{ scope: 'events' }] as const,
  lists: () => [{ ...eventKeys.all[0], entity: 'list' }] as const,
  list: (filter: EventFilter, idempotencyKeySearch: string) => [{
    ...eventKeys.lists()[0], filter, idempotencyKeySearch,
  }] as const,
  sum: (filter: EventFilter, idempotencyKeySearch: string) => [{
    ...eventKeys.list(filter, idempotencyKeySearch)[0], entity: 'sum',
  }] as const,
}

export const useEventsQuery = <ModelSchema = Event[]>(params?: QueryParams<Event[], ModelSchema>) => {
  const eventsfilter = useEventFilter()
  const searchTerm = useEventSearchTerm()

  return useQuery({
    queryKey: eventKeys.list(eventsfilter, searchTerm),
    queryFn: async ({
      queryKey: [{ filter, idempotencyKeySearch }],
    }: QueryFunctionContext<ReturnType<typeof eventKeys['list']>>) => eventService.fetchEvents(filter, idempotencyKeySearch),
    select: params?.select,
  })
}

export const useEventsSumQuery = <ModelSchema = number>(params?: QueryParams<number, ModelSchema>) => {
  const eventsfilter = useEventFilter()
  const searchTerm = useEventSearchTerm()

  return useQuery({
    queryKey: eventKeys.sum(eventsfilter, searchTerm),
    queryFn: async ({
      queryKey: [{ filter, idempotencyKeySearch }],
    }: QueryFunctionContext<ReturnType<typeof eventKeys['sum']>>) => eventService.fetchEventsSum(filter, idempotencyKeySearch),
    select: params?.select,
  })
}

export default {
  invoiceKeys: eventKeys,
}
