import { Text } from '@mantine/core'
import { ReactNode } from 'react'

interface Props {
  title: string;
  value: string | ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string
}

export default function InfoItem({
  title, value, size = 'md', className,
}: Props) {
  return (
    <div className={className}>
      <Text size="sm" fw={500} className="text-grayscale-text-caption">
        {`${title}: `}
      </Text>
      {typeof value === 'string' ? (
        <Text
          size={size}
          fw={(size === 'lg' || size === 'xl') ? 600 : 500}
          className={
          (size === 'lg' || size === 'xl') ? 'text-primary-text-label' : 'text-grayscale-text-body'
        }
        >
          {value}
        </Text>
      ) : (
        <div className="flex flex-col mt-1">
          {value}
        </div>
      )}
    </div>
  )
}
