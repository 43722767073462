import { Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import useCurrentPage from '../../../../shared/hooks/use-current-page'
import Breadcrumbs from '../breadcrumbs'

export default function PageHeader() {
  const { t } = useTranslation('common')
  const { currentPage, currentPageParams } = useCurrentPage()

  return (
    <div className="w-full">
      {currentPage && (
        <div className="my-3">
          <Breadcrumbs currentPage={currentPage} currentPageParams={currentPageParams} />
        </div>
      )}

      {currentPage?.titleInternationalizationKey && <Title size="h4">{t(currentPage.titleInternationalizationKey)}</Title>}
    </div>
  )
}
