import {
  Card, Text,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { ExtendedInvoice } from '../../../../models'
import { formatPlanNames } from '../../../../shared/utils/helpers/format-plan-names'
import InvoiceDetailsHeaderMenu from './invoice-detail-header-menu'
import Typography from '../../../../shared/components/general/typography'

interface Props {
  invoice: ExtendedInvoice
  onManageItems: () => void;
}

export default function InvoiceDetailHeader({
  invoice, onManageItems,
}: Props) {
  const { t, i18n } = useTranslation()

  const formatImportantDates = () => {
    const endDateFormatted = new Date(invoice.endDate).toLocaleDateString(i18n.language, { timeZone: 'UTC' })
    return `${t('invoice:end-date-natural-language', { endDate: endDateFormatted })}`
  }

  const formatInvoicePlanNames = () => formatPlanNames(invoice.plans?.map((plan) => plan.name)) || `${t('invoice:invoice')}`

  return (
    <Card padding="lg" radius="md" className="bg-primary-surface-subtle gap-6 px-6 py-6">
      <div className="flex flex-col gap-2 w-full">
        <div className="border w-fit border-primary-border-default rounded-full px-4 py-1">
          <Text size="xs" fw={600} className="text-primary-text-label">
            {invoice.id}
          </Text>
        </div>
        <Typography fw={600} className="text-gray-text-title text-xl" truncate>
          {formatInvoicePlanNames()}
        </Typography>
        <Text size="sm" fw={500} className="text-gray-text-body">
          {formatImportantDates()}
        </Text>
      </div>
      <InvoiceDetailsHeaderMenu invoice={invoice} onManageItems={onManageItems} />
    </Card>
  )
}
