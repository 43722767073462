import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import { PaymentAccount } from '../../../../models'
import DeleteCard from '../../../../shared/components/alert/delete-card'
import { UseDeletePaymentAccount } from '../../queries'
import { useDeletePaymentAccountErrorAlert } from './delete-payment-account-error-alert'

const useDeletePaymentAccountHandler = (customerId: string) => {
  const { t } = useTranslation()

  const { mutate } = UseDeletePaymentAccount(customerId)

  const deletePaymentAccountErrorAlert = useDeletePaymentAccountErrorAlert()

  return (paymentAccount: PaymentAccount) => {
    modals.open({
      withCloseButton: false,
      children: (
        <DeleteCard
          title={t('customer:payment-account:delete-alert:title')}
          subtitle={t('customer:payment-account:delete-alert:subtitle')}
          deletedItemName={t('customer:payment-account:delete-alert:target-payment-account', {
            paymentAccount: paymentAccount.tradeName || paymentAccount.businessName,
          })}
          conditions={[t('customer:payment-account:delete-alert:conditions')]}
          consequences={[
            { variant: 'danger', text: t('customer:payment-account:delete-alert:consequences:delete-danger') },
            { variant: 'secure', text: t('customer:payment-account:delete-alert:consequences:invoices-check') },
          ]}
          onDelete={() => {
            mutate(paymentAccount.id, {
              onSuccess: () => modals.closeAll(),
              onError: () => {
                modals.closeAll()
                deletePaymentAccountErrorAlert()
              },
            })
          }}
          onCancel={() => modals.closeAll()}
        />
      ),
    })
  }
}

export default useDeletePaymentAccountHandler
