import TableInvoiceExternalProcessors from '../features/invoice/components/invoice-external-processors/table-invoice-external-processors'
import InvoiceExternalProcessorPageActions from '../features/invoice/components/invoice-external-processors/invoice-external-processor-page-actions'

export default function InvoiceExternalProcessors() {
  return (
    <div className="h-full w-full flex flex-col">
      <InvoiceExternalProcessorPageActions />
      <TableInvoiceExternalProcessors />
    </div>
  )
}
