import { MatchKey } from '../../../interfaces/queries/match-key'
import Resource from '../../../models/resource'

const partialMatchKeys : MatchKey<Resource>[] = ['name', 'externalId']
const fullMatchKeys : MatchKey<Resource>[] = []
const indexKey = 'id' as keyof Resource

const tableResourcesUtils = {
  partialMatchKeys,
  fullMatchKeys,
  indexKey,
}

export default tableResourcesUtils
