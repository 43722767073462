import { Text, Tooltip } from '@mantine/core'
import { AvatarInitials } from '../../../shared/components/user/avatar-initials'
import { useAuthUser } from '../../auth/store/auth-store'
import stringHelper from '../../../shared/utils/helpers/string-helper'

export default function SidebarUserInformation() {
  const user = useAuthUser()

  const { text: name, isTextLimited: isNameLimited } = stringHelper.limitTextDetailed(user.name, 13)

  return (
    <div className="w-full flex items-center">
      <div className="w-4/12 flex items-center">
        <AvatarInitials text={user.name} size="2rem" />
      </div>
      <div className="w-8/12">
        {isNameLimited ? (
          <Tooltip label={user.name}>
            <Text size="sm" title={name} className="w-full truncate text-gray-700">{name}</Text>
          </Tooltip>
        ) : (
          <Text size="sm" title={user.name} className="w-full truncate text-gray-700">{user.name}</Text>
        )}
      </div>
    </div>
  )
}
