import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import SearchInput from '../../../shared/components/form/search-input'
import { useCustomersSearchTerm, useCustomersSearchTermActions } from '../store/customer-search-term-store'
import CreateCustomerModal from './create-customer-modal'

export default function CustomersPageActions() {
  const { t } = useTranslation('customer')

  const [isCreateCustomerModalOpen, setIsCreateCustomerModalOpen] = useState(false)

  const { setSearchTerm } = useCustomersSearchTermActions()
  const searchTerm = useCustomersSearchTerm()

  return (
    <div className="flex flex-row justify-between">
      <CreateCustomerModal opened={isCreateCustomerModalOpen} onClose={() => setIsCreateCustomerModalOpen(false)} />
      <Button onClick={() => setIsCreateCustomerModalOpen(true)}>
        {t('create-customer')}
      </Button>
      <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
    </div>
  )
}
