import i18n from '../../../config/i18n'
import UpsertInvoiceAdditionalItemAttributes from '../../../interfaces/invoice/upsert-invoice-additional-item-attributes'
import { api } from '../../../libs'
import GetFiscalInvoiceFileRequest from '../../../libs/api/requests/invoices/get-fiscal-invoice-file-request'
import GetInvoiceRequest from '../../../libs/api/requests/invoices/get-invoice-request'
import { Invoice } from '../../../models'
import ExtendedInvoice from '../../../models/extended-invoice'
import { InvoiceExternalProcessor } from '../../../models/invoice-external-processor'
import fileHelper, { FileType } from '../../../shared/utils/helpers/file-helper'
import { FiscalInvoiceFile } from '../enum'
import { upsertInvoiceAdditionalItemAttributesToRequest } from '../utils/upsert-invoice-utils'

const fetchInvoices = async (): Promise<Invoice[]> => {
  const getInvoicesResponse = await api.invoices.getInvoices()
  return getInvoicesResponse.data
}

const fetchInvoice = async (getInvoiceAttributes: GetInvoiceRequest): Promise<ExtendedInvoice> => {
  const getInvoiceResponse = await api.invoices.getInvoice(getInvoiceAttributes)
  return getInvoiceResponse.data
}

const fetchInvoiceExternalProcessors = async (): Promise<InvoiceExternalProcessor[]> => {
  const getInvoiceExternalProcessorsResponse = await api.invoices.getInvoiceExternalProcessors()
  return getInvoiceExternalProcessorsResponse.data
}

const downloadInvoiceDocument = async (invoiceId: string) => {
  const getInvoiceDocumentResponse = await api.invoices.getDocument({ id: invoiceId })

  const fileName = `${i18n.t('invoice')}_${invoiceId}`

  fileHelper.saveFile(getInvoiceDocumentResponse?.data, fileName)
}

const updateInvoiceAdditionalItems = async (
  invoiceId: string,
  additionalItems: UpsertInvoiceAdditionalItemAttributes[],
  currentInvoiceAdditionalItems: UpsertInvoiceAdditionalItemAttributes[],
): Promise<ExtendedInvoice> => {
  const updateInvoiceAdditionalItemsRequest = upsertInvoiceAdditionalItemAttributesToRequest(invoiceId, additionalItems, currentInvoiceAdditionalItems)

  const updateInvoiceAdditionalItemsResponse = await api.invoices.updateAdditionalItems(updateInvoiceAdditionalItemsRequest)

  return updateInvoiceAdditionalItemsResponse?.data
}

const synchronizeInvoices = async (synchronizeInvoicesAttributes: string[]) => {
  await api.invoices.createExternalProcessorSync({ invoiceIds: synchronizeInvoicesAttributes })
}

const calculateInvoice = async (invoiceId: string) => {
  const calculateInvoiceResponse = await api.invoices.calculate({ id: invoiceId })

  return calculateInvoiceResponse?.data
}

const downloadFiscalInvoiceFile = async (getFiscalInvoiceFileAttributes: GetFiscalInvoiceFileRequest) => {
  const getInvoiceDocumentResponse = await api.invoices.getFiscalInvoiceFIle(getFiscalInvoiceFileAttributes)

  const fileName = `${i18n.t('invoice:fiscal-invoice')}_${getFiscalInvoiceFileAttributes.id}`
  const fileType = getFiscalInvoiceFileAttributes.fiscalInvoiceFile === FiscalInvoiceFile.Xml ? FileType.Xml : FileType.Pdf

  fileHelper.saveFile(getInvoiceDocumentResponse?.data, fileName, fileType)
}

const InvoicesService = {
  fetchInvoices,
  fetchInvoice,
  downloadInvoiceDocument,
  updateInvoiceAdditionalItems,
  synchronizeInvoices,
  fetchInvoiceExternalProcessors,
  calculateInvoice,
  downloadFiscalInvoiceFile,
}

export default InvoicesService
