import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import i18n from 'i18next'
import queryClient from '../../config/query-client'
import { Resource } from '../../models'
import resourceService from './services/resource-service'
import MutationParams from '../../interfaces/queries/mutation-params'
import { QueryParams } from '../../interfaces'
import { useResourceSearchTerm } from './store/resource-search-term-store'
import tableResourcesUtils from './utils/table-resource-utils'
import searchHelper from '../../shared/utils/helpers/search-helper'

const resourcesKeys = {
  all: [{ scope: 'resources' }] as const,
  lists: () => [{ ...resourcesKeys.all[0], entity: 'list' }] as const,
}

export const useResourcesQuery = <ModelSchema = Resource[]>(params?: QueryParams<Resource[], ModelSchema>) => useQuery({
  queryKey: resourcesKeys.lists(),
  queryFn: resourceService.fetchResources,
  select: params?.select,
})

export const useFilteredResourcesQuery = () => {
  const searchTerm = useResourceSearchTerm()
  const { partialMatchKeys, fullMatchKeys } = tableResourcesUtils

  return useResourcesQuery({ select: (queriedResources) => searchHelper.getFilteredDataBySearchTerm(queriedResources, partialMatchKeys, fullMatchKeys, searchTerm) })
}

export const useCreateResource = (params?: MutationParams<Resource>) => useMutation({
  mutationFn: resourceService.createResource,
  onSuccess: (newResource) => {
    queryClient.setQueryData<Resource[]>(resourcesKeys.lists(), (resources) => {
      if (!resources) return [newResource]

      return [newResource, ...resources]
    })

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(newResource)
  },
})

export const useUpdateResource = (params?: MutationParams<Resource>) => useMutation({
  mutationFn: resourceService.updateResource,
  onSuccess: (updatedResource) => {
    queryClient.setQueryData<Resource[]>(resourcesKeys.lists(), (resources) => {
      if (!resources) return []

      return resources.map((resource) => (resource.id === updatedResource.id ? updatedResource : resource))
    })
    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(updatedResource)
  },
})

export const useDeleteResource = (params?: MutationParams<void>) => useMutation({
  mutationFn: (id: string) => resourceService.deleteResource({ id }),
  onSuccess: (_data, id) => {
    queryClient.setQueryData<Resource[]>(resourcesKeys.lists(), (resources) => {
      if (!resources) return []

      return resources.filter((resource) => (resource.id !== id))
    })
    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess()
  },
  onError: params?.onError,
})

export default resourcesKeys
