import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { FormProvider, useForm } from 'react-hook-form'
import { ErrorResponse, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import Modal from '../../../shared/components/general/modal'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import { UseCreateContract } from '../queries'
import ContractForm from './contract-form'
import { useAuthTenant } from '../../auth/store/auth-store'
import useMutateContractErrorModal from './modal-actions/mutate-contract-error-modal'

interface Props {
  opened: boolean;
  onClose: () => void;
}

export default function CreateContractModal({ opened, onClose }: Props) {
  const { t } = useTranslation('customer')
  const { id: customerId } = useParams()

  const tenant = useAuthTenant()
  const isOmieEnabled = tenant.externalServices.omie.enabled

  const useFormMethods = useForm<UpsertContractAttributes>({
    mode: 'onTouched',
    defaultValues: {
      startDate: new Date(),
    },
  })

  const {
    handleSubmit, reset, formState: { isValid },
  } = useFormMethods

  const mutateContractErrorModal = useMutateContractErrorModal()

  const handleCreateContractSuccess = () => {
    onClose()
    reset()
  }

  const handleCreateContractError = (error: AxiosError<ErrorResponse | any>) => {
    mutateContractErrorModal({ error, onOverrideOmieSync: handleSubmit(handleOverrideOmieSync), onRetry: handleSubmit(handleCreateContract) })
  }

  const { mutate, isLoading } = UseCreateContract({
    onSuccess: handleCreateContractSuccess,
    onError: isOmieEnabled ? handleCreateContractError : undefined,
  })

  const handleCreateContract = (upsertContractAttributes: UpsertContractAttributes) => {
    const newContract = {
      ...upsertContractAttributes,
      customerId: customerId!,
    }
    mutate(newContract)
  }

  const handleOverrideOmieSync = (upsertContractAttributes: UpsertContractAttributes) => {
    const newContract = {
      ...upsertContractAttributes,
      omieDetails: {
        ...upsertContractAttributes.omieDetails,
        overrideOmieSync: true,
      },
      customerId: customerId!,
    }
    mutate(newContract)
  }

  return (
    <Modal size="xl" opened={opened} onClose={onClose} title={t('customer:contract:add-contract')}>
      <FormProvider {...useFormMethods}>
        <div className="flex flex-col">
          <div className="w-[calc(100%-20px)]">
            <form
              onSubmit={handleSubmit(handleCreateContract)}
              className="flex flex-col w-full"
            >
              <ContractForm />

              <div className="w-full h-15 bg-white bottom-0 -mb-4 sticky">
                <Button
                  className="w-50 mt-3"
                  color="violet"
                  type="submit"
                  disabled={!isValid}
                  loading={isLoading}
                >
                  {t('common:save')}
                </Button>
              </div>

            </form>
          </div>
        </div>
      </FormProvider>
    </Modal>
  )
}
