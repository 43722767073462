import { t } from 'i18next'

export const formatPlanNames = (planNames: string[]) => {
  if (planNames.length === 0) {
    return `${t('customer:contract:no-plans-contract')}`
  }
  if (planNames.length <= 2) {
    return planNames.join(', ')
  }
  const firstThreePlansNames = planNames.slice(0, 2).join(', ')
  const remainingCount = planNames.length - 2
  return `${t('customer:contract:plans', { plans: firstThreePlansNames, count: remainingCount })}`
}
