import { useTranslation } from 'react-i18next'
import {
  Anchor, Button, Text, Title,
} from '@mantine/core'
import resetErrorBoundary from '../helpers/reset-error-bondary'
import { images } from '../../../shared/utils/public'

export default function ErrorFallback() {
  const { t } = useTranslation(['common', 'resource'])

  const handleResetErrorBoundary = () => {
    resetErrorBoundary()
  }

  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      <div className="flex flex-col">
        <Title order={3} className="font-bold flex justify-center">{t('common:error-page:title')}</Title>
        <img
          className="mb-4 h-100 mt-[-30px]"
          src={images.errorDesign.src}
          alt={images.errorDesign.alt}
        />
        <Button
          className="mt-4 mb-2"
          type="button"
          color="violet"
          variant="outline"
          onClick={handleResetErrorBoundary}
        >
          {t('common:error-page:button')}
        </Button>
        <div className="flex justify-center">
          <Text className="text-black mr-1">
            {t('common:error-page:subtitle')}
          </Text>
          <Anchor href="https://api.whatsapp.com/send?phone=5531995901901" target="_blank" className="text-primary">
            {t('common:error-page:subtitle-span')}
          </Anchor>
        </div>
      </div>
    </div>
  )
}
