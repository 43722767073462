const dateInputFormatter = (input: EventTarget & HTMLInputElement) => {
  const rawValue = input.value.replace(/\D/g, '')
  let formattedValue = ''

  if (rawValue.length > 0) formattedValue += rawValue.substring(0, 2)
  if (rawValue.length > 2) formattedValue += `/${rawValue.substring(2, 4)}`
  if (rawValue.length > 4) formattedValue += `/${rawValue.substring(4, 8)}`

  return formattedValue
}

export default dateInputFormatter
