import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { ExtendedContract } from '../../models'
import contractsService from './services/contracts-services'
import { QueryParams } from '../../interfaces'
import MutationParams from '../../interfaces/queries/mutation-params'
import queryClient from '../../config/query-client'
import i18n from '../../config/i18n'

const contractsKeys = {
  all: [{ scope: 'contracts' }] as const,
  lists: () => [{ ...contractsKeys.all[0], entity: 'list' }] as const,
  list: (customerId: string) => [...contractsKeys.lists(), { customerId }] as const,
  single: (id: string) => [{ ...contractsKeys.all[0], entity: 'single', id }] as const,
}

export const UseContractsQuery = <ModelSchema = ExtendedContract[]>(customerId?: string, params?: QueryParams<ExtendedContract[], ModelSchema>) => useQuery({
  queryKey: contractsKeys.list(customerId!),
  queryFn: () => contractsService.fetchContracts(customerId!),
  select: params?.select,
  enabled: !!customerId,
})

export const UseCreateContract = (params?: MutationParams<ExtendedContract>) => useMutation({
  mutationFn: contractsService.createContract,
  onSuccess: (newContract) => {
    queryClient.setQueryData<ExtendedContract[]>(contractsKeys.list(newContract.customerId), (contracts) => {
      if (!contracts) return [newContract]

      return [newContract, ...contracts]
    })

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(newContract)
  },
  onError: params?.onError,
})

export const UseUpdateContract = (params?: MutationParams<ExtendedContract>) => useMutation({
  mutationFn: contractsService.updateContract,
  onSuccess: (updatedContract) => {
    queryClient.setQueryData<ExtendedContract[]>(contractsKeys.list(updatedContract.customerId), (contracts) => {
      if (!contracts) return []

      return contracts.map((contract) => (contract.id === updatedContract.id ? updatedContract : contract))
    })

    queryClient.setQueryData<ExtendedContract>(contractsKeys.single(updatedContract.id), updatedContract)

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(updatedContract)
  },
  onError: params?.onError,
})

export const UseCancelContract = (customerId?: string, params?: MutationParams<void>) => useMutation({
  mutationFn: (id: string) => contractsService.cancelContract(id, customerId!),
  onSuccess: (cancelledContract) => {
    queryClient.setQueryData<ExtendedContract[]>(contractsKeys.list(cancelledContract.customerId), (contracts) => {
      if (!contracts) return []

      return contracts.map((contract) => (contract.id === cancelledContract.id ? cancelledContract : contract))
    })
    queryClient.setQueryData<ExtendedContract>(contractsKeys.single(cancelledContract.id), cancelledContract)

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess()
  },
  onError: params?.onError,
})
