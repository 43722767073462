import axiosInstance from '../config/axios'
import { CreateProductRequest } from '../requests/products'
import { CreateProductResponse, GetProductsResponse } from '../responses/products'

const PRODUCTS_BASE_URL = '/products'

const getProducts = async () => (axiosInstance.get<GetProductsResponse>(PRODUCTS_BASE_URL))

const createProduct = async (request: CreateProductRequest) => (axiosInstance.post<CreateProductResponse>(PRODUCTS_BASE_URL, request))

export default {
  getProducts,
  createProduct,
}
