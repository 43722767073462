const limitText = (text: string, limit: number): string => {
  if (text.length <= limit) {
    return text
  }
  const truncatedText: string = text.slice(0, limit - 3)
  return `${truncatedText}...`
}

const limitTextDetailed = (text: string, limit: number): { text: string, isTextLimited: boolean } => {
  if (text.length <= limit) {
    return { text, isTextLimited: false }
  }
  const truncatedText: string = text.slice(0, limit - 3)
  return { text: `${truncatedText}...`, isTextLimited: true }
}

const stringHelper = {
  limitText,
  limitTextDetailed,
}

export default stringHelper
