/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { modals } from '@mantine/modals'
import { usePlanQuery, useUpdatePlan } from '../queries/plan-queries'
import { Path } from '../../../shared/enums'
import PlanFormContainer from './plan-form-container'
import PlanSummaryContainer from './plan-summary-container'
import checkDeepEquality from '../../../shared/utils/helpers/check-deep-equality'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import UpdatePlanFormSkeleton from './update-plan-form-skeleton'
import { upsertExtendedPlanAttributesToUpdatedPlan } from '../utils/upsert-plan-utils'
import useUpdatePlanAlert from './modal-actions/update-plan-alert'

export default function UpdatePlanForm() {
  const navigate = useNavigate()

  const { id } = useParams()

  const [currentPlan, setCurrentPlan] = useState<UpsertExtendedPlanAttributes | null>(null)

  const { data: updatePlan, isLoading: isQueryLoading } = usePlanQuery(id)

  const { mutate, isLoading: isMutateLoading } = useUpdatePlan()

  const useFormMethods = useForm<UpsertExtendedPlanAttributes>({
    mode: 'onTouched',
  })

  const {
    handleSubmit, reset, watch, formState: { isDirty, isValid },
  } = useFormMethods

  const formValues = watch()

  const updatePlanAlert = useUpdatePlanAlert()

  const handleUpdatePlanSuccess = () => {
    modals.closeAll()
    navigate(Path.Plans)
    reset()
  }

  const handleCancel = () => {
    navigate(Path.Plans)
    reset()
  }

  const handleSubmitUpdatePlanForm = (updatePlanAttributes: UpsertExtendedPlanAttributes) => {
    const oldMetrics = currentPlan?.metrics || []
    const newMetrics = [...(updatePlanAttributes.metrics || [])]
    newMetrics.forEach((newMetric) => {
      const correspondingOldMetric = oldMetrics.find((oldMetric) => oldMetric.id === newMetric.id)
      if (!correspondingOldMetric || !checkDeepEquality(correspondingOldMetric, newMetric)) {
        // eslint-disable-next-line no-param-reassign
        newMetric.id = undefined
      }
    })

    const planToBeUpdated = upsertExtendedPlanAttributesToUpdatedPlan({ ...updatePlanAttributes, metrics: newMetrics })
    updatePlanAlert({
      onContinue: () => {
        if (planToBeUpdated) {
          mutate(planToBeUpdated, { onSuccess: handleUpdatePlanSuccess })
        }
      },
    })
  }

  useEffect(() => {
    if (updatePlan && !isDirty) {
      reset(updatePlan)
      setCurrentPlan(updatePlan)
    }
  }, [updatePlan, reset, isDirty])

  if (isQueryLoading) {
    return (
      <UpdatePlanFormSkeleton />
    )
  }

  return (
    <FormProvider {...useFormMethods}>
      <div className="flex flex-row gap-4">
        <form
          id="plan-form"
          onSubmit={
           handleSubmit(
             (updatePlanAttributes: UpsertExtendedPlanAttributes) => handleSubmitUpdatePlanForm(updatePlanAttributes),
           )
         }
          onKeyDown={(e:React.KeyboardEvent<HTMLFormElement>) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
          className="flex flex-col w-full "
        >
          <PlanFormContainer isValid={isValid} isLoading={isMutateLoading} handleCancel={handleCancel} />
        </form>

        <PlanSummaryContainer isValid={isValid} formValues={formValues} isLoading={isMutateLoading} handleCancel={handleCancel} />
      </div>
    </FormProvider>
  )
}
