import { Text } from '@mantine/core'

interface Props {
  label: string
  info: string
}

export default function MetricSummaryInfo({ label, info }: Props) {
  return (
    <div className="flex flex-row w-full justify-between">
      <Text fz="sm" fw={400} className="text-gray-600">
        {label}
      </Text>
      <Text fz="sm" fw={500}>
        {info}
      </Text>
    </div>
  )
}
