import { BiLockAlt, BiLockOpenAlt } from 'react-icons/bi'
import { Button } from '@mantine/core'

interface Props {
  isLocked: boolean
  setIsLocked: (value: boolean) => void
}

export default function LockButton({ isLocked, setIsLocked }: Props) {
  return (
    <Button
      variant="outline"
      color="violet"
      className="w-5 min-w-0 mt-6"
      onClick={() => setIsLocked(!isLocked)}
    >
      {isLocked ? <BiLockAlt size={20} /> : <BiLockOpenAlt size={20} />}
    </Button>

  )
}
