import { Badge } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Group } from '../../../../shared/enums'

interface Props {
  value: any;
}

export function TablePermissionCell({ value }: Props) {
  const { t } = useTranslation(['user'])

  return (
    <Badge
      color="violet"
      variant="light"
    >
      {
        value.includes(Group.Admin) ? t('user:permission-name:admin') : t('user:permission-name:external-customer')
      }
    </Badge>
  )
}
