import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import BillingModelPriceTierDivisionInfo from './billing-model-price-tier-division-info'
import { sumPriceTiersUsage } from '../../../utils/sum-price-tiers-usage'
import formatToCurrency from '../../../../../shared/utils/helpers/format-to-currency'
import { InvoiceMetric } from '../../../../../models/invoice-metric'
import { BillingModel } from '../../../../metric/enum'

interface Props {
  metric: InvoiceMetric;
}

export default function InvoiceMetricItem({ metric }: Props) {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  return (
    <>
      <BillingModelPriceTierDivisionInfo metric={metric} />
      <div className="flex justify-between">
        <div>
          <Text size="sm">{metric.name}</Text>
          {metric.billingModel === BillingModel.InFull && (
          <Text size="xs" className="text-grayscale-text-subtitle">
            {`${t('invoice:price-tier-item:item-total-usage')} ${sumPriceTiersUsage(metric)}`}
          </Text>
          )}
        </div>
        <Text size="sm" className="mt-2 mr-2">{formatToCurrency({ price: metric.totalAmount, language })}</Text>
      </div>
    </>
  )
}
