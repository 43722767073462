import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { UpsertUserAttributes } from '../../../interfaces'
import { useCreateUser } from '../queries'
import UserForm from './user-form'
import Modal from '../../../shared/components/general/modal'
import { Group } from '../../../shared/enums'

interface Props {
  opened: boolean;
  onClose: () => void;
}

export default function CreateUserModal({ opened, onClose }: Props) {
  const { t } = useTranslation(['common', 'user'])

  const useFormMethods = useForm<UpsertUserAttributes>({
    mode: 'onTouched',
    defaultValues: {
      group: Group.Admin,
    },
  })

  const {
    handleSubmit, reset, formState: { isValid },
  } = useFormMethods

  const handleCreateUserSuccess = () => {
    onClose()
    reset()
  }

  const { mutate, isLoading } = useCreateUser({ onSuccess: handleCreateUserSuccess })

  return (
    <Modal opened={opened} onClose={onClose} title={t('user:create-user')}>
      <div className="px-2">
        <FormProvider {...useFormMethods}>
          <form
            onSubmit={handleSubmit((createUserAttributes: UpsertUserAttributes) => mutate(createUserAttributes))}
            className="flex flex-col"
          >
            <UserForm />

            <Button
              fullWidth
              className="mt-7 w-50"
              color="violet"
              type="submit"
              disabled={!isValid}
              loading={isLoading}
            >
              {t('common:save')}
            </Button>
          </form>
        </FormProvider>
      </div>
    </Modal>
  )
}
