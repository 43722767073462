import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import formatToCurrency from '../../../../shared/utils/helpers/format-to-currency'
import { BillingCycleStatus } from '../../enum'

interface Props {
  value: any;
}

export function TableInvoiceAmountCell({ value }: Props) {
  const { i18n } = useTranslation()
  const { language } = i18n

  const displayValue = formatToCurrency({ price: value.amount, language })

  switch (value.status) {
    case BillingCycleStatus.Open:
      return (
        <div className="text-gray-500">
          <Text td="underline">
            {displayValue}
          </Text>
        </div>
      )
    default:
      return (
        <div className="truncate">
          <Text>
            {displayValue}
          </Text>
        </div>
      )
  }
}
