import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import i18n from '../../config/i18n'
import queryClient from '../../config/query-client'
import { QueryParams } from '../../interfaces'
import MutationParams from '../../interfaces/queries/mutation-params'
import searchHelper from '../../shared/utils/helpers/search-helper'
import { useCustomersSearchTerm } from './store/customer-search-term-store'
import tableCustomersUtils from './utils/table-customers-utils'
import customerService from './services/customer-service'
import { Customer } from '../../models'

const customersKeys = {
  all: [{ scope: 'customers' }] as const,
  lists: () => [{ ...customersKeys.all[0], entity: 'list' }] as const,
  single: (id: string) => [{ ...customersKeys.all[0], entity: 'single', id }] as const,
}

export const useCustomersQuery = <ModelSchema = Customer[]>(params?: QueryParams<Customer[], ModelSchema>) => useQuery({
  queryKey: customersKeys.lists(),
  queryFn: customerService.fetchCustomers,
  select: params?.select,
})

export const useFilteredCustomersQuery = () => {
  const searchTerm = useCustomersSearchTerm()
  const { partialMatchKeys, fullMatchKeys } = tableCustomersUtils

  return useCustomersQuery({ select: (queriedResources) => searchHelper.getFilteredDataBySearchTerm(queriedResources, partialMatchKeys, fullMatchKeys, searchTerm) })
}

export const useCustomerQuery = (id?: string) => useQuery({
  queryKey: customersKeys.single(id!),
  queryFn: () => customerService.fetchCustomer(id!),
  enabled: !!id,
})

export const useCreateCustomer = (params?: MutationParams<Customer>) => useMutation({
  mutationFn: customerService.createCustomer,
  onSuccess: (newCustomer) => {
    queryClient.setQueryData<Customer[]>(customersKeys.lists(), (customers) => {
      if (!customers) return [newCustomer]

      return [newCustomer, ...customers]
    })

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(newCustomer)
  },
})

export const useUpdateCustomer = (params?: MutationParams<Customer>) => useMutation({
  mutationFn: customerService.updateCustomer,
  onSuccess: (updatedCustomer) => {
    queryClient.setQueryData<Customer[]>(customersKeys.lists(), (customers) => {
      if (!customers) return []

      return customers.map((customer) => (customer.id === updatedCustomer.id ? updatedCustomer : customer))
    })

    queryClient.setQueryData<Customer>(customersKeys.single(updatedCustomer.id), updatedCustomer)

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(updatedCustomer)
  },
})

export const useDeleteCustomer = (params?: MutationParams<void>) => useMutation({
  mutationFn: (id: string) => customerService.deleteCustomer({ id }),
  onSuccess: (_data, id) => {
    queryClient.setQueryData<Customer[]>(customersKeys.lists(), (customers) => {
      if (!customers) return []

      return customers.filter((customer) => (customer.id !== id))
    })
    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess()
  },
})
