import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import CreatePaymentAccountModal from './create-payment-account-modal'
import PaymentAccountsList from './payment-accounts-list'
import { UsePaymentAccountsQuery } from '../queries'
import PaymentAccountsListSkeleton from './payment-accounts-list-skeletion'

export default function PaymentAccountTab() {
  const { t } = useTranslation('customer')
  const { id: customerId } = useParams()

  const {
    data: paymentAccounts,
    isLoading,
  } = UsePaymentAccountsQuery(customerId)

  const [isCreatePaymentAccountModalOpen, setIsCreatePaymentAccountModalOpen] = useState<boolean>(false)

  return (
    <div>
      <CreatePaymentAccountModal
        opened={isCreatePaymentAccountModalOpen}
        onClose={() => setIsCreatePaymentAccountModalOpen(false)}
      />

      <Button
        className="mt-2"
        variant="outline"
        onClick={() => setIsCreatePaymentAccountModalOpen(true)}
      >
        {t('customer:payment-account:add-payment-account')}
      </Button>

      <div className="mt-4">
        {isLoading ? (
          <PaymentAccountsListSkeleton />)
          : (<PaymentAccountsList paymentAccounts={paymentAccounts || []} />
          )}
      </div>
    </div>
  )
}
