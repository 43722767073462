import { useTranslation } from 'react-i18next'
import { InvoiceExternalProcessor } from '../../../../models/invoice-external-processor'
import { TableColumn } from '../../../../interfaces'
import { CoreTable, TableDateTimeCell } from '../../../../shared/components/table'
import { useFilteredInvoiceExternalProcessorsQuery } from '../../queries'
import tableInvoiceExternalProcessorUtils from '../../utils/table-invoice-external-processor-utils'
import { TableInvoiceExternalProcessorStatus } from './table-invoice-external-processor-status'
import { TableInvoiceExternalProcessorMessage } from './table-invoice-external-processor-message'

export default function TableInvoiceExternalProcessors() {
  const { t } = useTranslation()

  const {
    data: invoiceExternalProcessors,
    isLoading,
    isFetching,
  } = useFilteredInvoiceExternalProcessorsQuery()

  const tableInvoiceExternalProcessorColumns: TableColumn<InvoiceExternalProcessor>[] = [
    {
      label: t('invoice:external-processor:billing-id'),
      key: 'invoiceId',
      columnKey: 'invoiceId',
      widthPercentage: 10,
    },
    {
      label: t('invoice:status'),
      key: 'status',
      columnKey: 'status',
      widthPercentage: 20,
      Component: TableInvoiceExternalProcessorStatus,
    },
    {
      label: t('invoice:external-processor:sync-date'),
      key: 'lastProcessedAt',
      columnKey: 'lastProcessedAt',
      widthPercentage: 20,
      Component: TableDateTimeCell,
    },
    {
      label: t('invoice:external-processor:processor-id'),
      key: 'displayId',
      columnKey: 'displayId',
      widthPercentage: 10,
    },
    {
      label: t('common:error'),
      key: 'message',
      columnKey: 'message',
      widthPercentage: 30,
      Component: TableInvoiceExternalProcessorMessage,
    },
  ]

  return (
    <CoreTable<InvoiceExternalProcessor>
      data={invoiceExternalProcessors || []}
      indexKey={tableInvoiceExternalProcessorUtils.indexKey}
      columns={tableInvoiceExternalProcessorColumns}
      isFetching={isFetching}
      isLoading={isLoading}
      pageConfiguration={{
        pageVariation: 1,
      }}
    />
  )
}
