import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import TableProrationView from '../invoice-detail/proration-table/table-proration-usage-view'
import { InvoiceMetricProration } from '../../../../models/invoice-metric'

export const useProrationUsageViewModal = () => {
  const { t } = useTranslation('invoice')

  return (metric: InvoiceMetricProration) => modals.open({
    withCloseButton: true,
    title: t('invoice:proration-usage:metric-detail', {
      METRIC: metric.name,
    }),
    children: (
      <TableProrationView
        metric={metric}
      />
    ),
    size: 'lg',
  })
}
