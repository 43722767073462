const images = {
  airaLogo: {
    src: '/img/aira-logo.svg',
    alt: 'Aira Blue Logo',
  },
  loginDesign: {
    src: '/img/login-design.svg',
    alt: 'Login Design',
  },
  errorDesign: {
    src: '/img/error-design.svg',
    alt: 'Error Design',
  },
  emptyBox: {
    src: '/img/empty-box.svg',
    alt: 'Empty Box',
  },
  airaLogoIcon: {
    src: '/img/aira-logo-icon.svg',
    alt: 'Aira Logo Icon',
  },
}

const urls = {
  airaLandingPage: 'https://www.useaira.com/',
}

export {
  images,
  urls,
}
