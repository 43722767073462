/* eslint-disable no-nested-ternary */
import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import formatToCurrency from '../../../../../shared/utils/helpers/format-to-currency'
import InvoicePriceTierItemContent from './invoice-price-tier-item-content'
import { InvoiceMetric } from '../../../../../models/invoice-metric'
import { BillingModel, PriceTierDivision } from '../../../../metric/enum'
import InvoicePriceTierItem from './invoice-price-tier-item'

interface Props {
  metric: InvoiceMetric
}

export default function InvoicePriceTierItems({ metric }: Props) {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  return (
    <div>
      <div className="mb-6">
        <InvoicePriceTierItemContent
          left={(
            <Text size="sm">
              {t('invoice:price-tier-item:fixed-value')}
            </Text>
            )}
          right={formatToCurrency({ price: metric.fixedAmount, language })}
        />
      </div>
      {(metric.priceTierDivision === PriceTierDivision.UniqueTier && metric.billingModel === BillingModel.InFull)
        ? (
          metric.priceTiers.some((priceTier) => priceTier.usage !== 0)
            ? metric.priceTiers
              .filter((priceTier) => priceTier.usage !== 0)
              .map((priceTier) => (
                <InvoicePriceTierItem priceTier={priceTier} key={priceTier.id} />
              ))
            : <InvoicePriceTierItem priceTier={metric.priceTiers[0]} key={metric.priceTiers[0].id} />
        )
        : (metric.priceTiers.map((priceTier) => (
          <InvoicePriceTierItem priceTier={priceTier} key={priceTier.id} />
        )))}
    </div>
  )
}
