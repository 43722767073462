import i18n from '../../../config/i18n'

interface Props {
  value: any;
}

export function TableDateTimeCell({ value }: Props) {
  const date = new Date(value)

  return (
    <div className="truncate">
      {value && date.toLocaleString(i18n.language) }
    </div>
  )
}
