import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import ResourceForm from './resource-form'
import { CreateResourceAttributes } from '../../../interfaces'
import { useCreateResource } from '../queries'
import Modal from '../../../shared/components/general/modal'

interface Props {
  opened: boolean;
  onClose: () => void;
}

export default function CreateResourceModal({ opened, onClose }: Props) {
  const { t } = useTranslation(['common', 'resource'])

  const useFormMethods = useForm<CreateResourceAttributes>({
    mode: 'onTouched',
  })

  const {
    handleSubmit, reset, formState: { isValid },
  } = useFormMethods

  const handleCreateResourceSuccess = () => {
    onClose()
    reset()
  }

  const { mutate, isLoading } = useCreateResource({ onSuccess: handleCreateResourceSuccess })

  return (
    <Modal opened={opened} onClose={onClose} title={t('resource:create-resource')}>
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit((createResourceAttributes: CreateResourceAttributes) => mutate(createResourceAttributes))}
          className="flex flex-col px-2"
        >
          <ResourceForm />
          <Button
            className="mt-7 w-50"
            color="violet"
            type="submit"
            disabled={!isValid}
            loading={isLoading}
          >
            {t('common:save')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  )
}
