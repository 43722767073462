import {
  Accordion, Divider, Card, Text, Badge,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import formatToCurrency from '../../../../../shared/utils/helpers/format-to-currency'
import UpsertInvoiceAdditionalItemAttributes from '../../../../../interfaces/invoice/upsert-invoice-additional-item-attributes'

interface Props {
  additionalItem: UpsertInvoiceAdditionalItemAttributes;
  lastAdditionalItemsIndex: number;
  currentAdditionalItemIndex: number;
}

export default function InvoiceAdditionalItemDetailAccordion({ additionalItem, currentAdditionalItemIndex, lastAdditionalItemsIndex }: Props) {
  const { i18n, t } = useTranslation()
  const { language } = i18n

  const accordionStyles = {
    item: {
      '&[dataActive]': {
        backgroundColor: 'var(--mantine-color-body)',
      },
    },
  }

  return (
    <Accordion variant="filled" styles={accordionStyles} className="-mx-5 -mt-1">
      <Accordion.Item
        key={additionalItem.id || additionalItem.renderId}
        value={additionalItem.id || additionalItem.renderId}
      >
        <Accordion.Control>
          <div className="flex justify-between">
            <div className="flex gap-2">
              <Badge
                className="mt-[2px] normal-case"
                color="violet"
                variant="light"
              >
                {additionalItem.type === 'discount' ? `${t('invoice:item:discount')}` : `${t('invoice:item:charge')}`}
              </Badge>
              <Text size="sm">{additionalItem.name}</Text>
            </div>
            <Text size="sm">{formatToCurrency({ price: additionalItem.amount, language })}</Text>
          </div>
        </Accordion.Control>

        <Accordion.Panel>
          <div className="flex justify-between">
            <Card shadow="none" className="bg-gray-50 w-full">
              <Text size="sm" className="text-gray-700">
                {additionalItem.description || t('invoice:item:empty-description')}
              </Text>
            </Card>
          </div>
        </Accordion.Panel>

        { currentAdditionalItemIndex !== lastAdditionalItemsIndex
          && <Divider className="mt-2 mx-4" />}

      </Accordion.Item>
    </Accordion>
  )
}
