import { TableUser, UserPageActions } from '../features/user/components'

export default function Users() {
  return (
    <div className="h-full w-full flex flex-col">
      <UserPageActions />
      <TableUser />
    </div>
  )
}
