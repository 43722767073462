import { create } from 'zustand'

interface InvoiceSearchTermState {
  searchTerm: string
  actions: {
    setSearchTerm: (searchTerm: string) => void
  }
}

const useInvoiceSearchTermStore = create<InvoiceSearchTermState>((set) => ({
  searchTerm: '',
  actions: {
    setSearchTerm: (searchTerm: string) => set({ searchTerm }),
  },
}))

export const useInvoiceSearchTerm = () => useInvoiceSearchTermStore((state) => state.searchTerm)
export const useInvoiceSearchTermActions = () => useInvoiceSearchTermStore((state) => state.actions)
