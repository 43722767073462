import { Accordion, Text } from '@mantine/core'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { HiChevronDown } from 'react-icons/hi'
import { FaRegCircleCheck } from 'react-icons/fa6'

interface Props {
  value: string
  isSectionComplete: boolean
  sectionTitle: string
  children: React.ReactNode
}

export default function CollapseFormSection({
  value, isSectionComplete, sectionTitle, children,
}: Props) {
  const color = isSectionComplete ? '#2F9E44' : '#868E96'
  const textColorClass = isSectionComplete ? 'text-green-800' : 'text-gray-600'

  return (
    <Accordion.Item value={value}>
      <Accordion.Control
        chevron={<HiChevronDown size={12} color={color} />}
      >
        <div className="flex gap-2 items-center">

          {isSectionComplete
            ? <FaRegCircleCheck size={18} color={color} />
            : <AiOutlineExclamationCircle size={20} color={color} />}

          <Text fw={600} className={textColorClass}>
            {sectionTitle}
          </Text>

        </div>
      </Accordion.Control>

      <Accordion.Panel>
        {children}
      </Accordion.Panel>

    </Accordion.Item>
  )
}
