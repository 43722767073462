import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthIsUserAuthenticated } from '../../../auth/store/auth-store'
import notFoundPageUtils from './utils/not-found-page-utils'

export default function NotFoundPage() {
  const navigate = useNavigate()
  const isUserAuthenticated = useAuthIsUserAuthenticated()

  const defaultPath = notFoundPageUtils.getDefaultPath(isUserAuthenticated)

  useEffect(() => {
    navigate(defaultPath)
  }, [navigate, defaultPath])

  return (
    <div />
  )
}
