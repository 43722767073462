import { Badge, BadgeProps } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { PaymentStatus } from '../enum/payment-status'

interface Props {
  status: PaymentStatus | null;
  paymentDueDate: Date | null;
}

export function PaymentStatusBadge({ status, paymentDueDate }: Props) {
  const { t } = useTranslation(['invoice'])

  const getBadgeProps = (): BadgeProps => {
    const baseProps: BadgeProps = {
      className: 'p-3 flex w-fit',
      variant: 'outline',
    }

    if (!status || !paymentDueDate) {
      return {
        ...baseProps, bg: 'white', color: 'gray.5', children: t('invoice:payment-status-label:not-initiated'),
      }
    }

    const isDelayed = dayjs(paymentDueDate).isBefore(dayjs(), 'day')

    const statusConfig: Record<PaymentStatus, BadgeProps> = {
      [PaymentStatus.Paid]: { bg: 'green.0', color: 'green', children: t('invoice:payment-status-label:paid') },
      [PaymentStatus.Expired]: { bg: 'white', color: 'gray.5', children: t('invoice:payment-status-label:expired') },
      [PaymentStatus.Created]: { bg: 'white', color: 'gray.5', children: t('invoice:payment-status-label:not-initiated') },
      [PaymentStatus.Failed]: { bg: 'red.0', color: 'red', children: t('invoice:payment-status-label:failed') },
      [PaymentStatus.Canceled]: { bg: 'white', color: 'gray.5', children: t('invoice:payment-status-label:canceled') },
      [PaymentStatus.Pending]: isDelayed
        ? { bg: 'red.0', color: 'red', children: t('invoice:payment-status-label:delayed') }
        : { bg: 'orange.0', color: 'orange', children: t('invoice:payment-status-label:pending') },
    }

    return { ...baseProps, ...statusConfig[status] }
  }

  const badgeProps = getBadgeProps()

  return <Badge {...badgeProps} />
}
