import { useNavigate } from 'react-router-dom'
import { Path } from '../../../shared/enums'
import { TableArrowCell } from '../../../shared/components/table/table-arrow-cell'
import { Customer } from '../../../models'

interface Props {
  rowData: Customer;
}

export function TableCustomerArrowCell({ rowData }: Props) {
  const navigate = useNavigate()

  const navigateToCustomerDetails = async (customer: Customer) => {
    navigate(Path.CustomerDetails.replace(':id', customer.id))
  }

  return (
    <div className="cursor-pointer flex justify-end">
      <TableArrowCell rowData={rowData} action={navigateToCustomerDetails} />
    </div>
  )
}
