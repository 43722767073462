import { MatchKey } from '../../../interfaces/queries/match-key'
import ExtendedUser from '../../../models/extended-user'

const partialMatchKeys : MatchKey<ExtendedUser>[] = ['name', 'email']
const fullMatchKeys : MatchKey<ExtendedUser>[] = ['id']
const indexKey = 'id' as keyof ExtendedUser

const tableUserUtils = {
  partialMatchKeys,
  fullMatchKeys,
  indexKey,
}

export default tableUserUtils
