import {
  Button,
  Popover,
  Select,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useCustomersQuery } from '../../customer/queries'
import buildCustomerOptions from '../../customer/utils/build-customer-options'
import DateRangeInput from '../../../shared/components/form/date-range-input'
import { useResourcesQuery } from '../../resource/queries'
import buildResourceOptions from '../../resource/utils/build-resource-options'
import { useEventFilter, useEventFilterActions } from '../store/event-filter-store'

export default function EventFilterPopover() {
  const { t } = useTranslation(['event', 'common'])

  const filter = useEventFilter()
  const {
    setCustomerId,
    setResourceId,
    setCreatedAtRange,
    setOccurredAtRange,
  } = useEventFilterActions()

  const {
    data: customerOptions,
    isLoading: isCustomerOptionsLoading,
  } = useCustomersQuery({ select: buildCustomerOptions })

  const {
    data: resourceOptions,
    isLoading: isResourceOptionsLoading,
  } = useResourcesQuery({ select: buildResourceOptions })

  const handleClear = () => {
    setCustomerId(null)
    setResourceId(null)
    setCreatedAtRange([null, null])
    setOccurredAtRange([null, null])
  }

  return (
    <Popover width={350} position="bottom" shadow="md" withArrow>
      <Popover.Target>
        <Button variant="outline">{t('common:filter:label')}</Button>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="flex flex-col gap-4">
          <Select
            label={t('event:customer') as string}
            placeholder={t('event:customer') as string}
            searchable
            nothingFoundMessage={t('common:input.not-found')}
            data={customerOptions}
            disabled={isCustomerOptionsLoading}
            value={filter.customerId}
            onChange={setCustomerId}
            comboboxProps={{ withinPortal: false }}
            clearable
          />
          <Select
            label={t('event:resource') as string}
            placeholder={t('event:resource') as string}
            searchable
            nothingFoundMessage={t('common:input.not-found')}
            data={resourceOptions}
            disabled={isResourceOptionsLoading}
            value={filter.resourceId}
            onChange={setResourceId}
            comboboxProps={{ withinPortal: false }}
            clearable
          />
          <DateRangeInput
            label={t('event:filter:occurred-at-range') as string}
            dateRange={filter.occurredAtRange}
            setDateRange={setOccurredAtRange}
            placeholder={t('event:filter:occurred-at-range') as string}
          />
          <DateRangeInput
            label={t('event:filter:created-at-range') as string}
            dateRange={filter.createdAtRange}
            setDateRange={setCreatedAtRange}
            placeholder={t('event:filter:created-at-range') as string}
          />
        </div>
        <div className="flex justify-between w-full mt-4">
          <Button variant="outline" onClick={handleClear}>{t('common:filter:clear')}</Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}
