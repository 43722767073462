import { useState } from 'react'
import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import SearchInput from '../../../shared/components/form/search-input'
import { useResourceSearchTerm, useResourceSearchTermActions } from '../store/resource-search-term-store'
import CreateResourceModal from './create-resource-modal'

export default function ResourcePageActions() {
  const { t } = useTranslation('resource')

  const [isCreateResourceModalOpen, setIsCreateResourceModalOpen] = useState(false)

  const { setSearchTerm } = useResourceSearchTermActions()
  const searchTerm = useResourceSearchTerm()

  return (
    <>
      <CreateResourceModal
        opened={isCreateResourceModalOpen}
        onClose={() => setIsCreateResourceModalOpen(false)}
      />
      <div className="flex flex-row justify-between">
        <Button onClick={() => setIsCreateResourceModalOpen(true)}>
          {t('create-resource')}
        </Button>
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
    </>
  )
}
