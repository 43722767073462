import './App.css'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import { MantineProvider } from '@mantine/core'
import { QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { v4 as uuidv4 } from 'uuid'
import { ModalsProvider } from '@mantine/modals'
import Router from './router'
import mantineTheme from './theme/mantine-theme'
import 'react-toastify/dist/ReactToastify.css'
import queryClient from './config/query-client'
import { initializeClarity } from './config/clarity'
import ReactErrorBoundary from './features/error/components/react-error-boundary'
import { initializeGA4 } from './config/ga4'

function App() {
  initializeClarity(uuidv4())
  initializeGA4()

  return (
    <div className="App" id="app">
      <ReactErrorBoundary>
        <MantineProvider
          defaultColorScheme="light"
          theme={mantineTheme}
        >
          <ModalsProvider>
            <QueryClientProvider client={queryClient}>
              <ToastContainer />
              <Router />
              {/* <ReactQueryDevtools /> */}
            </QueryClientProvider>
          </ModalsProvider>
        </MantineProvider>
      </ReactErrorBoundary>
    </div>
  )
}

export default App
