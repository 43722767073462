import { MdChevronRight } from 'react-icons/md'

interface Props {
  rowData: any;
  action?: (rowData: any) => void;
}

export function TableArrowCell({ rowData, action }: Props) {
  const handleClick = () => {
    action?.(rowData)
  }

  return (
    <div className="cursor-pointer flex justify-end">
      <MdChevronRight onClick={handleClick} className="text-xl text-gray-700" />
    </div>
  )
}
