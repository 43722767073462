import { useMutation, useQuery } from 'react-query'
import { QueryParams } from '../../../interfaces'
import { Product } from '../../../models'
import productsService from '../services/product-service'
import queryClient from '../../../config/query-client'
import MutationParams from '../../../interfaces/queries/mutation-params'

export const productsKeys = {
  all: [{ scope: 'products' }] as const,
  lists: () => [{ ...productsKeys.all[0], entity: 'list' }] as const,
}

export const useProductsQuery = <ModelSchema = Product[]>(params?: QueryParams<Product[], ModelSchema>) => useQuery({
  queryKey: productsKeys.lists(),
  queryFn: productsService.fetchProducts,
  select: params?.select,
})

export const useCreateProduct = (params?: MutationParams<Product>) => useMutation({
  mutationFn: productsService.createProduct,
  onSuccess: (newProduct) => {
    queryClient.setQueryData<Product[]>(productsKeys.lists(), (products) => {
      if (!products) return [newProduct]
      if (!products.some((product) => product.name === newProduct.name)) return [newProduct, ...products]

      return products.map((product) => {
        if (product.name === newProduct.name) return newProduct
        return product
      })
    })
    params?.onSuccess(newProduct)
  },
})
