import {
  Tooltip as MantineTooltip, Text,
} from '@mantine/core'
import { ReactNode } from 'react'
import { FiInfo } from 'react-icons/fi'

interface Props {
  text: ReactNode;
  marginRight?: boolean;
  color?: 'dimmed' | 'violet';
  size?: 'xs' | 'sm' | 'md';
}

const fontSizes: { [key: string]: string } = {
  md: '1.1rem',
  sm: '0.9rem',
  xs: '0.7rem',
}

export default function Tooltip({
  text, marginRight, color = 'dimmed', size = 'md',
}: Props) {
  const textClass = marginRight ? 'mr-3' : ''
  const fontSize = fontSizes[size]

  return (
    <MantineTooltip
      multiline
      withArrow
      label={text}
      w={300}
      position="right"
      transitionProps={{ transition: 'pop-bottom-right' }}
    >
      <Text c={color} className={textClass}>
        <FiInfo size={fontSize} />
      </Text>
    </MantineTooltip>
  )
}
