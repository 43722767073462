import { useTranslation } from 'react-i18next'
import EmptyContentMessage from '../../../shared/components/designs/empty-content-message'
import { ExtendedContract } from '../../../models'
import ContractCard from './contract-card'

interface Props {
  contracts?: ExtendedContract[];
}

export default function ContractsList({ contracts }: Props) {
  const { t } = useTranslation('customer')

  if (contracts && contracts.length === 0) {
    return (
      <div className="py-20">
        <EmptyContentMessage text={t('customer:empty-contracts')} />
      </div>
    )
  }

  return (
    <div className="flex flex-col mt-4 gap-4">
      {contracts?.map((contract) => (
        <div key={contract.id}>
          <ContractCard contract={contract} />
        </div>
      ))}
    </div>
  )
}
