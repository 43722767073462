import { t } from 'i18next'

export const formatPlanNamesForDeleteErrorAlert = (planNames: string[]) => {
  if (planNames.length <= 3) {
    return planNames.join(', ')
  }
  const firstThreePlansNames = planNames.slice(0, 3).join(', ')
  const remainingCount = planNames.length - 3
  return `${t('resource:delete-error-alert:plans', { plans: firstThreePlansNames, count: remainingCount })}`
}
