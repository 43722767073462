import { NumberInput } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import LockButton from '../../../shared/components/form/lock-button'

interface Props {
  isUpdate?: boolean
}

export default function ContractScheduledPaymentDayInput({ isUpdate }: Props) {
  const { t } = useTranslation()

  const [isLocked, setIsLocked] = useState(!!isUpdate)

  const { control } = useFormContext<UpsertContractAttributes>()

  return (
    <div className={`flex w-full rounded-lg px-4 pt-2 pb-4 ${isUpdate && isLocked ? 'bg-gray-50' : 'border-[1px] border-gray-200'}  gap-4`}>
      <div className="w-1/2">
        {isUpdate ? (
          <Controller
            name="paymentGatewaySettings.scheduledPaymentDay"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <NumberInput
                label={t('customer:contract:form-sections:scheduled-payment-day')}
                placeholder={t('customer:contract:form-sections:scheduled-payment-day') as string}
                inputWrapperOrder={['label', 'input', 'description', 'error']}
                min={1}
                max={31}
                disabled={isLocked}
                required
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            name="paymentGatewaySettings.scheduledPaymentDay"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <NumberInput
                required
                label={t('customer:contract:form-sections:scheduled-payment-day')}
                placeholder={t('customer:contract:form-sections:scheduled-payment-day') as string}
                min={1}
                max={31}
                {...field}
              />
            )}
          />
        )}
      </div>

      <div className="flex w-1/2 justify-end">
        {isUpdate && (
          <LockButton isLocked={isLocked} setIsLocked={setIsLocked} />
        )}
      </div>

    </div>
  )
}
