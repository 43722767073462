import { useTranslation } from 'react-i18next'
import { BillingCycleStatus, ExternalProcessorSyncBillingCycleStatus } from '../enum'

const useInvoiceStatusOptions = () => {
  const { t } = useTranslation('invoice')

  const billingCycleStatusOptions = Object.values(BillingCycleStatus)
    .map((status) => ({
      label: t(`invoice:status-label:${status}`),
      value: status,
    }))
    .filter(({ value }) => value !== BillingCycleStatus.Paid)

  const externalProcessorStatusOptions = Object.values(ExternalProcessorSyncBillingCycleStatus)
    .map((status) => ({
      label: t(`invoice:external-processor:status-label:${status}`),
      value: status,
    }))
    .filter(({ value }) => value !== ExternalProcessorSyncBillingCycleStatus.NotApplicable && value !== ExternalProcessorSyncBillingCycleStatus.NotInitiated)

  return [...billingCycleStatusOptions, ...externalProcessorStatusOptions]
}

export { useInvoiceStatusOptions }
