import { Button, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { AiOutlinePlus } from 'react-icons/ai'

interface Props {
  handleAppendNewMetric: () => void;
}

export default function MetricFormHeader({ handleAppendNewMetric }: Props) {
  const { t } = useTranslation(['common', 'metric'])

  return (
    <div className="flex flex-row justify-between items-center">
      <Title size="h5">
        {t('metric:metrics')}
      </Title>
      <Button
        style={{
          justifyContent: 'flex-start',
        }}
        onClick={() => handleAppendNewMetric()}
        leftSection={<AiOutlinePlus size={22} />}
        variant="subtle"
      >
        {t('metric:add-metric')}
      </Button>
    </div>
  )
}
