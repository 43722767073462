import i18n from '../../../config/i18n'

interface Props {
  value: any;
}

export function TableDateCell({ value }: Props) {
  const date = new Date(value)

  if (!value) {
    return <div>-</div>
  }

  return (
    <div className="truncate">
      {date.toLocaleDateString(i18n.language, { timeZone: 'UTC' })}
    </div>
  )
}
