import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './error-fallback'
import errorLogger from '../helpers/error-logger'

interface Props {
  children: React.ReactNode;
}

export default function ReactErrorBoundary({ children }: Props) {
  return (
    <ErrorBoundary onError={errorLogger} FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  )
}
