import { DateInput, DateInputProps } from '@mantine/dates'
import { BiCalendar } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox } from '@mantine/core'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useState } from 'react'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import LockButton from '../../../shared/components/form/lock-button'
import dateInputFormatter from '../utils/date-input-formatter'

dayjs.extend(customParseFormat)

interface Props {
  isUpdate?: boolean
}

export default function ContractSubscriptionPeriodInput({ isUpdate }: Props) {
  const { t, i18n } = useTranslation()

  const {
    control, setValue, watch,
  } = useFormContext<UpsertContractAttributes>()

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  const [contractWithoutEndDate, setContractWithoutEndDate] = useState(!endDate && !!isUpdate)
  const [isLocked, setIsLocked] = useState(!!isUpdate)

  const dateFormat = i18n.language === 'pt' ? 'DD/MM/YYYY' : 'MM/DD/YYY'

  const dateParser: DateInputProps['dateParser'] = (input) => {
    const formats = ['DD/MM/YY', 'DD/MM/YYYY', 'MM/DD/YY', 'MM/DD/YYYY']
    // eslint-disable-next-line no-restricted-syntax
    for (const format of formats) {
      const parsedDate = dayjs(input, format, true)
      if (parsedDate.isValid()) {
        return parsedDate.toDate()
      }
    }
    return null
  }

  const handleDateInputChange = (input: EventTarget & HTMLInputElement) => {
    const cursorPos = input.selectionStart || 0
    const formattedValue = dateInputFormatter(input)

    // eslint-disable-next-line no-param-reassign
    input.value = formattedValue

    let newCursorPos = cursorPos
    if (formattedValue[cursorPos - 1] === '/') {
      newCursorPos = cursorPos + 1
    }
    input.setSelectionRange(newCursorPos, newCursorPos)

    const parsedDate = dayjs(formattedValue, 'DD/MM/YYYY', true).isValid()
      ? dayjs(formattedValue, 'DD/MM/YYYY').toDate()
      : null

    return parsedDate
  }

  const handleDateInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const cursorPos = e.currentTarget.selectionStart || 0

    if (e.key === 'Backspace') {
      if (e.currentTarget.value[cursorPos - 1] === '/') {
        e.preventDefault()
        e.currentTarget.setSelectionRange(cursorPos - 1, cursorPos - 1)
      }
    } else if (e.key === 'Delete') {
      if (e.currentTarget.value[cursorPos] === '/') {
        e.preventDefault()
        e.currentTarget.setSelectionRange(cursorPos + 1, cursorPos + 1)
      }
    }
  }

  const handleCheckContractWithoutEndDate = (checked: boolean) => {
    setContractWithoutEndDate(checked)

    if (!checked) {
      setValue('startDate', startDate)
      const newEndDate = new Date(startDate)
      newEndDate.setDate(startDate.getDate() + 1)
      setValue('endDate', newEndDate)
    }

    if (checked) {
      setValue('startDate', startDate)
      setValue('endDate', null)
    }
  }

  return (
    <div className={`flex w-full rounded-lg px-4 pt-2 pb-4 ${isUpdate && isLocked ? 'bg-gray-50' : 'border-[1px] border-gray-200'}  gap-4`}>
      <div className="w-full">
        <div className="flex gap-4">
          <div className="w-8/12">
            {contractWithoutEndDate ? (
              <Controller
                name="startDate"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    required
                    placeholder={t('customer:contract:start-date') as string}
                    label={t('customer:contract:start-date') as string}
                    rightSection={(<BiCalendar className="cursor-pointer" size={18} />)}
                    popoverProps={{ withinPortal: false }}
                    locale={i18n.language === 'pt' ? 'pt-br' : 'en'}
                    valueFormat={dateFormat}
                    dateParser={dateParser}
                    disabled={isLocked}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const parsedDate = handleDateInputChange(e.target)
                      field.onChange(parsedDate)
                    }}
                    onKeyDown={handleDateInputKeyDown}
                  />
                )}
              />
            ) : (
              <div className="flex gap-4">
                <Controller
                  name="startDate"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      required
                      placeholder={t('customer:contract:start-date') as string}
                      label={t('customer:contract:start-date') as string}
                      rightSection={(<BiCalendar className="cursor-pointer" size={18} />)}
                      popoverProps={{ withinPortal: true }}
                      locale={i18n.language === 'pt' ? 'pt-br' : 'en'}
                      valueFormat={dateFormat}
                      dateParser={dateParser}
                      maxDate={endDate ?? undefined}
                      disabled={isLocked}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const parsedDate = handleDateInputChange(e.target)
                        field.onChange(parsedDate)
                      }}
                      onKeyDown={handleDateInputKeyDown}
                    />
                  )}
                />
                <Controller
                  name="endDate"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      required
                      placeholder={t('customer:contract:end-date') as string}
                      label={t('customer:contract:end-date') as string}
                      rightSection={(<BiCalendar className="cursor-pointer" size={18} />)}
                      popoverProps={{ withinPortal: true }}
                      locale={i18n.language === 'pt' ? 'pt-br' : 'en'}
                      valueFormat={dateFormat}
                      dateParser={dateParser}
                      disabled={isLocked}
                      minDate={startDate}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const parsedDate = handleDateInputChange(e.target)
                        field.onChange(parsedDate)
                      }}
                      onKeyDown={handleDateInputKeyDown}
                    />
                  )}
                />
              </div>
            )}
          </div>

          <div className="flex w-60 mt-8">
            <Checkbox
              color="violet"
              label={t('customer:contract:automatic-renewal') as string}
              checked={contractWithoutEndDate}
              onChange={(event) => handleCheckContractWithoutEndDate(event.currentTarget.checked)}
              disabled={isLocked}
            />
          </div>

          {isUpdate && (
          <LockButton isLocked={isLocked} setIsLocked={setIsLocked} />
          )}

        </div>
      </div>
    </div>
  )
}
