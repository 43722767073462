import { toast } from 'react-toastify'
import ErrorType from '../enum/error-type'
import AppError from '../interfaces/app-error'
import handleLogout from '../../../shared/utils/user/handle-logout'
import errorLogger from './error-logger'
import { Path } from '../../../shared/enums'

const makeError = (error: AppError) => {
  switch (error.type) {
    case ErrorType.Fallback:
      errorLogger(new Error(error.message), { componentStack: error.stack || '' })
      window.location.href = Path.Error
      break

    case ErrorType.Loggout:
      handleLogout()
      toast.error(error.message)
      break

    case ErrorType.Notification:
    default:
      toast.error(error.message)
      break
  }
}

export default makeError
