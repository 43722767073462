import { Skeleton } from '@mantine/core'

export default function FetchInvoiceSkeleton() {
  return (
    <div className="flex flex-col w-full gap-8 overflow-y-hidden">
      <Skeleton height={141} width="100%" radius="xl" />
      <div className="flex flex-row items-flex-start w-full gap-6">
        <Skeleton height={280} width="75%" radius="xl" />
        <div className="flex flex-col items-center w-100 gap-6">
          <Skeleton height={137} width="100%" radius="xl" />
          <Skeleton height={130} width="100%" radius="xl" />
        </div>
      </div>
    </div>
  )
}
