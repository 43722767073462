import { PlanPageActions, TablePlan } from '../features/plan/components'

export default function Plans() {
  return (
    <div className="h-full w-full flex flex-col">
      <PlanPageActions />
      <TablePlan />
    </div>
  )
}
