import { Checkbox, Table } from '@mantine/core'
import { TableColumn } from '../../../interfaces'

interface Props<ModelSchema> {
  columns: TableColumn<ModelSchema>[];
  selectedRows: Set<keyof ModelSchema>
  selectableRows: ModelSchema[]
  onSelectMultipleRows: (rows: Set<keyof ModelSchema>) => void
  indexKey: keyof ModelSchema
}

export function SelectTableHeader<ModelSchema>({
  columns,
  selectedRows,
  selectableRows,
  onSelectMultipleRows,
  indexKey,
}: Props<ModelSchema>) {
  const allRowsSelected = selectedRows.size > 0 && selectedRows.size === selectableRows.length
  const someRowsSelected = selectedRows.size > 0 && selectedRows.size < selectableRows.length

  const handleToggleSelectAll = () => {
    if (allRowsSelected || someRowsSelected) {
      onSelectMultipleRows(new Set())
    } else {
      const newSelectedRows = new Set(selectableRows.map((row) => row[indexKey] as keyof ModelSchema))
      onSelectMultipleRows(newSelectedRows)
    }
  }

  return (
    <Table.Thead>
      <Table.Tr>
        <Table.Th>
          <Checkbox
            indeterminate={someRowsSelected}
            checked={allRowsSelected}
            onChange={handleToggleSelectAll}
          />
        </Table.Th>
        {columns.map((column) => (
          <Table.Th key={column.columnKey}>{column.label}</Table.Th>
        ))}
      </Table.Tr>
    </Table.Thead>
  )
}
