import dayjs from 'dayjs'
import { api } from '../../../libs'
import GetEventsRequest from '../../../libs/api/requests/events/get-events-request'
import { Event } from '../../../models'
import { EventFilter } from '../interfaces/event-filter'
import GetEventsSumRequest from '../../../libs/api/requests/events/get-events-sum-request'

const fetchEvents = async (filter: EventFilter, idempotencyKeySearch: string): Promise<Event[]> => {
  const request: GetEventsRequest = {
    page: 1,
    limit: 300,
    customerId: filter.customerId,
    resourceId: filter.resourceId,
    idempotencyKey: idempotencyKeySearch || null,
    occurredAtStart: (filter.occurredAtRange[0] && filter.occurredAtRange[1]) ? filter.occurredAtRange[0].toISOString() : null,
    occurredAtEnd: (filter.occurredAtRange[0] && filter.occurredAtRange[1]) ? getEndDateFilterValue(filter.occurredAtRange[1]) : null,
    createdAtStart: (filter.createdAtRange[0] && filter.createdAtRange[1]) ? filter.createdAtRange[0].toISOString() : null,
    createdAtEnd: (filter.createdAtRange[0] && filter.createdAtRange[1]) ? getEndDateFilterValue(filter.createdAtRange[1]) : null,
  }

  const getEventsResponse = await api.events.getEvents(request)
  return getEventsResponse.data
}

const fetchEventsSum = async (filter: EventFilter, idempotencyKeySearch: string): Promise<number> => {
  const request: GetEventsSumRequest = {
    customerId: filter.customerId,
    resourceId: filter.resourceId,
    idempotencyKey: idempotencyKeySearch || null,
    occurredAtStart: (filter.occurredAtRange[0] && filter.occurredAtRange[1]) ? filter.occurredAtRange[0].toISOString() : null,
    occurredAtEnd: (filter.occurredAtRange[0] && filter.occurredAtRange[1]) ? getEndDateFilterValue(filter.occurredAtRange[1]) : null,
    createdAtStart: (filter.createdAtRange[0] && filter.createdAtRange[1]) ? filter.createdAtRange[0].toISOString() : null,
    createdAtEnd: (filter.createdAtRange[0] && filter.createdAtRange[1]) ? getEndDateFilterValue(filter.createdAtRange[1]) : null,
  }

  const getInvoicesResponse = await api.events.getEventsSum(request)
  return getInvoicesResponse.data.total
}

const getEndDateFilterValue = (endDate: Date) => dayjs(endDate).endOf('day').toISOString()

const eventService = {
  fetchEvents,
  fetchEventsSum,
}

export default eventService
