import { Breadcrumbs as MantineBreadcrumbs, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link, Params } from 'react-router-dom'
import { Page } from '../../../interfaces'
import breadcrumbsUtils from '../utils/breadcrumbs-utils'

interface Props {
  currentPage: Page
  currentPageParams: Params<string>
}

export default function Breadcrumb({ currentPage, currentPageParams }: Props) {
  const { t } = useTranslation('common')
  const breadcrumbs = breadcrumbsUtils.getPageBreadcrumbs(currentPage, currentPageParams)

  return (
    <div>
      <MantineBreadcrumbs>
        {breadcrumbs?.map((breadcrumb) => (
          <div key={breadcrumbsUtils.getBreadcrumbKey(breadcrumb)}>
            {breadcrumb.path
              ? (
                <Text component={Link} size="md" to={breadcrumb.path} className="text-gray-500">
                  {t(breadcrumb.titleInternationalizationKey)}
                </Text>
              )
              : (
                <Text size="md">
                  {t(breadcrumb.titleInternationalizationKey)}
                </Text>
              )}
          </div>
        ))}
      </MantineBreadcrumbs>
    </div>
  )
}
