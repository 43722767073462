import { Skeleton } from '@mantine/core'

export default function UpdatePlanFormSkeleton() {
  return (
    <div className="flex flex-row w-full gap-4">
      <div className="flex flex-col items-flex-start w-full">
        <Skeleton height={24} width={200} radius="xl" />
        <Skeleton height={24} mt={6} width={160} radius="xl" />
        <Skeleton height={52} mt={8} width="100%" radius="xl" />
        <Skeleton height={52} mt={6} width="100%" radius="xl" />
        <Skeleton height={24} mt={12} width={140} radius="xl" />
        <Skeleton height={52} mt={8} width="100%" radius="xl" />
        <Skeleton height={52} mt={8} width="100%" radius="xl" />
        <Skeleton height={52} mt={8} width="100%" radius="xl" />
      </div>
      <div className="flex flex-col items-center w-100">
        <Skeleton height={320} width={320} radius="xl" />
      </div>
    </div>
  )
}
