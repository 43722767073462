import {
  Table as MantineTable, ScrollArea,
} from '@mantine/core'
import { TableHeader } from './table-header'
import { TableColumn } from '../../../interfaces'
import { TableDefaultCell } from './table-default-cell'
import { ROW_HEIGHT_SIZE } from '../../utils/constants'

interface Props<ModelSchema> {
  data: ModelSchema[];
  columns: TableColumn<ModelSchema>[];
  indexKey: keyof ModelSchema;
  SummaryRow?: React.ComponentType<any>;
  summaryRowProps?: any;
}

export function DetailTable<ModelSchema>({
  data, columns, indexKey, SummaryRow, summaryRowProps,
}: Props<ModelSchema>) {
  return (
    <div className="h-full my-3">
      <ScrollArea>
        <div className="bg-white rounded-xl px-5 pt-2 pb-2">
          <MantineTable className="table-fixed">
            <colgroup>
              {columns.map(({ widthPercentage, columnKey }) => (
                <col key={columnKey} style={{ width: `${widthPercentage}%` }} />
              ))}
            </colgroup>

            <TableHeader columns={columns} variant="detail" />

            <MantineTable.Tbody>
              {data.length > 0 && (
                data.map((row: any) => (
                  <MantineTable.Tr
                    style={{
                      height: `${ROW_HEIGHT_SIZE}px`,
                      maxHeight: `${ROW_HEIGHT_SIZE}px`,
                    }}
                    key={row[indexKey]}
                  >
                    {
                      columns.map(({
                        key, columnKey, Component,
                      }) => (
                        <MantineTable.Td
                          key={columnKey}
                          style={{
                            backgroundColor: '#F8F9FA',
                          }}
                        >
                          {Component ? (
                            <Component value={row[key]} rowData={row} />
                          ) : (
                            <TableDefaultCell value={row[key]} />
                          )}
                        </MantineTable.Td>
                      ))
                    }
                  </MantineTable.Tr>
                ))
              )}

              {SummaryRow && (
                <SummaryRow
                  {...summaryRowProps}
                />
              )}

            </MantineTable.Tbody>
          </MantineTable>
        </div>
      </ScrollArea>
    </div>
  )
}
