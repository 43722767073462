import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SearchInput from '../../../shared/components/form/search-input'
import { usePlanSearchTerm, usePlansSearchTermActions } from '../store/plan-search-term-store'
import { Path } from '../../../shared/enums'

export default function PlanPageActions() {
  const { t } = useTranslation('plan')

  const { setSearchTerm } = usePlansSearchTermActions()
  const searchTerm = usePlanSearchTerm()

  return (
    <div className="flex flex-row justify-between">
      <Link to={Path.CreatePlan}>
        <Button>
          {t('new-plan')}
        </Button>
      </Link>
      <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
    </div>
  )
}
