import { useAuthIsUserAuthenticated, useAuthUser } from '../../features/auth/store/auth-store'
import userSessionLocalStorageUtils from '../../features/auth/utils/user-session-local-storage-utils'
import { Page } from '../../interfaces'
import { User } from '../../models'
import pages from '../../pages'
import { Path } from '../enums'

const usePages = (): Page[] => {
  const isUserAuthenticated = useAuthIsUserAuthenticated()
  let user: User | undefined = useAuthUser()

  if (!isUserAuthenticated) {
    const userSession = userSessionLocalStorageUtils.getUserSession()
    user = userSession?.user
  }

  if (!user) {
    return pages.filter((page) => page.path === Path.Login)
  }

  return pages
}

export default usePages
