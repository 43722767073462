enum BillingCycelExternalProcessorUserCodes {
  NoActiveContractFoundForCustomer = 'no_active_contract_found_for_customer',
  MultipleMatchedOmieContractsForAiraContract = 'multiple_matched_omie_contracts_for_aira_contract',
  MultipleContractsInOmieWithTheSameContractNumber = 'multiple_contracts_in_omie_with_the_same_contract_number',
  NoOmieContractMatchedForProducts = 'no_omie_contract_matched_for_products',
  NoOmieContractMatchedForRangeDates = 'no_omie_contract_matched_for_range_dates',
  CustomerContractsInOmieAreNotActive = 'customer_contracts_in_omie_are_not_active',
  ContractInOmieHasRepeatedServices = 'contract_in_omie_has_repeated_services',
  PaymentAccountMissingTaxId = 'payment_account_missing_tax_id',
  CustomerNotFoundInOmie = 'customer_not_found_in_omie',
  NoActiveContractFoundInOmieForContractNumber = 'no_active_contract_found_for_this_contract_number',
  MultipleOmieContractsWithTheSameContractNumber = 'multiple_omie_contracts_with_the_same_contract_number',
  CustomerInOmieHasNoContracts = 'customer_in_omie_has_no_contracts',
  NoActiveContractFoundForCustomerInOmie = 'no_active_contract_found_for_customer_in_omie',
}

export default BillingCycelExternalProcessorUserCodes
