import {
  Button, Input, Select,
} from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useAggregationOptions from '../utils/use-aggregation-options'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import { Aggregation, BillingModel } from '../enum'
import Tooltip from '../../../shared/components/form/tooltip-input'

interface Props {
  index: number
}

export default function BillingModelAndAggregationInput({ index }: Props) {
  const { t } = useTranslation(['common', 'metric'])

  const aggregationOptions = useAggregationOptions()

  const {
    control, watch, formState: { errors }, setValue,
  } = useFormContext<UpsertExtendedPlanAttributes>()

  const aggregation = watch(`metrics.${index}.aggregation`)

  const handleSelectBillingModel = (value: BillingModel) => {
    const validAggregationValue = aggregation || Aggregation.Sum
    const newAggregationValue = value === BillingModel.Proration ? null : validAggregationValue
    setValue(`metrics.${index}.aggregation`, newAggregationValue)
    setValue(`metrics.${index}.billingModel`, value)
  }

  return (
    <div className="flex flex-col gap-4 mb-4">
      <div className="flex flex-row w-full">
        <Input.Wrapper label={t('metric:billing-model:form-label')} required>

          <div className="flex flex-row mt-1 gap-2">
            <Button
              className={aggregation ? 'bg-violet-100' : ''}
              variant="outline"
              onClick={() => handleSelectBillingModel(BillingModel.InFull)}
            >
              {t('metric:billing-model:in-full')}
            </Button>

            <Button
              className={!aggregation ? 'bg-violet-100' : ''}
              variant="outline"
              onClick={() => handleSelectBillingModel(BillingModel.Proration)}
            >
              {t('metric:billing-model:proration')}
            </Button>
          </div>
        </Input.Wrapper>

        <div className="-ml-9 mt-1">
          <Tooltip text={t('metric:billing-model:both-tooltip') as string} />
        </div>

      </div>

      { aggregation && (
        <Controller
          name={`metrics.${index}.aggregation`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              className="w-full"
              allowDeselect={false}
              label={`${t('metric:aggregation') as string}`}
              placeholder={t('metric:aggregation') as string}
              data={aggregationOptions}
              error={errors.metrics?.[index]?.aggregation && errors.metrics?.[index]?.message}
              required
              searchable
            />
          )}
        />
      )}
    </div>
  )
}
