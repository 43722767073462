import { Text } from '@mantine/core'

interface Props {
  label: string;
  description: string;
}

export function SelectOption({ label, description }: Props) {
  return (
    <div>
      <div>
        <Text fz="sm" fw={500}>
          {label}
        </Text>
        <Text fz="xs" opacity={0.6}>
          {description}
        </Text>
      </div>
    </div>
  )
}
