const CLARITY_SESSION_KEY = 'clarity-session'

const initializeClarity = (claritySessionId) => {
  window.clarity('consent')
  localStorage.setItem(CLARITY_SESSION_KEY, claritySessionId)
  window.clarity('set', 'env', import.meta.env.VITE_SERVER_BASE_URL)
}

const registerClarityEvent = (customId, customPageId, tenantId) => {
  const claritySessionId = localStorage.getItem(CLARITY_SESSION_KEY)
  window.clarity('set', 'tenant', tenantId)
  window.clarity('set', 'userId', customId)
  window.clarity('identify', customId, claritySessionId, customPageId)
}

const setClarityCustomTag = (key, value) => {
  window.clarity('set', key, value)
}

export { initializeClarity, registerClarityEvent, setClarityCustomTag }
