import { useFormContext } from 'react-hook-form'
import { Button, TextInput, Textarea } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'
import CentsInput from '../../../../../shared/components/form/cents-input'
import UpsertInvoiceAdditionalItems from '../../../../../interfaces/invoice/upsert-invoice-additional-items'
import { MAX_TEXT_FIELD_LENGTH } from '../../../../../shared/utils/constants'

interface Props {
  index: number,
  remove: (index: number) => void,
}

export default function ManageInvoiceItemForm({ index, remove }: Props) {
  const { t } = useTranslation(['common', 'invoice'])

  const {
    setValue, getValues, register, formState: { errors }, clearErrors,
  } = useFormContext<UpsertInvoiceAdditionalItems>()

  return (
    <div className="flex flex-col gap-1 items-start">
      <TextInput
        className="w-full mb-3"
        label={t('invoice:item-form:name') as string}
        placeholder={t('invoice:item-form:name') as string}
        {...register(`additionalItems.${index}.name`, {
          required: { value: true, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
        required
      />

      <Textarea
        className="w-full mb-3"
        label={t('invoice:item-form:description') as string}
        placeholder={t('invoice:item-form:description') as string}
        {...register(`additionalItems.${index}.description`, {
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />

      <CentsInput
        className="w-full mb-3"
        label={t('invoice:item-form:amount') as string}
        placeholder={t('common:form-placeholder:zero') as string}
        value={getValues(`additionalItems.${index}.amount`)}
        onChange={(value: number) => {
          setValue(`additionalItems.${index}.amount`, value)
          clearErrors(`additionalItems.${index}.amount`)
        }}
        error={errors.additionalItems?.[index]?.amount && errors.additionalItems?.[index]?.amount?.message as string}
        min={1}
        required
      />

      <Button
        style={{
          justifyContent: 'flex-start',
        }}
        onClick={() => remove(index)}
        leftSection={<BiTrashAlt size={22} color="#c92a2a" />}
        variant="subtle"
        color="red"
        size="sm"
      >
        {t('invoice:item-form:remove')}
      </Button>
    </div>
  )
}
