import { useTranslation } from 'react-i18next'
import { Table } from '@mantine/core'
import { ROW_HEIGHT_SIZE } from '../../../../../shared/utils/constants'
import { TableDefaultCell } from '../../../../../shared/components/table/table-default-cell'
import formatToCurrency from '../../../../../shared/utils/helpers/format-to-currency'
import { InvoiceMetricProration } from '../../../../../models/invoice-metric'

function TableProrationSumaryRow(metric: InvoiceMetricProration) {
  const { t, i18n } = useTranslation('common')
  const { language } = i18n

  const { prorationMetricBillings } = metric

  const metricTotalUsageAmount = prorationMetricBillings.reduce((previusValue, currentValue) => previusValue + currentValue.amount, 0) as number
  const metricUsageAmountCurrency = formatToCurrency({ price: metricTotalUsageAmount, language })

  return (
    <Table.Tr
      style={{
        height: `${ROW_HEIGHT_SIZE}px`,
        backgroundColor: '#F3F0FF',
      }}
    >
      <Table.Td>
        <TableDefaultCell value={t('common:final-value') as string} />
      </Table.Td>
      <Table.Td>
        <TableDefaultCell value="" />
      </Table.Td>
      <Table.Td>
        <TableDefaultCell value={metricUsageAmountCurrency} />
      </Table.Td>
    </Table.Tr>
  )
}

export default TableProrationSumaryRow
