import axios from 'axios'
import userSessionLocalStorageUtils from '../../../features/auth/utils/user-session-local-storage-utils'

const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_SERVER_BASE_URL || ''}/core/v1`,
})

axiosInstance.interceptors.request.use(
  (config) => {
    const userSession = userSessionLocalStorageUtils.getUserSession()

    if (config.headers && userSession) {
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/dot-notation
      config.headers['Authorization'] = `Bearer ${userSession.accessToken}`
    }

    return config
  },
)

export default axiosInstance
