import { useEffect } from 'react'
import {
  TextInput, Title, Button,
} from '@mantine/core'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCustomerQuery, useUpdateCustomer } from '../queries'
import { MAX_TEXT_FIELD_LENGTH } from '../../../shared/utils/constants'
import SafeTextInput from '../../../shared/components/form/safe-text-input'
import Tooltip from '../../../shared/components/form/tooltip-input'
import UpsertCustomerAttributes from '../../../interfaces/customer/upsert-customer-attributes'

export default function UpdateCustomerForm() {
  const { t } = useTranslation(['customer', 'common'])

  const { id } = useParams()
  const { data: customer } = useCustomerQuery(id)

  const useFormMethods = useForm<UpsertCustomerAttributes>({
    mode: 'onTouched',
  })

  const {
    handleSubmit, reset, register, formState: { errors, isDirty, isValid },
  } = useFormMethods

  useEffect(() => {
    if (customer) {
      reset(customer)
    }
  }, [customer, reset])

  const { mutate } = useUpdateCustomer()

  return (
    <FormProvider {...useFormMethods}>
      <form
        id="plan-form"
        className="flex flex-col w-full"
        onSubmit={handleSubmit(
          (updateCustomerAttributes: UpsertCustomerAttributes) => mutate(updateCustomerAttributes),
        )}
      >
        <Title fw={700} order={5} className="pb-4">
          {t('customer:customer-data')}
        </Title>

        <div className="w-full flex">
          <div className="w-6/12 pr-2">
            <TextInput
              label={t('customer:name') as string}
              placeholder={t('customer:name') as string}
              error={errors.name && errors.name.message}
              required
              {...register('name', {
                required: { value: true, message: t('common:form-error:required-field') },
                maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
              })}
            />

            <Button
              type="submit"
              className="mt-4 w-30"
              disabled={!isDirty || !isValid}
            >
              {t('common:save')}
            </Button>
          </div>

          <div className="w-6/12 pl-2">
            <SafeTextInput
              required
              label={t('customer:external-id') as string}
              placeholder={t('customer:external-id') as string}
              error={errors.externalId && errors.externalId.message}
              descriptionText={t('customer:form:external-id-alert') as string}
              rightSection={<Tooltip text={t('customer:form:external-id-description') as string} />}
              {...register('externalId', {
                required: { value: true, message: t('common:form-error:required-field') },
                maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
              })}
              keepDescriptionText
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
