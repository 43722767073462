import { PriceTierDivision } from '../enum'
import i18n from '../../../config/i18n'

const priceTierDivisionLabel = (priceTier: PriceTierDivision) => {
  const label = {
    [PriceTierDivision.Progressive]: i18n.t('priceTier:division-progressive'),
    [PriceTierDivision.UniqueTier]: i18n.t('priceTier:division-unique-tier'),
  }

  return label[priceTier]
}

export default priceTierDivisionLabel
