import { matchRoutes, Params, useLocation } from 'react-router-dom'
import { Page } from '../../interfaces'
import pages from '../../pages'

interface UseCurrentPage {
  currentPage?: Page
  currentPageParams: Params<string>
}

const useCurrentPage = (): UseCurrentPage => {
  const location = useLocation()

  const [foundPage] = matchRoutes(pages, location) || []

  return {
    currentPage: foundPage.route,
    currentPageParams: foundPage.params,
  }
}

export default useCurrentPage
