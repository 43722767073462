import { Table } from '@mantine/core'
import { TableColumn } from '../../../interfaces'

interface Props<ModelSchema> {
  columns: TableColumn<ModelSchema>[];
  variant: 'core' | 'detail'
}

export function TableHeader<ModelSchema>({ columns, variant }: Props<ModelSchema>) {
  return (
    <Table.Thead className={variant === 'detail' ? 'bg-violet-300' : ''}>
      <Table.Tr>
        {columns.map((column) => (
          <Table.Th className={variant === 'detail' ? 'text-white' : ''} key={column.columnKey}>{column.label}</Table.Th>
        ))}
      </Table.Tr>
    </Table.Thead>
  )
}
