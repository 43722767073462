import { v4 as uuidv4 } from 'uuid'
import { InvoiceAdditionalItem } from '../../../models'
import UpsertInvoiceAdditionalItemAttributes from '../../../interfaces/invoice/upsert-invoice-additional-item-attributes'
import UpdateInvoiceAdditionalItemsRequest from '../../../libs/api/requests/invoices/update-invoice-additional-items-request'

const extendedInvoiceAdditionalItemsToUpsertInvoiceAdditionalItems = (
  additionalItems: InvoiceAdditionalItem[],
): UpsertInvoiceAdditionalItemAttributes[] => additionalItems.map((additionalItem: InvoiceAdditionalItem) => ({
  ...additionalItem,
  renderId: uuidv4(),
}))

const upsertInvoiceAdditionalItemAttributesToRequest = (
  invoiceId: string,
  additionalItems: UpsertInvoiceAdditionalItemAttributes[],
  currentInvoiceAdditionalItems: UpsertInvoiceAdditionalItemAttributes[],
): UpdateInvoiceAdditionalItemsRequest => {
  const upsertedAdditionalItems = additionalItems.map((additionalItem) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { renderId, ...itemWithoutRenderId } = additionalItem

    const currentItem = currentInvoiceAdditionalItems.find((current) => current.id === additionalItem.id)
    const hasChanged = currentItem?.name !== additionalItem.name || currentItem?.amount !== additionalItem.amount

    if (hasChanged) {
      return {
        ...itemWithoutRenderId,
        id: undefined,
      }
    }

    return itemWithoutRenderId
  })

  return {
    invoiceId,
    additionalItems: upsertedAdditionalItems,
  }
}

export {
  extendedInvoiceAdditionalItemsToUpsertInvoiceAdditionalItems, upsertInvoiceAdditionalItemAttributesToRequest,
}
