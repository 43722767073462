import UserCodes from '../../../libs/api/enums/user-codes'
import userCodesMessages from './user-code-messages'

const getErrorMessage = (errorCode?: UserCodes) => {
  if (!errorCode || !(errorCode in userCodesMessages)) {
    return userCodesMessages.default
  }

  return userCodesMessages[errorCode as keyof typeof userCodesMessages]
}

export default getErrorMessage
