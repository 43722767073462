import TablePageConfiguration from '../../../../interfaces/table/page-configuration'

const getRowsPerPage = (defaultRowsPerPage: number, pageConfiguration?: TablePageConfiguration) => {
  if (!pageConfiguration || (!pageConfiguration.pageVariation && !pageConfiguration.rowsPerPage)) {
    return defaultRowsPerPage
  }

  if (pageConfiguration.rowsPerPage) return pageConfiguration.rowsPerPage

  return defaultRowsPerPage + (pageConfiguration.pageVariation as number)
}

const tableUtils = {
  getRowsPerPage,
}

export default tableUtils
