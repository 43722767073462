import { InvoiceExternalProcessor } from '../../../models/invoice-external-processor'
import { InvoiceExternalProcessorFilter } from '../../../interfaces/invoice/invoice-external-processor-filter'

const getDateRangeMatch = (invoiceExternalProcessor: InvoiceExternalProcessor, dateRange: [Date | null, Date | null]) => {
  const [startFilterDate, endFilterDate] = dateRange
  const endInvoiceDate = new Date(invoiceExternalProcessor.lastProcessedAt)

  if (!startFilterDate && !endFilterDate) return true
  if (startFilterDate && !endFilterDate) return endInvoiceDate >= startFilterDate
  if (!startFilterDate && endFilterDate) return endInvoiceDate <= endFilterDate
  if (startFilterDate && endFilterDate) return endInvoiceDate >= startFilterDate && endInvoiceDate <= endFilterDate

  return true
}

const getFilteredinvoiceExternalProcessors = (invoiceExternalProcessors: InvoiceExternalProcessor[], filter: InvoiceExternalProcessorFilter) => {
  const { status, dateRange } = filter

  return invoiceExternalProcessors.filter((invoiceExternalProcessor) => {
    const isStatusMatch = status.length === 0 || status.includes(invoiceExternalProcessor.status)
    const isDateRangeMatch = getDateRangeMatch(invoiceExternalProcessor, dateRange)
    return isStatusMatch && isDateRangeMatch
  })
}

export { getFilteredinvoiceExternalProcessors }
