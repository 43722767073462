import { create } from 'zustand'

interface InvoiceExternalProcessorSearchTermState {
  searchTerm: string
  actions: {
    setSearchTerm: (searchTerm: string) => void
  }
}

const useInvoiceExternalProcessorSearchTermStore = create<InvoiceExternalProcessorSearchTermState>((set) => ({
  searchTerm: '',
  actions: {
    setSearchTerm: (searchTerm: string) => set({ searchTerm }),
  },
}))

export const useInvoiceExternalProcessorSearchTerm = () => useInvoiceExternalProcessorSearchTermStore((state) => state.searchTerm)
export const useInvoiceExternalProcessorSearchTermActions = () => useInvoiceExternalProcessorSearchTermStore((state) => state.actions)
