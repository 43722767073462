import {
  Checkbox, NumberInput,
} from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import Tooltip from '../../../shared/components/form/tooltip-input'
import LockButton from '../../../shared/components/form/lock-button'

interface Props {
  isUpdate?: boolean
}

export default function ContractInvoiceEndDateInput({ isUpdate }: Props) {
  const { t } = useTranslation()

  const [isCheckboxMonthLastDayEnabled, setIsCheckboxMonthLastDayEnabled] = useState<boolean>(false)
  const [isLocked, setIsLocked] = useState(!!isUpdate)

  const {
    control, setValue, getValues,
  } = useFormContext<UpsertContractAttributes>()

  const { current: billingEndDay } = useRef(getValues('billingEndDay'))

  const handleCheckboxMonthLastDay = (value: boolean) => {
    if (value) setValue('billingEndDay', 31, { shouldDirty: true, shouldTouch: true, shouldValidate: true })
    setIsCheckboxMonthLastDayEnabled(value)
  }

  useEffect(() => {
    if (isUpdate && billingEndDay === 31) setIsCheckboxMonthLastDayEnabled(true)
  }, [isUpdate, billingEndDay])

  return (
    <div className={`flex w-full rounded-lg px-4 pt-2 pb-4 ${isUpdate && isLocked ? 'bg-gray-50' : 'border-[1px] border-gray-200'}  gap-4`}>
      <div className="w-full">
        {isUpdate ? (
          <Controller
            name="billingEndDay"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <NumberInput
                label={t('customer:invoice-billing-day')}
                placeholder={t('customer:billing-day') as string}
                description={!isLocked && t('customer:form:billing-day-change-alert') as string}
                inputWrapperOrder={['label', 'input', 'description', 'error']}
                max={31}
                rightSection={<Tooltip marginRight text={t('customer:form:billing-day-alert')} />}
                disabled={isCheckboxMonthLastDayEnabled || isLocked}
                required
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            name="billingEndDay"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <NumberInput
                required
                label={t('customer:invoice-billing-day')}
                placeholder={t('customer:billing-day') as string}
                min={1}
                max={31}
                rightSection={<Tooltip marginRight text={t('customer:form:billing-day-alert')} />}
                disabled={isCheckboxMonthLastDayEnabled}
                {...field}
              />
            )}
          />
        )}
      </div>

      <div className="flex w-full mt-8 gap-2">
        <Checkbox
          checked={isCheckboxMonthLastDayEnabled}
          onChange={(event) => handleCheckboxMonthLastDay(event.currentTarget.checked)}
          disabled={isUpdate && isLocked}
          color="violet"
          label={t('customer:month-last-day')}
        />
      </div>

      {isUpdate && (
      <LockButton isLocked={isLocked} setIsLocked={setIsLocked} />
      )}

    </div>
  )
}
