import { Skeleton } from '@mantine/core'

export default function ContractsListSkeleton() {
  return (
    <div className="flex flex-col gap-3">
      <Skeleton className="w-full h-22" />
      <Skeleton className="w-full h-22" />
      <Skeleton className="w-full h-22" />
    </div>
  )
}
