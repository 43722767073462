import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Path } from '../../../shared/enums'
import { ExtendedPlan } from '../../../models'
import TableMenuCell from '../../../shared/components/table/table-menu-cell'
import useDeletePlanHandler from './modal-actions/delete-plan-modal'
import RateAdjustPlanModal from './rate-adjust-plan-modal'

interface Props {
  rowData: ExtendedPlan;
}

export function TablePlanMenuCell({ rowData: plan }: Props) {
  const [isRateAdjustModalOpen, setIsRateAdjustModalOpen] = useState(false)

  const navigate = useNavigate()
  const deletePlanHandler = useDeletePlanHandler()

  return (
    <div className="cursor-pointer flex justify-end">
      <RateAdjustPlanModal
        planId={plan.id}
        opened={isRateAdjustModalOpen}
        onClose={() => setIsRateAdjustModalOpen(false)}
      />

      <TableMenuCell
        name={plan.name}
        onDuplicate={() => navigate(Path.DuplicatePlan.replace(':id', plan.id))}
        onAdjust={() => setIsRateAdjustModalOpen(true)}
        onDelete={() => deletePlanHandler(plan)}
        onEdit={() => navigate(Path.UpdatePlan.replace(':id', plan.id))}
      />
    </div>
  )
}
