import { useMemo } from 'react'
import { useViewportSize } from '@mantine/hooks'
import { ROW_HEIGHT_SIZE } from '../utils/constants'

const getRowsPerPage = (height: number) => {
  const OFFSIDE_TABLE_SIZE = 350
  const rowsPerPage = Math.round((height - OFFSIDE_TABLE_SIZE) / ROW_HEIGHT_SIZE)

  if (rowsPerPage <= 0) return 1

  return rowsPerPage
}

export default function useTableDefaultRowsPerPage() {
  const { height } = useViewportSize()

  const defaultRowsPerPage = useMemo(() => getRowsPerPage(height), [height])

  return defaultRowsPerPage
}
