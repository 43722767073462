import { create } from 'zustand'

interface EventSearchTermState {
  searchTerm: string
  actions: {
    setSearchTerm: (searchTerm: string) => void
  }
}

const useEventSearchTermStore = create<EventSearchTermState>((set) => ({
  searchTerm: '',
  actions: {
    setSearchTerm: (searchTerm: string) => set({ searchTerm }),
  },
}))

export const useEventSearchTerm = () => useEventSearchTermStore((state) => state.searchTerm)
export const useEventSearchTermActions = () => useEventSearchTermStore((state) => state.actions)
