import { modals } from '@mantine/modals'
import { Trans, useTranslation } from 'react-i18next'
import AlertCard from '../../../../shared/components/alert/alert-card'

interface Props {
  onCancel: () => void;
}

export const useIncompleteAddressAlertModal = ({ onCancel }: Props) => {
  const { t } = useTranslation()

  return () => {
    modals.open({
      withCloseButton: false,
      children: (
        <AlertCard
          variant="warning"
          title={t('customer:payment-account:address:incomplete-modal:title') as string}
          description={(
            <Trans i18nKey="customer:payment-account:address:incomplete-modal:content" />
          )}
          onContinue={modals.closeAll}
          onContinueLabel={t('customer:payment-account:address:incomplete-modal:fill-information') as string}
          onCancel={onCancel}
          onCancelLabel={t('customer:payment-account:address:incomplete-modal:continue-without-address') as string}
        />
      ),
    })
  }
}
