import { useState } from 'react'
import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import SearchInput from '../../../shared/components/form/search-input'
import { useUserSearchTerm, useUserSearchTermActions } from '../store/user-search-term-store'
import CreateUserModal from './create-user-modal'

export default function UserPageActions() {
  const { t } = useTranslation('user')

  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false)

  const { setSearchTerm } = useUserSearchTermActions()
  const searchTerm = useUserSearchTerm()

  return (
    <>
      <CreateUserModal
        opened={isCreateUserModalOpen}
        onClose={() => setIsCreateUserModalOpen(false)}
      />
      <div className="flex flex-row justify-between">
        <Button onClick={() => setIsCreateUserModalOpen(true)}>
          {t('create-user')}
        </Button>
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
    </>
  )
}
