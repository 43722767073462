import { useTranslation } from 'react-i18next'
import { Aggregation } from '../enum'

const useAggregationLabel = () => {
  const { t } = useTranslation(['common', 'metric'])

  return (aggregation: Aggregation | null) => {
    switch (aggregation) {
      case Aggregation.Sum:
        return t('metric:aggregation-sum')
      case Aggregation.LastDuringPeriod:
        return t('metric:aggregation-last-during-period')
      // case Aggregation.LAST_EVER:
      //  return t('metric:aggregation-last-ever')
      default:
        return ''
    }
  }
}

export default useAggregationLabel
