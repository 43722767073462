import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePickerInput } from '@mantine/dates'
import { BiCalendar } from 'react-icons/bi'

import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'

interface Props {
  dateRange: [Date | null, Date | null];
  setDateRange: (dateRange: [Date | null, Date | null]) => void;
  placeholder: string;
  label?: string | null;
  required?: boolean;
  disabled?: boolean;
}

export default function DateRangeInput({
  dateRange, setDateRange, placeholder, label = null, required = false, disabled = false,
}: Props) {
  const { i18n } = useTranslation()
  const datePickerInputRef = useRef<any | null>(null)

  return (
    <DatePickerInput
      disabled={disabled}
      label={label}
      className="w-full"
      required={required}
      rightSection={(<BiCalendar className="cursor-pointer" size={18} />)}
      type="range"
      variant="default"
      clearable
      popoverProps={{ withinPortal: false }}
      placeholder={placeholder}
      value={dateRange}
      onChange={setDateRange}
      ref={datePickerInputRef}
      locale={i18n.language === 'pt' ? 'pt-br' : 'en'}
      valueFormat={i18n.language === 'pt' ? 'DD, MMMM, YYYY' : 'MMMM, DD, YYYY'}
    />
  )
}
