import {
  Card, Text,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import InfoItem from '../../../../shared/components/display/info-item'
import { PaymentStatusBadge } from '../../../payment/components/payment-status-badge'
import Payment from '../../../../models/payment'

interface Props {
  payment: Payment
}

export default function InvoiceDetailPaymentCard({ payment }: Props) {
  const { t } = useTranslation(['invoice', 'customer'])

  const formatDate = (date: Date): string => dayjs(date).format('DD/MM/YYYY')

  return (
    <Card padding="md" radius="md" className="flex flex-col border border-gray-border-default">
      <Text size="md" fw={600} className="text-grayscale-text-title mb-0">
        {t('invoice:payments-card:title')}
      </Text>

      <div>
        <InfoItem
          className="mt-2"
          title={t('invoice:status')}
          value={<PaymentStatusBadge status={payment.status} paymentDueDate={payment.dueDate} />}
        />
        <InfoItem
          className="mt-2"
          title={t('invoice:payments-card:payment-due')}
          value={`${formatDate(payment.startDate)} -> ${formatDate(payment.dueDate)}`}
        />
        <InfoItem
          className="mt-2"
          title={t('invoice:payments-card:method')}
          value={t('invoice:payments-card:bolepix')}
        />
      </div>
    </Card>
  )
}
