import axiosInstance from '../config/axios'
import GetPaymentFileRequest from '../requests/payments/get-payment-file-request'
import GetPaymentFileResponse from '../responses/payments/get-payment-file-response'

const PAYMENTS_BASE_URL = '/payments'

const getPaymentFile = async (request: GetPaymentFileRequest) => axiosInstance.get<GetPaymentFileResponse>(
  `${PAYMENTS_BASE_URL}/${request.id}/files/download`,
  {
    responseType: 'blob',
    params: {
      documentType: request.paymentFile,
    },
  },
)

export default {
  getPaymentFile,
}
