import { useTranslation } from 'react-i18next'
import { TableColumn } from '../../../../interfaces'
import tableUserUtils from '../../utils/table-user-utils'
import { CoreTable, TableAvatarCell } from '../../../../shared/components/table'
import { useFilteredUsersQuery } from '../../queries'
import { TableCustomerCell } from '../../../../shared/components/table/table-customer-cell'
import ExtendedUser from '../../../../models/extended-user'
import { TablePermissionCell } from './table-permission-cell'
import { TableUserMenuCell } from './table-user-menu-cell'

export default function TableUser() {
  const { t } = useTranslation(['user', 'common'])

  const {
    data: users,
    isLoading,
    isFetching,
  } = useFilteredUsersQuery()

  const tableUserColumns: TableColumn<ExtendedUser>[] = [
    {
      label: t('common:avatar'),
      key: 'name',
      columnKey: 'id',
      Component: TableAvatarCell,
      widthPercentage: 10,
    },
    {
      label: t('user:name'),
      key: 'name',
      columnKey: 'name',
      widthPercentage: 15,
    },
    {
      label: t('user:email'),
      key: 'email',
      columnKey: 'email',
      widthPercentage: 25,
    },
    {
      label: t('user:permission'),
      key: 'groups',
      columnKey: 'groups',
      widthPercentage: 15,
      Component: TablePermissionCell,
    },
    {
      label: t('user:customer'),
      key: 'customer',
      columnKey: 'customer',
      widthPercentage: 25,
      Component: TableCustomerCell,
    },
    {
      label: '',
      key: 'id',
      columnKey: 'resourceId',
      Component: TableUserMenuCell,
      widthPercentage: 10,
    },
  ]

  return (
    <CoreTable<ExtendedUser>
      data={users || []}
      indexKey={tableUserUtils.indexKey}
      columns={tableUserColumns}
      isFetching={isFetching}
      isLoading={isLoading}
      pageConfiguration={{
        pageVariation: 1,
      }}
    />
  )
}
