export const loader = (
  <svg
    width="30"
    height="30"
    viewBox="9 16 52 1"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#868e96"
  >
    <circle
      cx="20"
      cy="27"
      r="6"
      fill="#868e96"
    >
      <animate
        attributeName="cy"
        from="27"
        to="27"
        dur="1s"
        values="20; 10; 20"
        repeatCount="indefinite"
      />
    </circle>

    <circle
      cx="50"
      cy="27"
      r="6"
      fill="#868e96"
    >
      <animate
        attributeName="cy"
        from="27"
        to="27"
        dur="1s"
        values="20; 10; 20"
        repeatCount="indefinite"
      />
    </circle>

    <circle
      cx="35"
      cy="27"
      r="6"
      fill="#868e96"
    >
      <animate
        attributeName="cy"
        from="27"
        to="27"
        dur="1s"
        values="10; 20; 10"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
)
