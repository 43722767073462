export enum FileType {
  Pdf = 'pdf',
  Xml = 'xml',
}

const saveFile = (rawFileContent: any, name: string, fileType: FileType = FileType.Pdf) => {
  const url = URL.createObjectURL(rawFileContent)
  const a = document.createElement('a')
  a.download = `${name}.${fileType}`
  a.href = url
  a.target = '_self'

  a.click()

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    a.remove()
    URL.revokeObjectURL(url)
  }, 100)
}

const fileHelper = {
  saveFile,
}

export default fileHelper
