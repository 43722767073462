import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Modal from '../../../shared/components/general/modal'
import UpsertCustomerAttributes from '../../../interfaces/customer/upsert-customer-attributes'
import { useCreateCustomer } from '../queries'
import CreateCustomerForm from './create-customer-form'
import { Path } from '../../../shared/enums'
import { Customer } from '../../../models'

interface Props {
  opened: boolean;
  onClose: () => void;
}

export default function CreateCustomerModal({ opened, onClose }: Props) {
  const { t } = useTranslation(['common', 'customer'])
  const navigate = useNavigate()

  const useFormMethods = useForm<UpsertCustomerAttributes>({
    mode: 'onTouched',
  })

  const {
    handleSubmit, reset, formState: { isValid },
  } = useFormMethods

  const handleCreateCustomerSuccess = (newCustomer: Customer) => {
    navigate(Path.CustomerDetails.replace(':id', newCustomer.id))
    onClose()
    reset()
  }

  const { mutate, isLoading } = useCreateCustomer({ onSuccess: handleCreateCustomerSuccess })

  return (
    <Modal opened={opened} onClose={onClose} title={t('customer:create-customer')}>
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit((createCustomerAttributes: UpsertCustomerAttributes) => mutate(createCustomerAttributes))}
          className="flex flex-col px-2"
        >
          <CreateCustomerForm />
          <Button
            className="mt-7 w-50"
            color="violet"
            type="submit"
            disabled={!isValid}
            loading={isLoading}
          >
            {t('common:save')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  )
}
