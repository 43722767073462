import React, { ReactNode, useEffect, useState } from 'react'
import {
  Combobox, InputBase, InputBaseProps, useCombobox,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import Typography from '../general/typography'

type Props = InputBaseProps & {
  placeholder: string
  label: string | ReactNode
  data: string[]
  selectedOption: string
  onCreate: (value: string) => void
  onSelect: (value: string) => void
}

const SelectCreatable = React.forwardRef(({
  placeholder, label, data, selectedOption, onCreate, onSelect, ...props
}: Props, ref: any) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState<string>(selectedOption || '')

  useEffect(() => {
    setSearchQuery(selectedOption || '')
  }, [selectedOption])

  const exactOptionMatch = data.find((item) => item.toLowerCase() === searchQuery.toLowerCase().trim())
  const filteredOptions = selectedOption === searchQuery ? data : data.filter((item) => item.toLowerCase().includes(searchQuery.toLowerCase().trim()))

  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item} key={item}>
      <Typography>{item}</Typography>
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      middlewares={{ flip: false }}
      onOptionSubmit={(value) => {
        if (value === '$create') {
          onCreate(searchQuery || t('plan:product'))
          combobox.closeDropdown()
          return
        }
        setSearchQuery(value)
        onSelect(value)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          {...props}
          rightSection={<Combobox.Chevron />}
          value={searchQuery}
          onChange={(event) => {
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
            setSearchQuery(event.currentTarget.value)
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => setSearchQuery(selectedOption || '')}
          label={label}
          placeholder={placeholder}
          rightSectionPointerEvents="none"
          ref={ref}
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options className="max-h-[25vh] overflow-y-auto" style={{ overflowY: 'auto' }}>
          {options}
          {((!exactOptionMatch && searchQuery.trim().length > 0) || (data.length === 0 && searchQuery === '')) && (
          <Combobox.Option value="$create">
            <span className="font-600">
              +
              {' '}
              {t('common:create')}
            </span>
            {' '}
            <span className="font-500 bg-violet-100 rounded-md p-1">{searchQuery || t('plan:product')}</span>
          </Combobox.Option>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
})

export default SelectCreatable
