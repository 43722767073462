/* eslint-disable arrow-body-style */
import axiosInstance from '../config/axios'
import CreatePaymentAccountRequest from '../requests/payment-accounts/create-payment-account-request'
import DeletePaymentAccountRequest from '../requests/payment-accounts/delete-payment-account-request'
import GetPaymentAccountsRequest from '../requests/payment-accounts/get-payment-accounts-request'
import UpdatePaymentAccountRequest from '../requests/payment-accounts/update-payment-account-request'
import CreatePaymentAccountResponse from '../responses/payment-accounts/create-payment-account-response'
import GetPaymentAccountsResponse from '../responses/payment-accounts/get-payment-accounts-response'
import UpdatePaymentAccountResponse from '../responses/payment-accounts/update-payment-account-response'

const CUSTOMERS_BASE_URL = '/customers'
const PAYMENT_ACCOUNTS_BASE_URL = 'payment-accounts'

const createPaymentAccount = async (request: CreatePaymentAccountRequest) => {
  return axiosInstance.post<CreatePaymentAccountResponse>(`${CUSTOMERS_BASE_URL}/${request.customerId}/${PAYMENT_ACCOUNTS_BASE_URL}`, request)
}

const updatePaymentAccount = async (request: UpdatePaymentAccountRequest) => {
  return axiosInstance.put<UpdatePaymentAccountResponse>(`${CUSTOMERS_BASE_URL}/${request.customerId}/${PAYMENT_ACCOUNTS_BASE_URL}/${request.id}`, request)
}

const getPaymentAccounts = async (request: GetPaymentAccountsRequest) => {
  return axiosInstance.get<GetPaymentAccountsResponse>(`${CUSTOMERS_BASE_URL}/${request.customerId}/${PAYMENT_ACCOUNTS_BASE_URL}`)
}

const deletePaymentAccount = async (request: DeletePaymentAccountRequest) => {
  return axiosInstance.delete(`${CUSTOMERS_BASE_URL}/${request.customerId}/${PAYMENT_ACCOUNTS_BASE_URL}/${request.id}`)
}

export default {
  createPaymentAccount,
  updatePaymentAccount,
  getPaymentAccounts,
  deletePaymentAccount,
}
