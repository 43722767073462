import { MatchKey } from '../../../interfaces/queries/match-key'
import ProrationUsage from '../../../models/proration-usage'

const partialMatchKeys: MatchKey<ProrationUsage>[] = ['id']
const fullMatchKeys: MatchKey<ProrationUsage>[] = []
const indexKey = 'id' as keyof ProrationUsage

const tableProrationUsageUtils = {
  partialMatchKeys,
  fullMatchKeys,
  indexKey,
}

export default tableProrationUsageUtils
