import { create } from 'zustand'

interface PlanSearchTermState {
  searchTerm: string
  actions: {
    setSearchTerm: (searchTerm: string) => void
  }
}

const usePlanSearchTermStore = create<PlanSearchTermState>((set) => ({
  searchTerm: '',
  actions: {
    setSearchTerm: (searchTerm: string) => set({ searchTerm }),
  },
}))

export const usePlanSearchTerm = () => usePlanSearchTermStore((state) => state.searchTerm)
export const usePlansSearchTermActions = () => usePlanSearchTermStore((state) => state.actions)
