import { Accordion } from '@mantine/core'

interface Props {
  activeSection: string
  setActiveSection: (section: string | null) => void
  children: React.ReactNode
}

export default function CollapseForm({
  activeSection, setActiveSection, children,
}: Props) {
  return (
    <Accordion
      variant="filled"
      value={activeSection}
      onChange={setActiveSection}
      className="mt-1"
      classNames={{ control: 'flex gap-2 w-fit px-1', item: 'bg-inherit' }}
    >
      <div className="flex flex-col">
        {children}
      </div>
    </Accordion>
  )
}
