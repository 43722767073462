import { ScrollArea } from '@mantine/core'
import PlanSummary from './plan-summary'
import PlanSummaryButtons from './plan-summary-buttons'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'

interface Props {
  isValid: boolean
  formValues: UpsertExtendedPlanAttributes;
  isLoading: boolean;
  handleCancel: () => void;
}

export default function PlanSummaryContainer({
  isValid, formValues, isLoading, handleCancel,
}: Props) {
  return (
    <div className="hidden xl:flex flex-col">
      <div className="max-h-[calc(100vh-200px)] -mb-4">
        <ScrollArea.Autosize className="h-full" offsetScrollbars>
          <div className="w-[99%]">
            <PlanSummary plan={formValues} />
          </div>
        </ScrollArea.Autosize>
      </div>
      <PlanSummaryButtons isValid={isValid} isLoading={isLoading} handleCancel={handleCancel} />
    </div>
  )
}
