import React, { useRef, useState } from 'react'
import { NumberInput, NumberInputProps } from '@mantine/core'
import { FiDollarSign } from 'react-icons/fi'
import { MdOutlinePercent } from 'react-icons/md'

type Props = Omit<NumberInputProps, 'onChange' | 'value'> & {
  decimalInputType?: 'currency' | 'percentage',
  onChange: (value: string) => void,
  value: string,
}

const DecimalInput = React.forwardRef(({
  onChange, value, decimalInputType = 'currency', ...props
}: Props, ref: any) => {
  const getFormattedValue = (rawValue: string) => {
    const parts = rawValue.split('.')
    const integerPart = parts[0]

    if (parts[1]) {
      let decimalPart = parts[1].replace(/0+$/, '')
      if (decimalPart.length < 2) {
        decimalPart = decimalPart.padEnd(2, '0')
      }
      const formatted = `${integerPart},${decimalPart}`

      return formatted
    }

    return `${integerPart},00`
  }

  const [formattedValue, setFormattedValue] = useState<string>(getFormattedValue(value))
  const isFocusing = useRef<boolean>(false)

  const handleChange = (newValue: string | number) => {
    if (!isFocusing.current) return
    let updatedValue = '0'

    if (newValue !== '' && newValue !== '.') updatedValue = newValue.toString()
    if (newValue === '.') updatedValue = '0.00'

    const parts = updatedValue.split('.')
    if (parts.length === 2) {
      const integerPart = parts[0]
      const decimalPart = parts[1].replace(/0+$/, '')
      const valueFormatted = decimalPart ? `${integerPart}.${decimalPart}` : integerPart
      setFormattedValue(valueFormatted)
    }
    setFormattedValue(updatedValue)

    onChange(updatedValue)
  }

  const handleBlur = () => {
    isFocusing.current = false

    setFormattedValue(getFormattedValue(value))
  }

  return (
    <NumberInput
      leftSection={decimalInputType === 'currency' ? <FiDollarSign /> : <MdOutlinePercent />}
      value={formattedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={() => { isFocusing.current = true }}
      min={0}
      decimalScale={6}
      allowNegative={false}
      decimalSeparator=","
      hideControls
      ref={ref}
      {...props}
    />
  )
})

export default DecimalInput
