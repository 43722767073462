import { modals } from '@mantine/modals'
import { AxiosError } from 'axios'
import { ErrorResponse } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import AlertCard from '../../../../shared/components/alert/alert-card'
import UserCodes from '../../../../libs/api/enums/user-codes'
import BillingCycelExternalProcessorUserCodes from '../../../../libs/api/enums/billing-cycle-external-processor-user-codes'
import getBillingCycleExternalProcessorErrorMessage from '../../../../shared/utils/messages/get-billing-cycle-external-processor-error-message'
import getErrorMessage from '../../../../shared/utils/messages/get-error-message'

interface Props {
  error: AxiosError<ErrorResponse | any>
  onRetry: () => void
  onOverrideOmieSync: () => void
}

interface RenderAlertErrorProps {
  t: TFunction
  errorCode?: UserCodes
  errorDetailsCode?: BillingCycelExternalProcessorUserCodes
  onRetry: () => void
  onOverrideOmieSync: () => void
}

enum ErrorSource {
  UnexpectedAiraError = 'UnexpectedAiraError',
  UnexpectedOmieError = 'UnexpectedOmieError',
  ExpectedOmieError = 'ExpectedOmieError',
}

const expectedBillingCycelExternalProcessorUserCodes = [
  BillingCycelExternalProcessorUserCodes.CustomerNotFoundInOmie,
  BillingCycelExternalProcessorUserCodes.CustomerInOmieHasNoContracts,
  BillingCycelExternalProcessorUserCodes.NoActiveContractFoundInOmieForContractNumber,
  BillingCycelExternalProcessorUserCodes.MultipleOmieContractsWithTheSameContractNumber,
  BillingCycelExternalProcessorUserCodes.MultipleMatchedOmieContractsForAiraContract,
  BillingCycelExternalProcessorUserCodes.NoOmieContractMatchedForProducts,
  BillingCycelExternalProcessorUserCodes.NoOmieContractMatchedForRangeDates,
  BillingCycelExternalProcessorUserCodes.CustomerContractsInOmieAreNotActive,
  BillingCycelExternalProcessorUserCodes.NoActiveContractFoundForCustomerInOmie,
]

const useMutateContractErrorModal = () => {
  const { t } = useTranslation('customer')

  return ({ error, onOverrideOmieSync, onRetry }: Props) => {
    const errorCode = error.response?.data?.code
    const errorDetailsCode = error.response?.data?.details?.code

    const errorSource = getErrorSource(errorCode, errorDetailsCode)

    const params = {
      t,
      errorCode,
      errorDetailsCode,
      onRetry,
      onOverrideOmieSync,
    }

    let alertCard
    switch (errorSource) {
      case ErrorSource.UnexpectedAiraError:
        alertCard = renderUnexpectedAiraErrorAlert(params)
        break
      case ErrorSource.UnexpectedOmieError:
        alertCard = renderUnexpectedOmieErrorAlert(params)
        break
      case ErrorSource.ExpectedOmieError:
        alertCard = renderExpectedOmieErrorAlert(params)
        break
      default:
        alertCard = renderUnexpectedAiraErrorAlert(params)
    }

    return modals.open({
      children: alertCard,
      withCloseButton: false,
    })
  }
}

const getErrorSource = (errorCode: any, errorDetailsCode: any) => {
  if (errorCode === UserCodes.FailedToPerformExternalProcessorContractSync && expectedBillingCycelExternalProcessorUserCodes.includes(errorDetailsCode))
    return ErrorSource.ExpectedOmieError

  if (errorCode === UserCodes.DuplicateContractProcessorId)
    return ErrorSource.ExpectedOmieError

  if (errorCode === UserCodes.FailedToPerformExternalProcessorContractSync)
    return ErrorSource.UnexpectedOmieError

  return ErrorSource.UnexpectedAiraError
}

const renderUnexpectedAiraErrorAlert = ({ t }: RenderAlertErrorProps) => (
  <AlertCard
    variant="warning"
    title={t('customer:contract:form-sections:integrations:omie-integration:mutate-error:unexpected-aira-error:title') as string}
    description={t('customer:contract:form-sections:integrations:omie-integration:mutate-error:unexpected-aira-error:description') as string}
    onContinueLabel={t('customer:contract:form-sections:integrations:omie-integration:mutate-error:alert-modal-buttons:confirm') as string}
    onContinue={() => {
      modals.closeAll()
    }}
  />
)

const renderUnexpectedOmieErrorAlert = ({ t, onRetry, onOverrideOmieSync }: RenderAlertErrorProps) => (
  <AlertCard
    variant="warning"
    title={t('customer:contract:form-sections:integrations:omie-integration:mutate-error:unexpected-omie-error:title') as string}
    description={t('customer:contract:form-sections:integrations:omie-integration:mutate-error:unexpected-omie-error:description') as string}
    onContinueLabel={t('customer:contract:form-sections:integrations:omie-integration:mutate-error:alert-modal-buttons:retry') as string}
    onCancelLabel={t('customer:contract:form-sections:integrations:omie-integration:mutate-error:alert-modal-buttons:override-omie-sync') as string}
    onContinue={() => {
      onRetry()
      modals.closeAll()
    }}
    onCancel={() => {
      onOverrideOmieSync()
      modals.closeAll()
    }}
  />
)

const renderExpectedOmieErrorAlert = ({
  t, errorCode, errorDetailsCode, onOverrideOmieSync,
}: RenderAlertErrorProps) => {
  let title = ''

  if (errorCode === UserCodes.DuplicateContractProcessorId) {
    title = t('customer:contract:form-sections:integrations:omie-integration:mutate-error:expected-omie-error:title:duplicate-contract-processor-id')
  } else {
    switch (errorDetailsCode) {
      case BillingCycelExternalProcessorUserCodes.NoActiveContractFoundForCustomer:
        title = t('customer:contract:form-sections:integrations:omie-integration:mutate-error:expected-omie-error:title:no-active-contract-found-for-customer')
        break
      case BillingCycelExternalProcessorUserCodes.CustomerNotFoundInOmie:
        title = t('customer:contract:form-sections:integrations:omie-integration:mutate-error:expected-omie-error:title:customer-not-found-in-omie')
        break
      case BillingCycelExternalProcessorUserCodes.CustomerInOmieHasNoContracts:
        title = t('customer:contract:form-sections:integrations:omie-integration:mutate-error:expected-omie-error:title:customer-in-omie-has-no-contracts')
        break
      case BillingCycelExternalProcessorUserCodes.NoActiveContractFoundInOmieForContractNumber:
      case BillingCycelExternalProcessorUserCodes.NoActiveContractFoundForCustomerInOmie:
        title = t('customer:contract:form-sections:integrations:omie-integration:mutate-error:expected-omie-error:title:no-active-contract-found')
        break
      case BillingCycelExternalProcessorUserCodes.MultipleOmieContractsWithTheSameContractNumber:
        title = t('customer:contract:form-sections:integrations:omie-integration:mutate-error:expected-omie-error:title:multiple-omie-contracts-with-the-same-contract-number')
        break
      case BillingCycelExternalProcessorUserCodes.MultipleMatchedOmieContractsForAiraContract:
        title = t('customer:contract:form-sections:integrations:omie-integration:mutate-error:expected-omie-error:title:multiple-matched-omie-contracts-for-aira-contract')
        break
      case BillingCycelExternalProcessorUserCodes.NoOmieContractMatchedForProducts:
      case BillingCycelExternalProcessorUserCodes.NoOmieContractMatchedForRangeDates:
      case BillingCycelExternalProcessorUserCodes.CustomerContractsInOmieAreNotActive:
        title = t('customer:contract:form-sections:integrations:omie-integration:mutate-error:expected-omie-error:title:no-matched-contract-in-omie')
        break
      default:
        title = t('customer:contract:form-sections:integrations:omie-integration:mutate-error:unexpected-omie-error:title')
    }
  }

  let description = ''

  if (errorCode === UserCodes.DuplicateContractProcessorId) {
    description = t(getErrorMessage(errorCode))
  } else {
    description = t(
      'customer:contract:form-sections:integrations:omie-integration:mutate-error:expected-omie-error:description',
      {
        billingCycleExternalProcessorErrorMessage: t(getBillingCycleExternalProcessorErrorMessage(errorDetailsCode)),
      },
    )
  }

  return (
    <AlertCard
      variant="warning"
      title={title}
      description={description}
      onContinue={() => {
        modals.closeAll()
      }}
      onCancel={() => {
        onOverrideOmieSync()
        modals.closeAll()
      }}
      onContinueLabel={t('customer:contract:form-sections:integrations:omie-integration:mutate-error:alert-modal-buttons:fix') as string}
      onCancelLabel={t('customer:contract:form-sections:integrations:omie-integration:mutate-error:alert-modal-buttons:override-omie-sync') as string}
    />
  )
}

export default useMutateContractErrorModal
