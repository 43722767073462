import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {
  auth,
  common,
  resource,
  user,
  messages,
  plan,
  metric,
  priceTier,
  customer,
  invoice,
  event,
} from '../internationalization/pt'

const resources = {
  pt: {
    auth,
    user,
    common,
    resource,
    messages,
    plan,
    metric,
    priceTier,
    customer,
    invoice,
    event,
  },
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
