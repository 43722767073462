import { create } from 'zustand'
import { InvoiceExternalProcessorFilter } from '../../../interfaces/invoice/invoice-external-processor-filter'

interface InvoiceExternalProcessorFilterState {
  filter: InvoiceExternalProcessorFilter;
  actions: {
    setStatus: (status: string[]) => void;
    setDateRange: (dateRange: [Date | null, Date | null]) => void;
  }
}

const useInvoiceExternalProcessorFilterStore = create<InvoiceExternalProcessorFilterState>((set) => ({
  filter: {
    status: [],
    dateRange: [null, null],
  },
  actions: {
    setStatus: (status) => set((state) => ({ filter: { ...state.filter, status } })),
    setDateRange: (dateRange) => set((state) => ({ filter: { ...state.filter, dateRange } })),
  },
}))

export const useInvoiceExternalProcessorFilter = () => useInvoiceExternalProcessorFilterStore((state) => state.filter)
export const useInvoiceExternalProcessorFilterActions = () => useInvoiceExternalProcessorFilterStore((state) => state.actions)
