import { ReactElement } from 'react'
import PageHeader from './page-header-v1'
import Sidebar from '../sidebar'

interface Props {
  children: ReactElement;
}

export default function PageLayoutV1({ children }: Props) {
  return (
    <div className="w-full min-h-screen bg-gray-200 flex">
      <Sidebar />
      <div className="2xl:w-[90%] xl:w-[85%] lg:w-[82%] w-[80%] px-10">
        <PageHeader />
        <div className="w-full max-h-full flex-grow pt-4">
          {children}
        </div>
      </div>
    </div>
  )
}
